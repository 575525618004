import React, { useContext } from 'react';
import Modal from '@/utils/Modal';
import StateAccessor from '@/StateAccessor';
import Subscriptions from '@/pages/AccountManagement/Subscriptions';
import '../css/PremiumSubscriptionModal.css';

interface PremiumSubscriptionModalProps {
  onSubscriptionComplete: () => void;
}

const PremiumSubscriptionModal: React.FC<PremiumSubscriptionModalProps> = ({ onSubscriptionComplete }) => {
  const { userName, userEmail, userId } = useContext(StateAccessor);

  return (
    <Modal
      content={
        <div className="premium-subscription-modal root-container">
          <Subscriptions
            viewControlSettings={{
              showAvailableResourcesView: false,
              showSubscriptionDetailsView: false,
              showPremiumSubscriptionView: true,
            }}
          />
        </div>
      }
      onClose={onSubscriptionComplete}
      padding='0px'
    />
  );
};

export default PremiumSubscriptionModal;
