import React from 'react';
import './css/FeatureGrid.css';

const FeatureGrid: React.FC = () => {
    const standardFeatures = (
        <>
            <div className="service-feature">✅ Pair with a FAANG+ senior engineer (10+ years)</div>
            <div className="service-feature">✅ Hire or No-Hire Decision (Know where you stand)</div>
            <div className="service-feature">✅ Tips & Tricks to level you up quickly</div>
        </>
    );

    const renderFeatures = () => (
        <>
            <div className="features-by-service-container">
                <h1> System Design</h1>
                {/* Start of System Design specific*/}
                <div className="service-feature">✅ Get good at driving the design discussion</div>
                <div className="service-feature">✅ Learn how to demonstrate depth of knowledge</div>
                <div className="service-feature">✅ Learn how to deal with vague requirements</div>
                <div className="service-feature">✅ Learn how to make trade-offs</div>
                <div className="service-feature">✅ Learn how to justify decisions</div>
                <div className="service-feature">✅ Learn how to convince the interviewer to hire you through signaling</div>
                {/* End of System Design specific*/}
                {standardFeatures}
            </div>

            <div className="features-by-service-container">
                <h1> Coding</h1>
                {/* Start of data structures and algorithms specific*/}
                <div className="service-feature">✅ Improve pattern recognition skills</div>
                <div className="service-feature">✅ Arrive at optimal solutions faster</div>
                <div className="service-feature">✅ Handle unfamiliar scenarios with confidence</div>
                <div className="service-feature">✅ Avoid auto-pilot on familiar problems</div>
                <div className="service-feature">✅ Craft correct solutions under pressure</div>
                <div className="service-feature">✅ Improve Dry-running skills for ensuring correctness</div>
                <div className="service-feature">✅ Write bug-free code quickly</div>
                <div className="service-feature">✅ Manage time effectively during interviews</div>
                <div className="service-feature">✅ Practice context-switching between coding and communication</div>
                <div className="service-feature">✅ Exude confidence and positive body language</div>
                <div className="service-feature">✅ Improve your interviewer management skills</div>

                {/* End of data structures and algorithms specific*/}
                {standardFeatures}
            </div>

            <div className="features-by-service-container">
                <h1> Systems/OS</h1>
                {/* Start of Systems-specific features*/}
                <div className="service-feature">✅ Suitable for candidates of production/systems engineer roles and other similar roles</div>
                <div className="service-feature">✅ Practice troubleshooting/debugging systems related problems</div>
                <div className="service-feature">✅ Practice crafting combinations of <strong>Linux</strong> commands to solve a problem (e.g., awk, grep, ps, etc.)</div>
                <div className="service-feature">✅ Test your knowledge of Linux & operating system fundamentals</div>
                {/* End of Systems-specific features*/}
                {standardFeatures}
            </div>

            <div className="features-by-service-container">
                <h1> Behavioural</h1>
                {/* Start of Behavioural specific*/}
                <div className="service-feature">✅ Craft compelling responses to behavioural questions for a strong hire signal</div>
                {/* <div className="service-feature">✅ get good at driving the design discussion</div>
                <div className="service-feature">✅ learn how to deal with vague requirements</div>
                <div className="service-feature">✅ learn how to make trade-offs and justify decisions</div>
                <div className="service-feature">✅ learn how to convince the interviewer to hire you through signaling</div> */}
                {/* End of Behavioural specific*/}
                {standardFeatures}
            </div>

            <div className="features-by-service-container">
                <h1>Strategy</h1>
                <div className="service-feature">✅ Customized structured plans for breaking into target companies (FAANG & others)</div>
                <div className="service-feature">✅ Leverage neuroscience to minimize time, effort, and challenges</div>
                <div className="service-feature">✅ Personalized advice tailored to your unique needs</div>
                <div className="service-feature">✅ Techniques to retain knowledge and overcome the forgetting curve</div>
                <div className="service-feature">✅ Sustainable plan which prevents burnout</div>
            </div>


            <div className="features-by-service-container">
                <h1> Low-level Design</h1>
                {/* Start of LLD specific*/}
                {/* <div className="service-feature">✅ get good at driving the design discussion</div>
                <div className="service-feature">✅ learn how to demonstrate depth of knowledge</div>
                <div className="service-feature">✅ learn how to deal with vague requirements</div>
                <div className="service-feature">✅ learn how to make trade-offs and justify decisions</div>
                <div className="service-feature">✅ learn how to convince the interviewer to hire you through signaling</div> */}
                {/* End of LLD specific*/}
                {standardFeatures}
            </div>
            
        </>
    );

    return (
        <div className="features-container landing-page-row">
            <div className="features-grid">
                {renderFeatures()}
            </div>
        </div>
    );
}

export default FeatureGrid;