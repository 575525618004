import React, { createContext, useState, ReactNode, useEffect } from "react";
import { Assessment, AccessLevel, ChallengeType, AssessmentType, Difficulty, UnitConceptTags, Unit, } from "./Constants";
// import { getAssessments } from "../BackEndRequests";

// Assessment 0
const ASSESSMENT_0_ALGOS_AND_DS_UNITS: Unit[] = [
  {
    "unit_id": 0,
    "access_level": AccessLevel.Standard,
    "title": "Minimum Cost",
    "challenge": {
      "challenge_id": 0,
      "title": "Minimum Cost",
      "challenge_type": ChallengeType.KNOWLEDGE, // checks foundational knowledge
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["1", "2", "3", "4", "5"],
      "acceptable_answers": ["2"],

      "problem_statement": `
          <div>
            <p>Given two strings, <code>str1</code> and <code>str2</code>, your task is to convert <code>str1</code> into <code>str2</code>, whilst minimising the total cost of the operations. Each operation has a cost, and you are allowed to perform the following operations as many times as possible:</p>
            <ul>
                <li><strong>Insert:</strong> Add a single character anywhere in the string. Cost: 1 unit per insertion</li>
                <li><strong>Delete:</strong> Remove a single character from the string. Cost: 1 unit per deletion</li>
                <li><strong>Replace:</strong> Change a single character to any other character. Cost: 1 unit per replacement</li>
            </ul>
            <p>For the purpose of this question, you're given:</p>
            <p><code>str1</code> = "flower"<br>
            <code>str2</code> = "flow"</p>
            
            <strong><p>Determine the minimum cost to convert <code>str1</code> into <code>str2</code> using the allowed operations.</p></strong>
          </div>
        `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward,
    },
    "explanations": {
      "quick_html": `
          <div>
            <h2>Explanation:</h2>
            <p>To convert "flower" to "flow", the minimum operations required are:</p>
            <ol>
                <li>Delete 'e' from "flower" -> "flowr"</li>
                <li>Delete 'r' from "flowr" -> "flow"</li>
            </ol>
            <p>Hence, the minimum cost is 2.</p>
          </div>

        `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.DynamicProgramming1D],
  },
  {
    "unit_id": 1,
    "access_level": AccessLevel.Standard,
    "title": "Backpack Capacity",
    "challenge": {
      "challenge_id": 1,
      "title": "Optimal Packing",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["Line 2", "Line 5", "Line 8", "Line 12", "No bug"],
      "acceptable_answers": ["No bug"],

      "problem_statement": `
          <div>
            <p>You are given a set of items, each with a weight and a value</p>
            <p>You are also given a backpack with a limited capacity that represents the maximum weight it can withstand.</p>
            <p>The goal is to determine the maximum value that can be achieved by picking a subset of the items,</p>
            <p> for storage in the backpack, ensuring the total weight does not exceed the backpack's capacity.</p>
            <p>Below is a potential algorithm to solve the problem:</p>
<pre>
<code>
function backpack(items, capacity): // Line 1
  if items is empty or capacity is 0: // Line 2
      return 0

  if items[0].weight > capacity: // Line 5
      return backpack(items[1:], capacity) // Line 6

  // consider the current item
  value1 =  items[0].value + backpack(// Line 8
    items[1:], capacity - items[0].weight
  ) 

  // skip the current item
  value2 = backpack(items[1:], capacity) // Line 10

  return max(value1, value2) // Line 12
</code>
</pre>
            <strong><p>Identify which line, if any, has a bug.</p></strong>
          </div>
        `,
      "penalty": 0,
      "time_limit_in_minutes": 5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
      "quick_html": `
          <div>
            <h2>Explanation:</h2>
            <p>The given backpack algorithm does not contain any bug. Each line serves its purpose for the problem's requirements.</p>
          </div>
        `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.DynamicProgramming1D],
  },
  {
    "unit_id": 2,
    "access_level": AccessLevel.Standard,
    "title": "Topological Sort",
    "challenge": {
      "challenge_id": 2,
      "title": "Topological Sort",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "A directed graph with only one cycle.",
        "A binary tree with directed edges, where each edge indicates precedence.",
        "A graph with a single node and a directed edge where the node points to itself.",
        "A heap."
      ],
      "acceptable_answers": ["A binary tree with directed edges, where each edge indicates precedence."],
      "problem_statement": `
              <div>
                  <p>Which of the following can be topologically sorted?</p>
                  <ul>
                    <li>A directed graph with only one cycle.</li>
                    <li>A binary tree with directed edges, where each edge indicates precedence.</li>
                    <li>A graph with a single node and a directed edge where the node points to itself.</li>
                    <li>A heap.</li>
                  </ul>
              </div>
          `,
      "penalty": 0,
      "time_limit_in_minutes": 1.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
              <div>
                  <h2>Explanation:</h2>
                  <p>The correct answer is "A binary tree with directed edges, where each edge represents precedence." as binary trees can be topologically sorted.</p>
              </div>
          `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.TopologicalSort],
  },
  {
    "unit_id": 3,
    "access_level": AccessLevel.Standard,
    "title": "Valid Topological Sort",
    "challenge": {
      "challenge_id": 3,
      "title": "Valid Topological Sort Order",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "C, B, A",
        "A,B,C",
        "D, C, E, B, A",
        "A, B, C, D, E",
        "D, E, A, B, C",
        "A, C, B, D, E"
      ],
      "acceptable_answers": ["D, C, E, B, A"],
      "problem_statement": `
            <div>
                <p>Consider the following description of a graph:</p>
                <p>Nodes: A, B, C, D, E</p>
                <p>Directed Edges: (A -> B), (B -> C)</p>
                <strong><p>Note (A->B) means node A depends on node B</p></strong>
                
                <strong><p>Given the above graph, can you determine which of the orderings is a valid topological sort?</p></strong>
            </div>
        `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward,
    },
    "explanations": {
      "quick_html": `
            <div>
                <h2>Explanation:</h2>
                <p>The correct order for a topological sort is "D, C, E, B, A".</p>
                <p>As this order contains all the nodes and satisfies the dependencies expressed i.e. C must occur before B which must occur before A</p>
            </div>
        `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.TopologicalSort],
  },
  {
    "unit_id": 4,
    "access_level": AccessLevel.Standard,
    "title": "Graph Algorithms: Depth-First Search",
    "challenge": {
      "challenge_id": 4,
      "title": "Depth-First Search Implementations",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "Nodes: X, Y, Z; Directed Edges: (X -> Y), (Y -> Z), (Z -> X)",
        "Nodes: M, N; Directed Edges: (M -> N)",
        "Nodes: P; No edges.",
        "All of the graphs.",
        "None of the graphs."
      ],
      "acceptable_answers": [
        "Nodes: M, N; Directed Edges: (M -> N)",
        "Nodes: P; No edges."
      ],
      "problem_statement": `
        <div>
            <p>Consider the given Depth-First Search (DFS) implementation for graph traversal.</p>
            <p><strong>Post-order DFS:</strong></p>
            <pre>
<code>
function DFS_post_order(node) {
  if (node is not visited) {
      for each child of node {
          DFS_post_order(child);
      }
      visit(node);
  }
}
</code></pre>
            <p>Note: "visit" denotes marking a node as visited.</p>
        
        <strong><p>Select only one of the given graphs, for which the DFS implementation correctly visits all nodes exactly once?</p></strong>
        </div>
    `,
      "penalty": 0,
      "time_limit_in_minutes": 4,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
      "quick_html": `
        <div>
            <p>The post-order DFS will get stuck in an infinite loop for a cyclic graph, as seen in option 1. Option 2 and 3 both represent acyclic graphs, ensuring that the algorithm visits each node only once.</p>
        </div>
    `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.DepthFirstSearch],
  },
  {
    "unit_id": 5,
    "access_level": AccessLevel.Standard,
    "title": "AutoComplete",
    "challenge": {
      "challenge_id": 5,
      "title": "Autocomplete",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "Hash Table",
        "Binary Search Tree",
        "LinkedList",
        "Trie"
      ],
      "acceptable_answers": ["Trie"],
      "problem_statement": `
        <div>
            <p>Consider a scenario where you are implementing an autocomplete feature for a text editor. As the user types, the software should predict and display a list of words that begin with the string typed so far. Which of the following data structures would be most effective to support this functionality?</p>
        </div>
    `,
      "penalty": 0,
      "time_limit_in_minutes": 2,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward,
    },
    "explanations": {
      "quick_html": `
        <div>
            <p>A trie (also known as a prefix tree) is specifically designed for storing and searching strings. For the autocomplete feature, the string that the user types can be used to traverse down the trie. As soon as the end of the string is reached in the trie, all descendant nodes represent possible completions. Thus, a trie would allow for very efficient predictions based on prefixes.</p>
        </div>
    `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Trie],
  },
  {
    "unit_id": 6,
    "access_level": AccessLevel.Standard,
    "title": "Graph Algorithms: BFS vs DFS",
    "challenge": {
      "challenge_id": 6,
      "title": "Navigating a Maze: BFS vs DFS",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "Depth-First Search (DFS)",
        "Breadth-First Search (BFS)",
        "Binary Search",
        "Topological sort"
      ],
      "acceptable_answers": ["Breadth-First Search (BFS)"],
      "problem_statement": `
        <div>
            <p>Imagine you are navigating a maze, represented as a weighted graph whose edges all have the same weight. A weight represents the distance between two nodes. There are multiple paths to the exit node, from a designated starting node. You want to find the shortest path to the exit. Which of the following algorithms would be the most suitable for this task?</p>
        </div>
    `,
      "penalty": 0,
      "time_limit_in_minutes": 3.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
      "quick_html": `
        <div>
            <p>BFS explores all neighboring nodes at the present depth before moving on to nodes at the next depth level. As a result, if a path exists to the exit at a closer depth level, BFS will find it before exploring paths at deeper levels. On the other hand, DFS might navigate deep into the maze before backtracking, making it less suitable for finding the nearest path.</p>
        </div>
    `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.BreadthFirstSearch],
  },
  {
    "unit_id": 7,
    "access_level": AccessLevel.Standard,
    "title": "Understanding Heaps",
    "challenge": {
      "challenge_id": 7,
      "title": "Heap Description",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "A heap is always a perfect binary tree i.e. all levels are completely filled.",
        "A heap is can be implemented using an array or a linked list.",
        "A heap is always a binary tree but not necessarily a complete binary tree.",
        "A heap is always a complete binary tree i.e. it has no holes"
      ],
      "acceptable_answers": ["A heap is always a complete binary tree i.e. it has no holes"],
      "problem_statement": "<div>Which of the following best describes a heap?</div>",
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": "<div>Answer: A heap is always a complete binary tree. It ensures that all levels, except possibly the last, are fully filled and that all nodes are as left as possible.</div>",
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Heap],
  },
  {
    "unit_id": 8,
    "access_level": AccessLevel.Standard,
    "title": "Complexity of Heap Operations",
    "challenge": {
      "challenge_id": 8,
      "title": "Heapify and root popping",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "O(n) for heapify and O(1) for removal",
        "O(nlogn) for heapify and O(logn) for removal",
        "O(n) for heapify and O(logn) for removal",
        "O(nlogn) for heapify and O(1) for removal"
      ],
      "acceptable_answers": ["O(n) for heapify and O(logn) for removal"],
      "problem_statement": "<div><p>Given a heap with <strong>n</strong> nodes, where <strong>n</strong> is an integer.</p><p>What would be the runtime complexity of the heapify algorithm if applied to this heap? Also what is the worst-case runtime complexity of removing the root element of the heap?</p></div>",
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging
    },
    "explanations": {
      "quick_html": "<div>Answer: The heapify operation has a runtime complexity of O(n) while removing the root element in the worst-case scenario from a max heap is O(logn) as you'd need to re-adjust the heap to maintain its properties.</div>",
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Heap],
  },
  {
    "unit_id": 9,
    "access_level": AccessLevel.Standard,
    "title": "Memory Defragmentation",
    "challenge": {
      "challenge_id": 9,
      "title": "Optimal Defragmentation",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "O(1) runtime and O(1) space complexity",
        "O(n) runtime and O(1) space complexity",
        "O(log n) runtime and O(1) space complexity",
        "O(n log n) runtime and O(1) space complexity",
        "O(1) runtime and O(n) space complexity",

      ],
      "acceptable_answers": ["O(n) runtime and O(1) space complexity"],
      "problem_statement": `
          <div>
            <p>Consider an array representing memory blocks, where '1' denotes an occupied block and '0' denotes an empty block. Your task is to rearrange this array such that all occupied blocks are grouped together, and all unoccupied blocks are also grouped together.</p>
            <p>Note occupied blocks must all occur before unoccupied blocks. For example:</p>
            
            <ul>
                <li>Initial state: [1, 0, 1, 0, 1], Final state: [1, 1, 1, 0, 0]</li>
                <li>Initial state: [0, 1, 0, 1, 0, 1], Final state: [1, 1, 1, 0, 0, 0]</li>
            </ul>
            <p>Of all the posisble ways to solve this, what is the best runtime and space complexity that can be achieved? <strong> You do not need to code</strong></p>
          </div>
        `,
      "penalty": 0,
      "time_limit_in_minutes": 5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging
    },
    "explanations": {
      "quick_html": `
          <div>
            <h2>Answer:</h2>
            <p>This can be solved in-place, one can use a two-pointer approach to swap 1s and 0s to ensure that all 1s occur before all 0s</p>
          </div>
        `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Heap],
  },
]
const ASSESSMENT_0_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_0_ALGOS_AND_DS_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);

// Assessment 1
const ASSESSMENT_1_ALGOS_AND_DS_UNITS: Unit[] = [
  {
    "unit_id": 100,
    "access_level": AccessLevel.Standard,
    "title": "Merge Sum (Trees)",
    "challenge": {
      "challenge_id": 100,
      "title": "Merge Trees via Summation",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "There's no error.",
        "Error at line 2.",
        "Error at line 4.",
        "Error at line 6."
      ],
      "acceptable_answers": ["Error at line 2."],
      "problem_statement": `
              <div>
                <p>Given the following pseudocode to create a new binary tree by summing corresponding nodes in two given binary trees, identify if there's any implementation error. If so, specify the line number of the first error encountered.</p>
                <pre>
function sumTrees(node1, node2): // Line 0
  if node1 is null or node2 is null: // Line 1
    return null // Line 2
  newNode = new Node(node1.value + node2.value) // Line 3
  newNode.left = sumTrees(node1.left, node2.left) // Line 4
  newNode.right = sumTrees(node1.right, node2.right) // Line 5
  return newNode // Line 6
                </pre>
              </div>
            `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
              <div>
                <h2>Reasoning:</h2>
                <p>If one of the nodes is null but the other isn't, it shouldn't directly return null. It should sum up the non-null node's value and proceed.</p>
              </div>
            `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Tree],
  },
  {
    "unit_id": 101,
    "access_level": AccessLevel.Standard,
    "title": "Binary Search Tree Condition",
    "challenge": {
      "challenge_id": 101,
      "title": "BST Condition",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "Every node must have a value less than that of all its ancestors",
        "Every node in the tree has a value greater than all its descendants.",
        "Every node must be greater than or equal to all its left descendants and less than all its right descendants.",
        "Every node must be less than or equal to all its left descendants and greater than all its right descendants.",
        "Every leaf node has a value less than the root."
      ],
      "acceptable_answers": ["Every node must be greater than or equal to all its left descendants and less than all its right descendants."],
      "problem_statement": `
              <div>
                <p>Which of the following conditions must a binary tree satisfy in order to be classified as a <strong>binary search tree</strong>?</p>
              </div>
            `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
              <div>
                <h2>Reasoning:</h2>
                <p>A binary search tree ensures that all the nodes in the left subtree are less than or equal to the root and all nodes in the right subtree are greater than the root. This condition applies to every node in the BST.</p>
              </div>
            `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.BinarySearchTree],
  },
  {
    "unit_id": 103,
    "access_level": AccessLevel.Standard,
    "title": "",
    "challenge": {
      "challenge_id": 103,
      "title": "Tree Properties",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "A tree doesn't have cycles, and the height is log(n).",
        "A tree has cycles, and the height is log2n.",
        "A tree doesn't have cycles, and the height is n.",
        "A tree has cycles, and the height is n/2."
      ],
      "acceptable_answers": ["A tree doesn't have cycles, and the height is log(n)."],
      "problem_statement": `
              <div>
                <p>Can you answer the following questions:</p>
                <ul>
                  <li>Can a tree have cycles?</li>
                  <li>If a binary tree has n nodes (where n is an integer, and n > 20), what is a rough approximation for the height of the tree? You can assume all non-leaf nodes have 2 children i.e. no holes in the tree</li>
                </ul>
              </div>
            `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
      "quick_html": `
              <div>
                <h2>Explanation:</h2>
                <p>A tree doesn't contain any cycles. The height of a perfect binary tree is log base 2 of n.</p>
              </div>
            `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Tree],
  },
  {
    "unit_id": 104,
    "access_level": AccessLevel.Standard,
    "title": "",
    "challenge": {
      "challenge_id": 104,
      "title": "Mystery Function on Binary Tree",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "It creates a deep copy of the binary tree.",
        "It inverts the binary tree.",
        "It swaps every left child with its parent.",
        "It swaps the root node with the deepest leaf node."
      ],
      "acceptable_answers": ["It inverts the binary tree."],
      "problem_statement": `
              <div>
                <p><strong>Given the following pseudocode, determine what it does to the input binary tree.</strong></p>
                <pre>
      function mysteryFunction(node) {
          if node is null:
              return null
          left = mysteryFunction(node.left)
          right = mysteryFunction(node.right)
          node.left = right
          node.right = left
          return node
      }
                </pre>
              </div>
            `,
      "penalty": 0,
      "time_limit_in_minutes": 6,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
      "quick_html": `
              <div>
                <h2>Explanation:</h2>
                <p>The function inverts the binary tree by recursively swapping the left and right children of each node.</p>
              </div>
            `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Tree],
  },
  {
    "unit_id": 105,
    "access_level": AccessLevel.Standard,
    "title": "Queue Processing Issue",
    "challenge": {
      "challenge_id": 105,
      "title": "Task Processing Problem",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "Tasks will be processed out of order",
        "The function will enter an infinite loop if the queue contains 'special' tasks",
        "Tasks are being duplicated",
        "Queue might overflow"
      ],
      "acceptable_answers": ["The function will enter an infinite loop if the queue contains 'special' tasks"],
      "problem_statement": `
            <div>
                <p>Given the pseudocode below for processing some tasks in a queue:</p>
                <pre>
  function processTasks(taskQueue):
    while not taskQueue.isEmpty():
        task = taskQueue.dequeue()
        if task.type == "special"
            taskQueue.enqueue(task)
        else:
            task.process()
                </pre>
                <h3>Can you spot a major issue with the logic?</h3>
            </div>
        `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward,
    },
    "explanations": {
      "quick_html": `
            <div>
                <h2>Explanation:</h2>
                <p>If the task is of type "special", it is enqueued back without being processed. If a "special" task is at the front and there's no other task to push it back in the queue, the function will keep dequeuing and enqueuing the same "special" task, creating an infinite loop.</p>
            </div>
        `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Queue],
  },
  {
    "unit_id": 106,
    "access_level": AccessLevel.Standard,
    "title": "Parentheses Sequence Checker",
    "challenge": {
      "challenge_id": 106,
      "title": "Balanced Parentheses",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "((()))",
        "(())(())",
        "()",
        "(()",
        "All sequences will be handled correctly"
      ],
      "acceptable_answers": ["All sequences will be handled correctly"],
      "problem_statement": `
      <div>
        <p> A sequence of parentheses is considered "balanced" if the following conditions are met:</p>
        <ul>
          <li>Each opening parenthesis '(' has a corresponding closing parenthesis ')'.</li>
          <li>The closing parenthesis ')' for each opening parenthesis '(' must occur later in the sequence.</li>
          <li>The sequence must not contain any extra, unpaired parentheses.</li>
        </ul>
        <p>For example, these are balanced sequences: <strong>"()"</strong>, <strong>"()()"</strong>, <strong>"(())"</strong>.</p>
        <p>Consider the following pseudocode to check if a sequence of parentheses is balanced:</p>
        <pre>
  function isBalanced(sequence):
    stack = empty stack
    for i = 0 to length(sequence) - 1:
        if sequence[i] == "(":
            stack.push(sequence[i])
        else if sequence[i] == ")":
            if stack.isEmpty():
                return False
            stack.pop()
    return stack.isEmpty()
        </pre>
        <h3>Which of the following sequences will not be handled correctly?</h3>
    </div>
    
        `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging
    },
    "explanations": {
      "quick_html": `
            <div>
                <h2>Explanation:</h2>
                <p>The function uses a stack to keep track of the open parentheses. It will correctly identify whether all given sequences are balanced or not. Thus, all sequences are correctly handled by the pseudocode.</p>
            </div>
        `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Stack],
  },
  {
    "unit_id": 107,
    "access_level": AccessLevel.Standard,
    "title": "",
    "challenge": {
      "challenge_id": 107,
      "title": "Improving Lookup Time Complexity",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["Stack", "Queue", "Binary Tree", "Hash Table"],
      "acceptable_answers": ["Hash Table"],
      "problem_statement": `
        <div>
          <p>Suppose you encounter a program which uses an array to check if a value has been seen before, and this results in a time complexity of O(n) for looking up a given value in the array.</p>
          <p>Which data structure would be best to replace the array, in order to improve the runtime complexity for this operation?</p>
        </div>
      `,
      "penalty": 0,
      "time_limit_in_minutes": 1,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging
    },
    "explanations": {
      "quick_html": `
        <div>
          <h2>Explanation:</h2>
          <p>Using a Hash Table (or dictionary) provides O(1) average-time complexity for lookups, making it the most efficient choice for this operation compared to the other options.</p>
        </div>
      `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.HashTable],
  },
  {
    "unit_id": 108,
    "access_level": AccessLevel.Standard,
    "title": "",
    "challenge": {
      "challenge_id": 108,
      "title": "QuickSort Worst-Case Runtime",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["O(n)", "O(n log n)", "O(n^2)", "O(log n)"],
      "acceptable_answers": ["O(n^2)"],
      "problem_statement": `
        <div>
          <p>What is the worst-case runtime complexity of the QuickSort algorithm?</p>
        </div>
      `,
      "penalty": 0,
      "time_limit_in_minutes": 1,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging
    },
    "explanations": {
      "quick_html": `
        <div>
          <h2>Explanation:</h2>
          <p>In the worst-case scenario, QuickSort has a runtime complexity of O(n^2).</p>
        </div>
      `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Sorting],
  },
  {
    "unit_id": 109,
    "access_level": AccessLevel.Standard,
    "title": "Linked List Concatenation",
    "challenge": {
      "challenge_id": 109,
      "title": "Linked List Concatenation",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "1",
        "2",
        "3",
        "4",
        "All options are concatenated correctly",
        "None of the options are concatenated correctly"
      ],
      "acceptable_answers": ["All options are concatenated correctly"],
      "problem_statement": `
              <div>
                <p>The pseudocode below is designed to concatenate the character values of a linked list's nodes:</p>
<pre>
<code>
  FUNCTION ConcatenateString(list):
      result = ""
      currentNode = list.head

      WHILE currentNode IS NOT NULL DO
          result += currentNode.value // string concatenation
          currentNode = currentNode.next
      ENDWHILE

      RETURN result
  END FUNCTION
</code>
</pre>
                <p>Given the following linked list structures, which of them (if any) will not be concatenated correctly by the above function?</p>
                  <p><pre>1) A -> B -> C -> D -> NULL </pre> </p>
                  <p><pre>2) Z -> NULL </pre> </p>
                  <p><pre>3) H -> E -> L -> L -> O -> NULL </pre> </p>
                  <p><pre>4) A -> B -> NULL </pre> </p>
              </div>
            `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
              <div>
                <h2>Explanation:</h2>
                <p>The 4th option contains a NULL character, which will lead to an error when attempting to concatenate. Therefore, the correct answer is option d) 4.</p>
              </div>
            `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.LinkedList],
  },
  {
    "unit_id": 110,
    "access_level": AccessLevel.Standard,
    "title": "Bitwise Operations",
    "challenge": {
      "challenge_id": 110,
      "title": "Bitwise Output Computation",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["2", "12", "4", "8", "13"],
      "acceptable_answers": ["12"],
      "problem_statement": `
            <div>              
              <p>Consider the following function which takes in two integers <code>a</code> and <code>b</code>:</p>
              <pre>
              FUNCTION BitwiseManipulation(a, b):
                  integer: result = 0;
                  result = (a & b) ^ (a | ~b)
                  RETURN result // returns an integer
              END FUNCTION
              </pre>
              <p>What will be the output of <code>BitwiseManipulation(5, 3)</code>?</p>
              <p>
                <strong>Note</strong>:
                <ul>
                  <li>5 in binary: 0101</li>
                  <li>3 in binary: 0011</li>
                </ul>
              </p>
            </div>
          `,
      "penalty": 0,
      "time_limit_in_minutes": 4,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
            <div>
              <h2>Explanation:</h2>
              <p>The given binary operations result in:</p>
              <ol>
                  <li><code>a & b</code> results in <code>0001</code> (decimal: 1)</li>
                  <li><code>~b</code> gives <code>1100</code></li>
                  <li><code>a | ~b</code> results in <code>1101</code> (decimal: 13)</li>
                  <li>Finally, <code>0001 ^ 1101= 1100</code> (decimal: 12)</li>
              </ol>
            </div>
          `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.BitManipulation],
  },
  {
    "unit_id": 111,
    "access_level": AccessLevel.Standard,
    "title": "Recursive Factorial",
    "challenge": {
      "challenge_id": 111,
      "title": "Factorial Function Complexity",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["O(1)", "O(n)", "O(n^2)", "O(logn)"],
      "acceptable_answers": ["O(n)"],
      "problem_statement": `
            <div>
              <p>Below is pseudocode for a recursive function intended to calculate the factorial of an integer <code>n</code>, where <code>1 ≤ n ≤ 5</code>:</p>
              <pre>
                <code>
      FUNCTION Factorial(n):
          IF n = 1 THEN
              RETURN 1
          END IF

          RETURN n * Factorial(n-1)
      END FUNCTION
                </code>
              </pre>
              <p>Determine the runtime complexity of the function:</p>
            </div>
          `,
      "penalty": 0,
      "time_limit_in_minutes": 1,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
            <div>
              <h2>Explanation:</h2>
              <p>The function calls itself <code>n</code> times to calculate the factorial of a number. Thus, its runtime complexity is linear, <code>O(n)</code>.</p>
            </div>
          `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Recursion],
  },
  {
    "unit_id": 112,
    "access_level": AccessLevel.Standard,
    "title": "Recursive Factorial II",
    "challenge": {
      "challenge_id": 112,
      "title": "Factorial Function Complexity",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["Yes", "No, because it doesn't handle negative integers", "No, due to integer overflow", "No, due to a stack overflow"],
      "acceptable_answers": ["No, due to integer overflow"],
      "problem_statement": `
            <div>
              <p>Below is pseudocode for a recursive function intended to calculate the factorial of an integer <code>n</code>, where n is a non-negative integer.</p>
              <i>You can assume there is infinite memory available to the program executing this function</i>
              <pre>
                <code>
      FUNCTION Factorial(n):
          IF n == 1 or n == 0 THEN // this line has no issues
              RETURN 1
          END IF

          RETURN n * Factorial(n-1)
      END FUNCTION
                </code>
              </pre>
              
              <p><strong>Is this implementation correct?</strong></p>
            </div>
          `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
            <div>
              <h2>Explanation:</h2>
              <p>Integer overflow will occur if n is large enough</p>
            </div>
          `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Recursion],
  },


]
const ASSESSMENT_1_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_1_ALGOS_AND_DS_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);

// Assessment 2
const ASSESSMENT_2_ALGOS_AND_DS_UNITS: Unit[] = [
  {
    "unit_id": 200,
    "access_level": AccessLevel.Standard,
    "title": "Binary Arithmetic",
    "challenge": {
      "challenge_id": 200,
      "title": "Binary Arithmetic",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "Multiplies two binary numbers and returns their product as a string.",
        "Adds two binary numbers and returns their sum as a string.",
        "Returns the binary representation of the bitwise OR operation of the two numbers.",
        "Returns the result of dividing A by B, as a string"
      ],
      "acceptable_answers": ["Adds two binary numbers and returns their sum as a string."],
      "problem_statement": `
              <div>
                <p>Consider the following pseudocode function <code>foo</code> which performs an operation on two bits:</p>
                <i><strong>Note that a bit is either a 1 or 0</strong></i>
                <pre>
      FUNCTION foo(A: bit, B: bit) -> string:
          resultString = "";
          
          leftVal: bit = A AND B // bitwise AND
          rightVal: bit = A XOR B // exclusive OR

          // string concatenation
          resultString += bitAsString(leftVal) // e.g. 1 will become "1"
          resultString += bitAsString(rightVal) // e.g. 0 will become "0"
      
          return resultString
      END FUNCTION
                </pre>
                <p><strong>Can you deduce what arithmetic operation is being performed on the bits?</p></strong>
              </div>
            `,
      "penalty": 0,
      "time_limit_in_minutes": 7,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Challenging,
    },
    "explanations": {
      "quick_html": `
              <div>
                <h2>Explanation:</h2>
                <p>The function <code>foo</code> returns the sum of two binary numbers as a string.</p>
              </div>
            `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.BitManipulation],
  },
  {
    "unit_id": 201,
    "access_level": AccessLevel.Standard,
    "title": "Complexity Analysis",
    "challenge": {
      "challenge_id": 201,
      "title": "Time Complexity of Nested Loops",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["O(n^2 * k * log n)", "O(n * m * k * log n)", "O(n * m * log n)", "O(n * m * k)", "O(n^3 * log n)"],
      "acceptable_answers": ["O(n * m * k * log n)"],
      "problem_statement": `
        <div>
          <p>Given 3 list of integers (array of integers) such that list1 has size n, list2 has size m, and list3 has size k </p> 
          <i><strong>Note: n, m and k are positive non-zero integers</strong></i>
          <p>Consider the following pseudocode:</p>
<pre>
  function analyzeComplexity(list1, list2, list3):
    for item1 in list1: // iterate through each item in list1
      for item2 in list2:
        for item3 in list3:
          // search for item3 in list1
          binarySearch(item3, list1)
</pre>
          <p><strong>What is the worst-case time complexity of the function?</strong></p>
        </div>
      `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward,
    },
    "explanations": {
      "quick_html": `
        <div>
          <h2>Explanation:</h2>
          <p>- The outer loop runs <code>n</code> times due to the iteration over list1.<br>
            - The middle loop runs <code>m</code> times due to the iteration over list2.<br>
            - The inner loop runs <code>k</code> times due to the iteration over list3.<br>
            - Within the innermost loop, we perform a binary search on a list of size <code>n</code>, which is O(log n).<br>
            Combining these, we get O(n * m * k * log n).</p>
        </div>
      `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.AsymptoticRuntimeComplexity],
  },
  {
    "unit_id": 202,
    "access_level": AccessLevel.Standard,
    "title": "Strings",
    "challenge": {
      "challenge_id": 202,
      "title": "Identifying Unique Characters",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["apple", "dog", "door", "banana", "''", "None. The code is correct"],
      "acceptable_answers": ["None. The code is correct"],
      "problem_statement": `
        <div>
          <p>Consider the following pseudocode that uses a naive algorithm to check whether all characters in a string are unique:</p>
          <pre>
            function hasUniqueChars(str):
                int n = length(str)
                for (int i = 0; i < n - 1; i++)
                    for (int j = i + 1; j < n; j++)
                        if (str[i] == str[j])
                            return False
                return True
          </pre>
          <p>Given the above pseudocode, which of the following strings will the function give a wrong result for?</p>
        </div>
      `,
      "penalty": 0,
      "time_limit_in_minutes": 5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
      "quick_html": `
        <div>
          <h2>Explanation:</h2>
          <p>The code correctly identifies if a string has all unique characters. Thus, it won't wrongly identify any of the provided strings.</p>
        </div>
      `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.String],
  },
  {
    "unit_id": 203,
    "access_level": AccessLevel.Standard,
    "title": "Arrays",
    "challenge": {
      "challenge_id": 203,
      "title": "Sum of 32-bit Integers",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["[500000, 2, 500000, 0]", "[2000000000, 1, 2000000000, -1000]", "[-1000000000, 2000000000]", "[10, 20, 30, 40]", "All test cases will be handled correctly"],
      "acceptable_answers": ["[2000000000, 1, 2000000000, -1000]"],
      "problem_statement": `
        <div>
          <p>Given the pseudocode below that calculates the sum of 32-bit integers in an array, which of the arrays (revealed if you decide to attempt), may not be processed properly?</p>
<pre>
  function calculateSum(arr: Array of 32-bit integers):
      sum = 0
      for num in arr:
          sum += num
      return sum
</pre>
        </div>
      `,
      "penalty": 0,
      "time_limit_in_minutes": 3,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
      "quick_html": `
        <div>
          <h2>Explanation:</h2>
          <p>32-bit integers can represent values from -2^31 to 2^31 - 1. Integer overflow will occur with the array [2000000000, 1, 2000000000, -1000], as the sum exceeds the upper limit which is .</p>
        </div>
      `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Array],
  },
  {
    "unit_id": 204,
    "access_level": AccessLevel.Standard,
    "title": "Second Largest Element in BST",
    "challenge": {
      "challenge_id": 204,
      "title": "Pseudocode for 2nd Largest BST Element",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "The pseudocode correctly finds the second largest element in the BST.",
        "The pseudocode does not handle the case where the BST has only one element.",
        "The pseudocode will fail if the root node has a right child, and the largest element in the BST has a left child.",
        "The pseudocode will return incorrect results if the second largest element is the root."
      ],
      "acceptable_answers": ["The pseudocode will fail if the root node has a right child, and the largest element in the BST has a left child."],
      "problem_statement": `
              <div>
                <p>Given the pseudocode function below, which attempts to find the second largest element in a Binary Search Tree, determine if the pseudocode correctly achieves its goal:</p>
                <pre>
function findSecondLargest(node):
  if node is null or (node.left is null and node.right is null):
    return null
  if node.right is null:
    return findMax(node.left)
  if node.right.left is null and node.right.right is null:
    return node.value
  return findSecondLargest(node.right)

function findMax(node):
  while node.right is not null:
    node = node.right
  return node.value
        </pre>
              </div>
            `,
      "penalty": 0,
      "time_limit_in_minutes": 7.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Challenging
    },
    "explanations": {
      "quick_html": `
              <div>
                <h2>Reasoning:</h2>
                <p>If the root has a right child, and the largest element (the rightmost element) in the BST has a left child, the pseudocode will incorrectly return the root of the tree as the second largest element. In reality, the second largest element in this scenario would be the largest element in the left subtree of the largest element.</p>
              </div>
            `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.BinarySearchTree],
  },
  {
    "unit_id": 205,
    "access_level": AccessLevel.Standard,
    "title": "Doubly-Linked List - Inserting a New Head",
    "challenge": {
      "challenge_id": 205,
      "title": "Doubly-Linked List: Insert as New Head",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": ["The function is fine.", "Line 8", "Line 4", "Line 5"],
      "acceptable_answers": ["The function is fine."],

      "problem_statement": `
          <div>
              <p>Given below is pseudocode for a function that inserts a new node at the beginning of a doubly-linked list:</p>
              <pre>
FUNCTION InsertAtStart(value, head):
    newNode = Node(value) // Line 1
    newNode.next = head 

    IF head IS NOT NULL THEN // Line 4
        head.prev = newNode
    END IF

    RETURN newNode // Line 8
END FUNCTION
              </pre>
              <p>Identify if there's an issue with the function. This function should return the new head of the list.</p>
          </div>
      `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward
    },
    "explanations": {
      "quick_html": `
          <div>
              <h2>Explanation:</h2>
              <p>The function is correct. When inserting a new node at the beginning of the doubly-linked list, the function ensures both the forward and backward links between nodes are maintained properly. If the list was not empty (i.e., head was not null), the previous pointer of the original head node is correctly set to point to the new node.</p>
          </div>
      `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.DoublyLinkedList],
  },

  {
    "unit_id": 206,
    "access_level": AccessLevel.Standard,
    "title": "Recursion",
    "challenge": {
      "challenge_id": 206,
      "title": "Fibonacci Function Analysis",
      "challenge_type": ChallengeType.KNOWLEDGE,
      "assessment_type": AssessmentType.TruthOrDespair,
      "assessment_options": [
        "It doesn't handle the case when n=1 correctly.",
        "It has exponential runtime complexity.",
        "The recursive calls will never terminate.",
        "There's no issue with the function."
      ],
      "acceptable_answers": ["It doesn't handle the case when n=1 correctly."],
      "problem_statement": `
        <div>
            <p>Below is a pseudocode of a recursive function intended to calculate the n-th Fibonacci number:</p>
  <pre>
      FUNCTION Fibonacci(n):
          IF n < 0 THEN:
            throw exception("n must be a non-negative integer")
          IF n < 1 THEN
              RETURN n
          END IF

          RETURN Fibonacci(n-1) + Fibonacci(n-2)
      END FUNCTION
  </pre>
            <p>The function is called with an argument <code>n</code> where <code>0 ≤ n ≤ 5</code>. Identify the issue, if any, with the function:</p>
        </div>
              `,
      "penalty": 0,
      "time_limit_in_minutes": 2.5,
      "max_attempts": 1,
      "hints": [],
      "difficulty": Difficulty.Straightforward,
    },
    "explanations": {
      "quick_html": `
<div>
    <h2>Explanation:</h2>
    <p>For the input <code>n=1</code>, the function tries to evaluate <code>Fibonacci(n-1) + Fibonacci(n-2)</code> which translates to <code>Fibonacci(0) + Fibonacci(-1)</code>. While <code>Fibonacci(0)</code> returns 0 correctly, <code>Fibonacci(-1)</code> throws an exception as per the defined condition. Thus, the function doesn't handle the case when <code>n=1</code> correctly.</p>
</div>
    `,
      "explain_like_im_five_years_old_html": null,
      "thought_process_html": null,
      "example_based_html": null
    },
    "tags": [UnitConceptTags.Recursion],
  },
]
const ASSESSMENT_2_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_2_ALGOS_AND_DS_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);


// Assessment 3
const ASSESSMENT_3_SYS_DESIGN_UNITS: Unit[] = [
  {
    "unit_id": 300,
    "access_level": AccessLevel.Standard,
    "title": "Selecting a database optimised for write operations",
    "challenge": {
        "challenge_id": 300,
        "title": "Selecting a database optimised for write operations",
        "challenge_type": ChallengeType.KNOWLEDGE,
        "assessment_type": AssessmentType.TruthOrDespair,
        "problem_statement": "<p>In a scenario where the data access pattern involves a <strong>high volume of write operations with minimal read requirements</strong>, which of the following databases is the most suitable choice for persisting this data?</p>",
        "assessment_options": [
            "A) PostgreSQL",
            "B) Cassandra",
            "C) MySQL",
            "D) NoSQL data stores"
        ],
        "acceptable_answers": [
            "B) Cassandra"
        ],
        "penalty": 1,
        "time_limit_in_minutes": 3,
        "max_attempts": 1,
        "hints": [],
        "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
        "quick_html": "<p>In a scenario where the data access pattern involves a high volume of write operations with minimal read requirements, <strong>Cassandra</strong> is the most suitable choice for persisting this data.</p><h1>Explanation:</h1><p><strong>Cassandra</strong> is a distributed NoSQL database that excels at handling high write throughput and is designed for scenarios where write-heavy workloads are predominant. It offers strong scalability and availability, even across multiple data centers.</p><ul><li><strong>PostgreSQL</strong> and <strong>MySQL</strong> are both relational databases (SQL-based) that are typically not optimized for write-heavy use cases at scale without additional tuning. They are generally better for read-heavy workloads with complex queries and transactions.</li><li><strong>MongoDB</strong> is a NoSQL database that's versatile and can handle a decent amount of writes, but it may not scale as efficiently as Cassandra for high write volumes in distributed environments.</li></ul><p>Thus, <strong>Cassandra</strong> is optimized for scenarios requiring high volumes of write operations.</p>",
        "explain_like_im_five_years_old_html": null,
        "thought_process_html": null,
        "example_based_html": null
    },
    "prerequisite_unit_ids": [],
    "tags": [
      UnitConceptTags.DatabaseSelection,
    ]
},
{
    "unit_id": 301,
    "access_level": AccessLevel.Standard,
    "title": "Selecting a database optimised for read operations",
    "challenge": {
        "challenge_id": 301,
        "title": "Selecting a database optimised for read operations",
        "challenge_type": ChallengeType.KNOWLEDGE,
        "assessment_type": AssessmentType.TruthOrDespair,
        "problem_statement": "<p>You have a data access pattern where there are significantly more reads than writes, or the system primarily handles read operations. Considering PostgreSQL's architecture and optimization, would PostgreSQL be a suitable database choice for persisting this data?</p>",
        "assessment_options": [
            "A) Yes",
            "B) No"
        ],
        "acceptable_answers": [
            "A) Yes"
        ],
        "penalty": 1,
        "time_limit_in_minutes": 3,
        "max_attempts": 1,
        "hints": [],
        "difficulty": Difficulty.ModeratelyChallenging,
    },
    "explanations": {
        "quick_html": "<p>Yes, PostgreSQL would be a suitable choice for a system that handles significantly more reads than writes. PostgreSQL is well-optimized for read-heavy workloads, especially when configured properly with features such as <strong>index optimization</strong>, <strong>caching</strong>, and <strong>read replicas</strong>. Here are a few reasons why PostgreSQL is a good fit for this scenario:</p><ul><li><strong>Efficient Indexing</strong>: PostgreSQL can leverage multiple types of indexes (e.g., B-trees, hash indexes) to ensure fast lookups for read-heavy queries. Techniques like <strong>covering indexes</strong> allow it to retrieve all needed data from the index itself without accessing the main table, significantly boosting read performance.</li><li><strong>Read Replicas</strong>: PostgreSQL supports <strong>streaming replication</strong>, allowing you to set up read replicas that can distribute the read load across multiple servers. This is particularly beneficial for scaling read-heavy workloads as it improves both availability and query performance.</li><li><strong>Caching and Query Optimization</strong>: By tuning PostgreSQL's caching mechanisms and optimizing queries, it can handle large volumes of read operations efficiently. Features like <strong>connection pooling</strong> and <strong>query performance insights</strong> can further enhance the speed and scalability for read-intensive systems.</li></ul><p>In summary, PostgreSQL's architecture, especially when combined with the use of read replicas and optimized indexing, makes it an excellent choice for systems where reads significantly outnumber writes.</p>",
        "explain_like_im_five_years_old_html": null,
        "thought_process_html": null,
        "example_based_html": null
    },
    "prerequisite_unit_ids": [],
    "tags": [
      UnitConceptTags.DatabaseSelection,
    ]
},
{
  "unit_id": 303,
  "access_level": AccessLevel.Standard,
  "title": "Overcoming Stale Reads from Distributed Cache",
  "challenge": {
    "challenge_id": 303,
    "title": "Overcoming Stale Reads from Distributed Cache",
    "challenge_type": ChallengeType.KNOWLEDGE,
    "assessment_type": AssessmentType.TruthOrDespair,
    "problem_statement": "<p>You are using a distributed cache with a <strong>leader-follower architecture</strong> (also known as <strong>single leader, multiple read replicas</strong>). Your goal is to read from any of the read replicas without worrying about stale data. Which of the following is the <strong>worst</strong> strategy to achieve this?</p>",
    "assessment_options": [
      "A) Quorum reads",
      "B) Configure strong consistency for the replication process",
      "C) Implement read-your-writes consistency, ensuring that each client sees their own updates immediately across any replica they read from.",
      "D) Allow eventual consistency by configuring asynchronous replication"
    ],
    "acceptable_answers": [
      "D) Allow eventual consistency by configuring asynchronous replication"
    ],
    "penalty": 1,
    "time_limit_in_minutes": 5,
    "max_attempts": 1,
    "hints": [],
    "difficulty": Difficulty.ModeratelyChallenging,
  },
  "explanations": {
    "quick_html": "<p>In a leader-follower architecture, allowing eventual consistency through asynchronous replication increases the chances of reading stale data from replicas, as they may lag behind the leader.</p><h1>Explanation:</h1><p><strong>Quorum reads</strong> and configuring <strong>strong consistency</strong> in replication are strategies to avoid reading stale data by ensuring majority agreement or synchronous updates. <strong>Read-your-writes consistency</strong> ensures clients see their own updates immediately. In contrast, allowing <strong>eventual consistency</strong> leads to potential data staleness because replicas may not be immediately synchronized.</p>",
    "explain_like_im_five_years_old_html": null,
    "thought_process_html": null,
    "example_based_html": null
  },
  "prerequisite_unit_ids": [],
  "tags": [
    UnitConceptTags.DistributedCache
  ]
},
{
  "unit_id": 304,
  "access_level": AccessLevel.Standard,
  "title": "Identifying the Versatile Component",
  "challenge": {
    "challenge_id": 304,
    "title": "Identifying the Versatile Component",
    "challenge_type": ChallengeType.KNOWLEDGE,
    "assessment_type": AssessmentType.TruthOrDespair,
    "problem_statement": "<p>There is a service within an architecture that performs the following roles: <strong>rate limiting</strong> for incoming client requests, <strong>authentication</strong> of client requests, <strong>request transformation</strong> (e.g., converting an HTTP GET request into a remote procedure call), and <strong>forwarding client requests</strong> to backend services. Which of the following components can play this role?</p>",
    "assessment_options": [
      "A) Global Load Balancer",
      "B) Local Load Balancer",
      "C) API Gateway",
      "D) Reverse Proxy",
      "E) Microservice"
    ],
    "acceptable_answers": [
      "C) API Gateway"
    ],
    "penalty": 1,
    "time_limit_in_minutes": 4,
    "max_attempts": 1,
    "hints": [],
    "difficulty": Difficulty.Straightforward,
    "test_cases": [],
    "key_associations": [
      "An API Gateway handles rate limiting, authentication, request transformation, and routing of client requests to backend services."
    ],
    "rewards": []
  },
  "explanations": {
    "quick_html": "<p>The correct answer is <strong>API Gateway</strong>. API gateways provide central management of API traffic, handling roles such as rate limiting, authentication, request transformation, and routing requests to appropriate backend services. A <strong>global load balancer</strong> or <strong>local load balancer</strong> primarily handles distributing traffic across servers, but they do not handle the additional functions like request transformation or authentication. A <strong>reverse proxy</strong> typically forwards client requests to backend servers, but it lacks the full capabilities of an API Gateway.</p>",
    "explain_like_im_five_years_old_html": null,
    "thought_process_html": null,
    "example_based_html": null
  },
  "prerequisite_unit_ids": [],
  "tags": [
    UnitConceptTags.ApiDesign
  ]
}, 
{
  "unit_id": 305,
  "access_level": AccessLevel.Standard,
  "title": "Delivering Real-Time Updates I",
  "challenge": {
    "challenge_id": 305,
    "title": "Delivering Real-Time Updates I",
    "challenge_type": ChallengeType.KNOWLEDGE,
    "assessment_type": AssessmentType.TruthOrDespair,
    "problem_statement": "<p>You are designing a system to deliver live soccer match event updates to a front-end web application. The updates include events like goals, start of match, and end of match. These updates are sent in JSON format, with a sample payload that might look like:</p><pre><code>{\n    \"event\": \"goal\",\n    \"time\": \"45+2\",\n    \"team\": \"Team A\",\n    \"player\": \"Player X\",\n    \"score\": \"1-0\"\n}</code></pre><p>The payload is small and contains only essential match information. During a typical 120-minute match (including extra time), you estimate a maximum of 20-30 updates, based on the number of events.</p><p>Clients should receive these updates in near real-time, but only when an event occurs. The client doesn't need to send data back to the server, and the updates are one-way (from server to client).</p><p>Which of the following is the best option for delivering live updates to the client?</p>",
    "assessment_options": [
      "A) Polling",
      "B) WebSocket",
      "C) Server-Sent Events (SSE)",
      "D) HTTP/2 Push"
    ],
    "acceptable_answers": [
      "C) Server-Sent Events (SSE)"
    ],
    "penalty": 1,
    "time_limit_in_minutes": 4,
    "max_attempts": 1,
    "hints": [],
    "difficulty": Difficulty.Straightforward,
  },
  "explanations": {
    "quick_html": "<h1>Answer Explanation:</h1><p><strong>A) Polling:</strong> Polling involves the client regularly sending requests to the server (e.g., every few seconds) to check if there's an update. While this can work, it's inefficient for your scenario because updates are infrequent, and the client would be repeatedly requesting data even when there's nothing new. This wastes resources and bandwidth.</p><p><strong>B) WebSocket:</strong> WebSocket establishes a persistent, two-way connection between the client and the server. While this works for real-time communication, the client doesn't need to send data back to the server. The overhead of maintaining a bi-directional connection is unnecessary for this one-way communication scenario.</p><p><strong>C) Server-Sent Events (SSE):</strong> This is the best option for your scenario. SSE creates a persistent, one-way connection where the server can push updates to the client as events happen. It's lightweight, efficient, and designed for streaming real-time updates in text-based formats (like JSON). The client doesn't have to poll or maintain a two-way connection, making it ideal for live score updates.</p><p><strong>D) HTTP/2 Push:</strong> HTTP/2 Push allows the server to send resources to the client without the client explicitly requesting them, but it's better suited for static assets like images or scripts. It's not designed for continuously pushing real-time event updates, so it's not an optimal choice for live match events.</p>",
    "explain_like_im_five_years_old_html": null,
    "thought_process_html": null,
    "example_based_html": null
  },
  "prerequisite_unit_ids": [],
  "tags": [
    UnitConceptTags.EventStreaming
  ]
}
  
]
const ASSESSMENT_3_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_3_SYS_DESIGN_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);

// Assessment 4
const ASSESSMENT_4_NETWORKS_UNITS: Unit[] = [
  {
    unit_id: 400,
    access_level: AccessLevel.Standard,
    title: "Choosing Public/Private IP Configuration",
    challenge: {
      challenge_id: 400,
      title: "Choosing Public/Private IP Configuration",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>An application server hosts a public-facing API, while its backend database must remain accessible only to internal systems. 
          Which IP addressing strategy best meets these requirements?</p>
        </div>
      `,
      assessment_options: [
        "Both the application server and database use public IPs for flexibility.",
        "The application server uses a public IP; the database uses a private IP.",
        "Both the application server and database use private IPs and rely on NAT for public access.",
        "The application server uses a loopback address, and the database uses a public IP."
      ],
      acceptable_answers: [
        "The application server uses a public IP; the database uses a private IP."
      ],
      penalty: 0,
      time_limit_in_minutes: 2,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The public-facing application server requires a public IP for external accessibility, while the database should be assigned a private IP to ensure it remains isolated from the public internet.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
  {
    unit_id: 401,
    access_level: AccessLevel.Standard,
    title: "Device identification in network troubleshooting",
    challenge: {
      challenge_id: 401,
      title: "Device identification in network troubleshooting",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
      <div>
        <p>A network engineer is troubleshooting a device causing network issues on a monitored local network.
        Which identifier will uniquely identify the device at the hardware level?</p>
      </div>
    `,

      assessment_options: [
        "IP address",
        "MAC address",
        "Hostname",
        "Port number"
      ],
      acceptable_answers: [
        "MAC address",
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The MAC address is a unique hardware-level identifier for a network interface on the local network, 
          allowing the engineer to pinpoint exactly which device is involved in the issue.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
  {
    unit_id: 402,
    access_level: AccessLevel.Standard,
    title: "Protocol Selection for Buffered Streaming",
    challenge: {
      challenge_id: 402,
      title: "Protocol Selection for Buffered Streaming",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A video streaming service optimizes its protocol choices for stable viewing. 
          For buffered, reliable video playback that can tolerate slight delays to maintain quality, which protocol is typically preferred?</p>
        </div>
      `,
      assessment_options: [
        "UDP, because it ignores packet loss.",
        "TCP, because it ensures reliable, in-order delivery.",
        "HTTP over UDP for combined reliability and speed.",
        "FTP over TCP for file-transfer-like behavior."
      ],
      acceptable_answers: [
        "TCP, because it ensures reliable, in-order delivery."
      ],
      penalty: 0,
      time_limit_in_minutes: 2,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>For stable, high-quality streaming that can buffer content, TCP's guaranteed delivery and in-order transmission are beneficial, 
          ensuring the viewer receives a consistent stream without data gaps.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
  {
    unit_id: 403,
    access_level: AccessLevel.Standard,
    title: "What does TLS provide?",
    challenge: {
      challenge_id: 403,
      title: "What does TLS provide?",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>Websites typically use HTTPS to secure login pages. HTTPS relies on TLS. 
          What does TLS primarily provide to ensure secure communication?</p>
        </div>
      `,
      assessment_options: [
        "Assigns a public IP address to each user.",
        "Ensures both encryption and authentication of the communication channel.",
        "Compresses data to reduce transfer times.",
        "Eliminates the need for DNS resolution."
      ],
      acceptable_answers: [
        "Ensures both encryption and authentication of the communication channel."
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>TLS secures the connection by encrypting the data transferred and authenticating the server's identity, 
          preventing eavesdropping and impersonation attacks.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
  {
    unit_id: 404,
    access_level: AccessLevel.Standard,
    title: "Pinging",
    challenge: {
      challenge_id: 404,
      title: "Pinging",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A network administrator troubleshooting latency on a video conferencing service sends ICMP echo requests (ping) to the server. 
          What does a successful ping mainly confirm?</p>
        </div>
      `,
      assessment_options: [
        "End-to-end IP-level connectivity between source and destination.",
        "Proper packet encryption is in place.",
        "The DNS server is authoritative for the domain.",
        "The TCP three-way handshake has completed successfully."
      ],
      acceptable_answers: [
        "End-to-end IP-level connectivity between source and destination."
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>A successful ping (ICMP echo reply) confirms that packets can travel between the devices at the IP layer, 
          indicating basic connectivity but not ensuring application-level functionality.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
  {
    unit_id: 405,
    access_level: AccessLevel.Standard,
    title: "Understanding the Three-Way Handshake",
    challenge: {
      challenge_id: 405,
      title: "Understanding the Three-Way Handshake",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>When a user connects to a streamed video call over TCP, 
          what is the main purpose of the three-way handshake before any data transfer?</p>
        </div>
      `,
      assessment_options: [
        "To negotiate encryption keys for secure communication during data transfer.",
        "To confirm readiness and synchronize sequence numbers so that both parties can exchange data",
        "To select the optimal DNS resolver for establishing a faster connection to the server.",
        "To establish a VPN tunnel for secure communication over an encrypted private network."
      ],      
      acceptable_answers: [
        "To confirm readiness and synchronize sequence numbers so that both parties can exchange data"
      ],
      
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The TCP three-way handshake (SYN, SYN-ACK, ACK) ensures both sides agree on initial sequence numbers and are ready to exchange data, 
          establishing a reliable connection before transmission begins.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
  {
    unit_id: 406,
    access_level: AccessLevel.Standard,
    title: "Browser-to-Page Rendering Steps",
    challenge: {
      challenge_id: 406,
      title: "Browser-to-Page Rendering Steps",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A user visits Facebook News Feed (a social media feed by Meta) via a browser. 
          Which best describes the browser’s early actions in retrieving and rendering the page?</p>
        </div>
      `,
      assessment_options: [
        "The browser parses HTML from memory without any network requests.",
        "The browser sends a DNS query to resolve the domain and then issues an HTTP(S) request for the page content.",
        "The browser starts TLS negotiation only after fully rendering the page.",
        "The browser relies solely on local cache and avoids contacting the server."
      ],
      acceptable_answers: [
        "The browser sends a DNS query to resolve the domain and then issues an HTTP(S) request for the page content."
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Before rendering the content, the browser resolves the domain name via DNS to get an IP address, 
          then sends an HTTP(S) request to retrieve the HTML, which it then parses to eventually render the page.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
  {
    unit_id: 407,
    access_level: AccessLevel.Standard,
    title: "Identifying Network Latency Issues",
    challenge: {
      challenge_id: 407,
      title: "Identifying Network Latency Issues",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>An engineer at at a video streaming service notices increased buffering for users in a specific region. 
          What initial networking test can help isolate whether the problem is due to latency along the path to the server?</p>
        </div>
      `,
      assessment_options: [
        "Running traceroute to identify slow network hops.",
        "Checking MAC addresses of all local devices.",
        "Using a loopback IP address to test the server locally.",
        "Adjusting TLS configurations on the client."
      ],
      acceptable_answers: [
        "Running traceroute to identify slow network hops."
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Traceroute shows the path packets take and the latency at each hop, helping pinpoint where delays occur along the route to the server.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
];
const ASSESSMENT_4_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_4_NETWORKS_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);

// Assessment 5
const ASSESSMENT_5_NETWORKS_UNITS: Unit[] = [
  {
    unit_id: 500,
    access_level: AccessLevel.Standard,
    title: "Selecting a Secure File Transfer Method",
    challenge: {
      challenge_id: 500,
      title: "Selecting a Secure File Transfer Method",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>An engineer needs to securely transfer a sensitive configuration file between two Linux servers in the same data center.
          Which method provides encryption to protect the file during transit?</p>
        </div>
      `,
      assessment_options: [
        "TFTP",
        "An HTTP PUT request",
        "SCP",
        "FTP"
      ],
      acceptable_answers: [
        "SCP"
      ],
      penalty: 0,
      time_limit_in_minutes: 2,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>SCP (Secure Copy Protocol) uses SSH for encryption, ensuring the file cannot be easily intercepted or read in transit.
          TFTP, HTTP PUT, and FTP do not provide this level of built-in encryption by default.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },

  {
    unit_id: 501,
    access_level: AccessLevel.Standard,
    title: "Redirecting Outputs for System Processes",
    challenge: {
      challenge_id: 501,
      title: "Redirecting Outputs for System Processes",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A system administrator runs a background script and needs to capture normal output and errors in separate log files.
          Which pair of file descriptors should be redirected?</p>
        </div>
      `,
      assessment_options: [
        "File descriptor 1 (standard output) and file descriptor 2 (standard error)",
        "File descriptor 4 (extra output) and file descriptor 3 (debug log)",
        "File descriptor 0 (standard input) and file descriptor 1 (standard output)",
        "File descriptor 2 (standard error) and a custom descriptor (3) for errors"
      ],
      acceptable_answers: [
        "File descriptor 1 (standard output) and file descriptor 2 (standard error)"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Standard output (stdout) is represented by file descriptor 1 and standard error (stderr) by file descriptor 2.
          Redirecting these two lets you separate normal output from error messages.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },

  {
    unit_id: 502,
    access_level: AccessLevel.Standard,
    title: "Steps in Resolving a Domain Name",
    challenge: {
      challenge_id: 502,
      title: "Steps in Resolving a Domain Name",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A user’s computer attempts to access "www.microsoft.com" for the first time today.
          Which sequence best describes how the hostname is typically resolved to an IP address?</p>
        </div>
      `,
      assessment_options: [
        "Check local hosts file, then ask a recursive DNS server, which queries root, TLD, and authoritative DNS servers",
        "Use cached entries in the browser for all domains",
        "Ask the client's local router, which always knows the IP",
        "Contact Microsoft's server directly, which returns its own IP"
      ],
      acceptable_answers: [
        "Check local hosts file, then ask a recursive DNS server, which queries root, TLD, and authoritative DNS servers"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The standard DNS resolution process involves the client checking local configurations (like the hosts file),
          then making a request to a recursive DNS server, which consults root, then TLD, then authoritative servers to find the correct IP.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },

  {
    unit_id: 503,
    access_level: AccessLevel.Standard,
    title: "Understanding DNS Hierarchy",
    challenge: {
      challenge_id: 503,
      title: "Understanding DNS Hierarchy",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>An IT trainee is learning about DNS structure.
          How do root DNS servers differ from authoritative DNS servers?</p>
        </div>
      `,
      assessment_options: [
        "Root servers store every domain's IP address directly",
        "Root and authoritative servers are functionally identical",
        "Authoritative servers always redirect queries back to root",
        "Root servers provide high-level referrals (like TLDs), while authoritative servers hold the final domain’s IP mapping"
      ],
      acceptable_answers: [
        "Root servers provide high-level referrals (like TLDs), while authoritative servers hold the final domain’s IP mapping"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Root DNS servers don’t store every IP. They direct queries to TLD servers, and eventually, 
          an authoritative server provides the specific IP for the requested domain.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },

  {
    unit_id: 504,
    access_level: AccessLevel.Standard,
    title: "Choosing a Protocol for Secure Web Communication",
    challenge: {
      challenge_id: 504,
      title: "Choosing a Protocol for Secure Web Communication",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>An online banking platform must ensure user credentials and transactions are protected from eavesdroppers.
          Which approach helps achieve this?</p>
        </div>
      `,
      assessment_options: [
        "Use HTTPS to encrypt the data in transit and validate the server's identity",
        "Use HTTP to keep data in clear text, relying on trust in the ISP",
        "Use HTTP but on a higher port number",
        "Use a custom TCP handshake without TLS"
      ],
      acceptable_answers: [
        "Use HTTPS to encrypt the data in transit and validate the server's identity"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>HTTPS, which employs TLS, encrypts the data and verifies the server’s authenticity, 
          ensuring that user information remains confidential and protected from tampering.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },

  {
    unit_id: 505,
    access_level: AccessLevel.Standard,
    title: "Isolating Public-Facing Services",
    challenge: {
      challenge_id: 505,
      title: "Isolating Public-Facing Services",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>An organization places its public-facing web server into a DMZ.
          What is the main advantage of placing a service in a DMZ?</p>
        </div>
      `,
      assessment_options: [
        "It ensures internal servers become accessible to the public by default",
        "It isolates externally-facing servers, reducing risk to internal systems if compromised",
        "It stores sensitive internal data where external users can reach it easily",
        "It bypasses all firewalls for better performance"
      ],
      acceptable_answers: [
        "It isolates externally-facing servers, reducing risk to internal systems if compromised"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>A DMZ serves as a buffer zone. Placing the public server there limits the impact on the internal network if the public server is compromised.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },

  {
    unit_id: 506,
    access_level: AccessLevel.Standard,
    title: "Adjusting to Network Congestion",
    challenge: {
      challenge_id: 506,
      title: "Adjusting to Network Congestion",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A streaming application detects that its data packets are experiencing increased delay and some losses.
          How does TCP typically respond to signs of congestion?</p>
        </div>
      `,
      assessment_options: [
        "It continuously increases its sending rate regardless of feedback",
        "It reduces its sending rate when it detects packet loss or significant delay",
        "It switches to UDP to handle congestion",
        "It depends entirely on DNS responses to adjust speed"
      ],
      acceptable_answers: [
        "It reduces its sending rate when it detects packet loss or significant delay"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>TCP uses congestion control algorithms. When it detects congestion (often indicated by packet loss or delay), 
          it lowers its sending rate to help alleviate network overload.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },

  {
    unit_id: 507,
    access_level: AccessLevel.Standard,
    title: "Identifying Transport Layer Issues",
    challenge: {
      challenge_id: 507,
      title: "Identifying Transport Layer Issues",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A user complains that their web requests are very slow.
          The engineer suspects a transport-layer problem. Which scenario most likely indicates a transport-layer issue rather than an application or physical layer one?</p>
        </div>
      `,
      assessment_options: [
        "Physical damage to a cable causing intermittent signals",
        "Incorrect DNS entries causing failed lookups",
        "Packet loss and delayed acknowledgments interfering with reliable delivery",
        "Malformed HTTP headers causing server-side errors"
      ],
      acceptable_answers: [
        "Packet loss and delayed acknowledgments interfering with reliable delivery"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The transport layer (e.g., TCP) deals with reliable end-to-end communication. 
          Packet loss and delayed ACKs are classic indicators of a transport-layer issue, rather than physical damage, DNS errors, or HTTP formatting problems.</p>
        </div>
      `,
      explain_like_im_five_years_old_html: null,
      thought_process_html: null,
      example_based_html: null
    },
    tags: [UnitConceptTags.Networks],
  },
];

const ASSESSMENT_5_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_5_NETWORKS_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);

// Assessment 6
const ASSESSMENT_6_OPERATING_SYSTEMS_UNITS: Unit[] = [
  {
    unit_id: 600,
    access_level: AccessLevel.Standard,
    title: "Linux Boot Sequence",
    challenge: {
      challenge_id: 1000,
      title: "Linux Boot Sequence",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>In a typical Linux boot process, what is the correct high-level sequence from power-on to the login prompt?</p>
        </div>
      `,
      assessment_options: [
        "BIOS/UEFI → Kernel → Bootloader → init/systemd → Login prompt",
        "Bootloader → BIOS/UEFI → init/systemd → Kernel → Login prompt",
        "BIOS/UEFI → Bootloader → Kernel initialization → init/systemd start → Login prompt",
        "BIOS/UEFI → Bootloader → Kernel initialization → init/systemd start → immediate desktop environment with no login"
      ],
      acceptable_answers: [
        "BIOS/UEFI → Bootloader → Kernel initialization → init/systemd start → Login prompt"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The standard sequence: BIOS/UEFI initializes hardware, the bootloader loads and starts the kernel, the kernel initializes and hands off to init/systemd, which starts services and finally presents a login prompt.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 601,
    access_level: AccessLevel.Standard,
    title: "User-Kernel Mode Switch",
    challenge: {
      challenge_id: 1002,
      title: "User-Kernel Mode Switch",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>When a user program makes a system call, how does it transition from user mode to kernel mode?</p>
        </div>
      `,
      assessment_options: [
        "A software-triggered trap (system call instruction) switches execution from user to kernel mode",
        "The process sets a special CPU register directly to enter kernel mode",
        "The kernel continuously polls user processes to detect a request",
        "A user-level library directly runs kernel instructions without a mode switch"
      ],
      acceptable_answers: [
        "A software-triggered trap (system call instruction) switches execution from user to kernel mode"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>A system call typically uses a special CPU instruction (like syscall) to trigger a controlled transition into kernel mode.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 602,
    access_level: AccessLevel.Standard,
    title: "Zombie Process Characteristics",
    challenge: {
      challenge_id: 1004,
      title: "Zombie Process Characteristics",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>What best describes a zombie process?</p>
        </div>
      `,
      assessment_options: [
        "A zombie process has exited but remains until the parent reads its exit status, making it effectively unkillable until reaped",
        "A zombie process is one that is sleeping indefinitely and can be killed with SIGKILL",
        "A zombie process is a kernel thread waiting for I/O and cleaned automatically by init",
        "A zombie process is a process without allocated memory waiting for manual memory assignment"
      ],
      acceptable_answers: [
        "A zombie process has exited but remains until the parent reads its exit status, making it effectively unkillable until reaped"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Zombie processes have completed execution but still occupy a slot in the process table until their parent calls wait() to collect their exit code.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 603,
    access_level: AccessLevel.Standard,
    title: "Signal Handling Mechanism",
    challenge: {
      challenge_id: 1007,
      title: "Signal Handling Mechanism",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>When a process receives a signal like SIGTERM, how does the kernel allow the process to handle or ignore it?</p>
        </div>
      `,
      assessment_options: [
        "The kernel sets a pending signal flag, and the process checks and handles it when returning from system calls",
        "The kernel rewrites the process's code to jump immediately into a signal handler",
        "The process must regularly call a 'check_signal()' syscall to detect signals",
        "A separate daemon injects the signal handler code into the process memory"
      ],
      acceptable_answers: [
        "The kernel sets a pending signal flag, and the process checks and handles it when returning from system calls"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Signals are delivered asynchronously; the kernel marks them pending, and when the process returns to user mode, it runs the appropriate handler or uses the default action.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 604,
    access_level: AccessLevel.Standard,
    title: "fork() vs exec()",
    challenge: {
      challenge_id: 1009,
      title: "fork() vs exec()",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>In UNIX-like systems, how do 'fork()' and 'exec()' differ in process creation?</p>
        </div>
      `,
      assessment_options: [
        "fork() merges two running processes into one, while exec() splits them again",
        "fork() creates a new thread in the same process, exec() changes the thread's priority",
        "fork() replaces the calling process image, exec() creates multiple children",
        "fork() creates a new child process as a copy of the parent, and exec() replaces the current process image with a new program"
      ],
      acceptable_answers: [
        "fork() creates a new child process as a copy of the parent, and exec() replaces the current process image with a new program"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>fork() duplicates the calling process, creating a child, while exec() replaces the current process’s code and data with a new program.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 605,
    access_level: AccessLevel.Standard,
    title: "Handling Large Memory Requests",
    challenge: {
      challenge_id: 1010,
      title: "Handling Large Memory Requests",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>A program requests 1TB of RAM when only 16GB is physically available. How can Linux handle this scenario?</p>
        </div>
      `,
      assessment_options: [
        "The system uses virtual memory and swap, allowing inactive pages to be moved to disk so the process can have a large address space",
        "The kernel immediately denies the request and kills the process",
        "The kernel compresses memory in-place to fit the requested RAM",
        "The kernel offloads part of the RAM onto the GPU's VRAM"
      ],
      acceptable_answers: [
        "The system uses virtual memory and swap, allowing inactive pages to be moved to disk so the process can have a large address space"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Linux employs virtual memory and swap space, enabling processes to use address spaces larger than physical RAM by paging out less-used pages to disk.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 606,
    access_level: AccessLevel.Standard,
    title: "Virtual vs Physical Memory",
    challenge: {
      challenge_id: 1012,
      title: "Virtual vs Physical Memory",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>How does Linux use virtual memory to present each process with a large, continuous address space, often larger than physical RAM?</p>
        </div>
      `,
      assessment_options: [
        "Linux merges all processes into one big shared memory space",
        "Linux uses virtual memory, mapping virtual addresses to physical frames on-demand, isolating processes and extending available space",
        "Virtual memory is a network drive that stores pages remotely",
        "Virtual and physical memory are identical with no translation needed"
      ],
      acceptable_answers: [
        "Linux uses virtual memory, mapping virtual addresses to physical frames on-demand, isolating processes and extending available space"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Virtual memory allows the OS to give each process its own isolated address space, translating virtual addresses to physical frames as needed and often using more memory than physically available via paging.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 607,
    access_level: AccessLevel.Standard,
    title: "What is a Filesystem",
    challenge: {
      challenge_id: 1015,
      title: "What is a Filesystem",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>Which choice best describes the role of a filesystem in Linux?</p>
        </div>
      `,
      assessment_options: [
        "A filesystem is a CPU scheduling algorithm for I/O tasks",
        "A filesystem is a hardware controller mapping files directly to CPU registers",
        "A filesystem organizes data on storage devices, mapping filenames to inodes and blocks, creating a hierarchical structure",
        "A filesystem is a shell script that sorts filenames alphabetically"
      ],
      acceptable_answers: [
        "A filesystem organizes data on storage devices, mapping filenames to inodes and blocks, creating a hierarchical structure"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>A filesystem manages how data is stored and retrieved on storage media, using structures like directories and inodes to organize files in a hierarchy.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 608,
    access_level: AccessLevel.Standard,
    title: "Troubleshooting Application Startup",
    challenge: {
      challenge_id: 1018,
      title: "Troubleshooting Application Startup",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>An application fails to start on your Linux server. What is a sensible first step to diagnose the issue?</p>
        </div>
      `,
      assessment_options: [
        "Reinstall the entire operating system immediately",
        "Kill all other processes to free memory",
        "Increase CPU frequency for faster startup",
        "Review logs, verify configurations, and consider using tools like 'strace' to identify the root cause"
      ],
      acceptable_answers: [
        "Review logs, verify configurations, and consider using tools like 'strace' to identify the root cause"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Examining logs and configuration, along with debugging tools, helps pinpoint why the application isn't starting without drastic or guesswork solutions.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 609,
    access_level: AccessLevel.Standard,
    title: "Understanding strace",
    challenge: {
      challenge_id: 1019,
      title: "Understanding strace",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>The 'strace' tool is used during troubleshooting. How does it operate to reveal what a program does at runtime?</p>
        </div>
      `,
      assessment_options: [
        "strace reads the source code comments to guess system calls",
        "strace modifies the kernel to print system calls to the console",
        "strace uses ptrace to intercept and log every system call, displaying their arguments and return values",
        "strace only shows network-related operations, ignoring file I/O"
      ],
      acceptable_answers: [
        "strace uses ptrace to intercept and log every system call, displaying their arguments and return values"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>strace leverages the ptrace system call to intercept and record all system calls a process makes, helping in understanding its behavior.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
];

const ASSESSMENT_6_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_6_OPERATING_SYSTEMS_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);

// Assessment 7

const ASSESSMENT_7_OPERATING_SYSTEMS_UNITS: Unit[] = [
  {
    unit_id: 700,
    access_level: AccessLevel.Standard,
    title: "Kernel-Level Actions for 'ls -l'",
    challenge: {
      challenge_id: 1001,
      title: "Kernel-Level Actions for 'ls -l'",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>When you run "ls -l" in a shell, which option best represents the kernel-level sequence before directory listings are printed?</p>
        </div>
      `,
      assessment_options: [
        "The shell interprets 'ls -l', sends a direct memory copy of directory data to 'ls', and 'ls' prints it",
        "The 'ls' binary includes a prebuilt list of files, no kernel involvement is needed",
        "The shell directly requests the filesystem daemon to print directory contents to stdout",
        "The shell runs 'ls', the kernel loads 'ls' into memory, 'ls' makes system calls to read directory entries, the kernel returns data, and then 'ls' formats and prints results"
      ],
      acceptable_answers: [
        "The shell runs 'ls', the kernel loads 'ls' into memory, 'ls' makes system calls to read directory entries, the kernel returns data, and then 'ls' formats and prints results"
      ],
      penalty: 0,
      time_limit_in_minutes: 1.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>'ls' is a user-space program. The shell executes 'ls', the kernel loads it, and 'ls' uses system calls like readdir() to get directory data from the kernel before printing it.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 701,
    access_level: AccessLevel.Standard,
    title: "/proc Filesystem Role",
    challenge: {
      challenge_id: 1003,
      title: "/proc Filesystem Role",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>What is the nature of the "/proc" filesystem and how does it let you interact with kernel data?</p>
        </div>
      `,
      assessment_options: [
        "/proc is a memory-mapped I/O area that only stores binary kernel data",
        "/proc is a static on-disk database of kernel parameters updated once at boot",
        "/proc is a filesystem containing encrypted kernel logs accessible only by root",
        "/proc is a virtual filesystem that exposes process and kernel info as files, enabling file-like interaction with kernel data"
      ],
      acceptable_answers: [
        "/proc is a virtual filesystem that exposes process and kernel info as files, enabling file-like interaction with kernel data"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>/proc is a virtual filesystem presenting kernel and process information as files, allowing reading and writing kernel parameters in a familiar file-based manner.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 702,
    access_level: AccessLevel.Standard,
    title: "Signals on Child Termination",
    challenge: {
      challenge_id: 1005,
      title: "Signals on Child Termination",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>When a child process terminates, which signal is typically sent to its parent process?</p>
        </div>
      `,
      assessment_options: [
        "SIGSTOP",
        "SIGKILL",
        "SIGCHLD",
        "SIGPIPE"
      ],
      acceptable_answers: [
        "SIGCHLD"
      ],
      penalty: 0,
      time_limit_in_minutes: 0.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>When a child finishes, the parent receives SIGCHLD, prompting it to call wait() and collect the child's exit status.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 703,
    access_level: AccessLevel.Standard,
    title: "Making a Process a Service",
    challenge: {
      challenge_id: 1006,
      title: "Making a Process a Service",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>You want your custom binary to run continuously and restart if it crashes. How can you properly run it as a service?</p>
        </div>
      `,
      assessment_options: [
        "Start it with '&' in a terminal and leave the terminal open",
        "Use 'cron' to run it every minute as a workaround",
        "Add the command to /etc/rc.local with no supervision",
        "Create a systemd unit file and enable it"
      ],
      acceptable_answers: [
        "Create a systemd unit file and enable it"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>systemd can manage the lifecycle of services, ensure they run at boot, restart on failure, and handle logging.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 704,
    access_level: AccessLevel.Standard,
    title: "How 'kill -9' Works",
    challenge: {
      challenge_id: 1008,
      title: "How 'kill -9' Works",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>Sending 'kill -9 &lt;pid&gt;' (SIGKILL) to a process forces termination. How does this operate at the kernel level?</p>
        </div>
      `,
      assessment_options: [
        "kill -9 only prints a message and doesn't affect the process",
        "The kill system call instructs the kernel to set a pending SIGKILL, ensuring the process is terminated as soon as it returns to user mode",
        "The kill command directly modifies the process's memory via /proc/<pid>/mem and then forcefully terminates it",
        "The shell triggers a CPU exception that halts the process immediately"
      ],
      acceptable_answers: [
        "The kill system call instructs the kernel to set a pending SIGKILL, ensuring the process is terminated as soon as it returns to user mode"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>kill -9 sets a non-catchable SIGKILL pending. The kernel enforces this immediately when the process is next scheduled, causing termination.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 705,
    access_level: AccessLevel.Standard,
    title: "Purpose of Swap Area",
    challenge: {
      challenge_id: 1011,
      title: "Purpose of Swap Area",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>What is the main purpose of the swap area in Linux memory management?</p>
        </div>
      `,
      assessment_options: [
        "To store user home directories for quick access",
        "To log disk I/O requests before execution",
        "To serve as an extension of RAM by paging out inactive pages to disk",
        "To compile kernel modules on-the-fly"
      ],
      acceptable_answers: [
        "To serve as an extension of RAM by paging out inactive pages to disk"
      ],
      penalty: 0,
      time_limit_in_minutes: 0.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Swap provides extra space on disk to hold pages that aren’t actively used, freeing physical RAM for more active tasks.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 706,
    access_level: AccessLevel.Standard,
    title: "Handling a Page Fault",
    challenge: {
      challenge_id: 1013,
      title: "Handling a Page Fault",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>When a process accesses an unmapped memory address and triggers a page fault, what does the kernel typically do?</p>
        </div>
      `,
      assessment_options: [
        "It sends SIGSEGV to the process for every fault",
        "It reboots the system to resolve the mapping",
        "It arbitrarily swaps out unrelated pages, ignoring the requested page",
        "It fetches or maps the required page into memory and then resumes the process"
      ],
      acceptable_answers: [
        "It fetches or maps the required page into memory and then resumes the process"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.ModeratelyChallenging,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>On a page fault, the kernel locates or loads the needed page, updates the page tables, and restarts the instruction so the process continues normally.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 707,
    access_level: AccessLevel.Standard,
    title: "Loading Shared Libraries",
    challenge: {
      challenge_id: 1014,
      title: "Loading Shared Libraries",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>How does Linux efficiently handle shared libraries when multiple processes need the same code?</p>
        </div>
      `,
      assessment_options: [
        "Shared libraries are loaded once into memory, and multiple processes share the same code pages",
        "Each process gets a private physical copy of the library",
        "All shared libraries are embedded into binaries at compile time",
        "Libraries are fetched from remote servers for each process startup"
      ],
      acceptable_answers: [
        "Shared libraries are loaded once into memory, and multiple processes share the same code pages"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>Linux uses shared libraries to save memory. The code segments are loaded only once, and all processes map those pages, reducing overall memory usage.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 708,
    access_level: AccessLevel.Standard,
    title: "Determining Mounted Filesystems",
    challenge: {
      challenge_id: 1016,
      title: "Determining Mounted Filesystems",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>How can you find out which filesystems are currently mounted on a Linux system?</p>
        </div>
      `,
      assessment_options: [
        "Use 'mount', 'df -h', or check '/proc/mounts' to see currently mounted filesystems",
        "Reading kernel source code is the only way to know",
        "Relying solely on 'lsblk' shows all mounts accurately",
        "Mount points are guaranteed listed in /etc/fstab even if not mounted"
      ],
      acceptable_answers: [
        "Use 'mount', 'df -h', or check '/proc/mounts' to see currently mounted filesystems"
      ],
      penalty: 0,
      time_limit_in_minutes: 0.5,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The commands 'mount', 'df -h', or inspecting '/proc/mounts' show which filesystems are actively mounted.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
  {
    unit_id: 709,
    access_level: AccessLevel.Standard,
    title: "Mounting and Unmounting Filesystems",
    challenge: {
      challenge_id: 1017,
      title: "Mounting and Unmounting Filesystems",
      challenge_type: ChallengeType.KNOWLEDGE,
      assessment_type: AssessmentType.TruthOrDespair,
      problem_statement: `
        <div>
          <p>You want to access a new USB drive at '/media/usb'. How do you properly mount and unmount it?</p>
        </div>
      `,
      assessment_options: [
        "Copy the device’s raw data into the directory to mount it",
        "Use the 'mount' command to attach the device's filesystem to '/media/usb' and 'umount' to detach it",
        "Filesystems always mount automatically with no action required",
        "To unmount, you must delete the '/media/usb' directory"
      ],
      acceptable_answers: [
        "Use the 'mount' command to attach the device's filesystem to '/media/usb' and 'umount' to detach it"
      ],
      penalty: 0,
      time_limit_in_minutes: 1,
      max_attempts: 1,
      hints: [],
      difficulty: Difficulty.Straightforward,
    },
    explanations: {
      quick_html: `
        <div>
          <h2>Explanation:</h2>
          <p>The mount command associates a block device's filesystem with a directory, and umount safely detaches it to prevent data loss.</p>
        </div>
      `
    },
    tags: [UnitConceptTags.OperatingSystems],
  },
];
const ASSESSMENT_7_TOTAL_TIME_LIMIT_IN_MINUTES = ASSESSMENT_7_OPERATING_SYSTEMS_UNITS.reduce((total, unit) => {
  return total + unit.challenge.time_limit_in_minutes;
}, 0);


const assessmentsData: Assessment[] = [
  {
    "assessment_id": 0,
    "title": "Diagnostic Assessment 0 (Algorithms and Data Structures)",
    "estimated_completion_time_in_minutes": ASSESSMENT_0_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_0_ALGOS_AND_DS_UNITS.length,
    "units": ASSESSMENT_0_ALGOS_AND_DS_UNITS
  },
  {
    "assessment_id": 1,
    "title": "Diagnostic Assessment I (Algorithms and Data Structures)",
    "estimated_completion_time_in_minutes": ASSESSMENT_1_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_1_ALGOS_AND_DS_UNITS.length,
    "units": ASSESSMENT_1_ALGOS_AND_DS_UNITS
  },
  {
    "assessment_id": 2,
    "title": "Diagnostic Assessment II (Algorithms and Data Structures)",
    "estimated_completion_time_in_minutes": ASSESSMENT_2_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_2_ALGOS_AND_DS_UNITS.length,
    "units": ASSESSMENT_2_ALGOS_AND_DS_UNITS
  },
  {
    "assessment_id": 3,
    "title": "Diagnostic Assessment III (System Design)",
    "estimated_completion_time_in_minutes": ASSESSMENT_3_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_3_SYS_DESIGN_UNITS.length,
    "units": ASSESSMENT_3_SYS_DESIGN_UNITS
  },
  {
    "assessment_id": 4,
    "title": "Diagnostic Assessment IV (Networking Concepts)",
    "estimated_completion_time_in_minutes": ASSESSMENT_4_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_4_NETWORKS_UNITS.length,
    "units": ASSESSMENT_4_NETWORKS_UNITS
  },
  {
    "assessment_id": 5,
    "title": "Diagnostic Assessment V (Networking Concepts)",
    "estimated_completion_time_in_minutes": ASSESSMENT_5_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_5_NETWORKS_UNITS.length,
    "units": ASSESSMENT_5_NETWORKS_UNITS
  },
  {
    "assessment_id": 6,
    "title": "Diagnostic Assessment VI (Operating Systems)",
    "estimated_completion_time_in_minutes": ASSESSMENT_6_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_6_OPERATING_SYSTEMS_UNITS.length,
    "units": ASSESSMENT_6_OPERATING_SYSTEMS_UNITS
  },
  {
    "assessment_id": 7,
    "title": "Diagnostic Assessment VII (Operating Systems)",
    "estimated_completion_time_in_minutes": ASSESSMENT_7_TOTAL_TIME_LIMIT_IN_MINUTES,
    "number_of_questions_to_answer": ASSESSMENT_7_OPERATING_SYSTEMS_UNITS.length,
    "units": ASSESSMENT_7_OPERATING_SYSTEMS_UNITS
  },
]
// const AssessmentDataStillBeingTweaked: Assessment[] = [
//   { 
//     "assessment_id": 0,
//     "title": "Diagnostic Assessment I",
//     "estimated_completion_time_in_minutes": 19 * 2.5, // NITO: TODO: set after all questions are added
//     "number_of_questions_to_answer": 19, // NITO: TODO: set after all questions are added
//     "units": [
//       {// NEBUG: either remove or add the algo logic 
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "Complexities and Algorithms",
//         "challenge": {
//           "challenge_id": 0,
//           "title": "Bidirectional Search",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": ["O(∣V∣+∣E∣)", "O(∣V∣^2)", "O(∣E∣)", "O(∣V∣log∣V∣)"],
//           "acceptable_answers": ["O(∣V∣+∣E∣)"],
//           "problem_statement": `
//               <div>
//                   <p>The pseudocode provided describes a bidirectional search algorithm for determining if a path exists between two nodes, <code>start</code> and <code>end</code>, in a graph. The <code>explore</code> function processes nodes from a given queue and updates the associated visited set. The <code>has_intersection</code> function checks if two sets have any shared elements, operating in linear time relative to the size of the smaller set.</p>
//                   <h3>Question:</h3>
//                   <p>Given the provided pseudocode for bidirectional search, determine its runtime complexity.</p>
//               </div>
//           `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.Challenging
//         },
//         "explanations": {
//           "quick_html": `
//               <div>
//                   <p>The primary driver of the complexity is the <code>explore</code> function. Therefore, the overall complexity remains linear with respect to the number of vertices and edges, resulting in O(∣V∣+∣E∣).</p>
//               </div>
//           `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.BreadthFirstSearch],
//       },
//       {// NEBUG: either remove or add the algo logic 
//         "unit_id": 1,
//         "access_level": AccessLevel.Standard,
//         "title": "Complexities and Algorithms",
//         "challenge": {
//           "challenge_id": 1,
//           "title": "Dynamic Programming and Fibonacci",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": ["A) Space: O(1), Time: O(n)", "B) Space: O(n), Time: O(2n)", "C) Space: O(n), Time: O(n)", "D) Space: O(1), Time: O(2n)"],
//           "acceptable_answers": ["C) Space: O(n), Time: O(n)"],
//           "problem_statement": `
//               <div>
//                   <p>The provided pseudocode offers a dynamic programming approach to calculate the nth Fibonacci number. It employs caching to optimize repeated computations. Accessing values in the cache occurs in O(1) time.</p>
//                   <h3>Question:</h3>
//                   <p>For n≥0, determine both the space complexity and runtime complexity of the provided code.</p>
//               </div>
//           `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.ModeratelyChallenging
//         },
//         "explanations": {
//           "quick_html": `
//               <div>
//                   <p>Since the function caches results for each number up to <code>n</code>, the space complexity is O(n). Given that each number is calculated only once due to caching, the time complexity is O(n).</p>
//               </div>
//           `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.AsymptoticRuntimeComplexity, UnitConceptTags.AsymptoticSpaceComplexity],
//       },

//       {
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "Inner Matrix Iteration",
//         "challenge": {
//           "challenge_id": 0,
//           "title": "8x8 Matrix Iteration",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": ["A", "B", "C", "D"],
//           "acceptable_answers": ["A"],
//           "problem_statement": `
//             <div>
//               <h2>Consider a 10x10 matrix:</h2>
//               <p>The task is to only iterate through the inner 8x8 matrix (i.e., cells from indices [1,1] to [8,8]). Which of the following pseudocodes correctly accomplishes this?</p>

//               <div>
//                 <p><strong>A.</strong></p>
//                 <pre>
//                 FOR i = 1 TO 8:
//                     FOR j = 1 TO 8:
//                         PROCESS(matrix[i][j])
//                     END FOR
//                 END FOR
//                 </pre>
//               </div>

//               <div>
//                 <p><strong>B.</strong></p>
//                 <pre>
//                 FOR i = 1 TO 9:
//                     FOR j = 1 TO 9:
//                         PROCESS(matrix[i][j])
//                     END FOR
//                 END FOR
//                 </pre>
//               </div>

//               <div>
//                 <p><strong>C.</strong></p>
//                 <pre>
//                 FOR i = 0 TO 7:
//                     FOR j = 0 TO 7:
//                         PROCESS(matrix[i][j])
//                     END FOR
//                 END FOR
//                 </pre>
//               </div>

//               <div>
//                 <p><strong>D.</strong></p>
//                 <pre>
//                 FOR i = 2 TO 9:
//                     FOR j = 2 TO 9:
//                         PROCESS(matrix[i][j])
//                     END FOR
//                 END FOR
//                 </pre>
//               </div>

//             </div>
//           `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.Straightforward
//         },
//         "explanations": {
//           "quick_html": `
//             <div>
//               <h2>Explanation:</h2>
//               <p>The correct pseudocode that only iterates through the inner 8x8 matrix (from indices [1,1] to [8,8]) is:</p>
//               <pre>
//               FOR i = 1 TO 8:
//                   FOR j = 1 TO 8:
//                       PROCESS(matrix[i][j])
//                   END FOR
//               END FOR
//               </pre>
//               <p>Thus, option <strong>A</strong> is the correct answer.</p>
//             </div>
//           `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.Matrix],
//       },
//       {
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "Matrix Iteration Optimization",
//         "challenge": {
//           "challenge_id": 0,
//           "title": "Optimal Iteration Method for 2D Matrix",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": [
//             "Iterating through each row, and then each column within that row.",
//             "Iterating through each column, and then each row within that column.",
//             "Neither, both methods offer the same performance."
//           ],
//           "acceptable_answers": ["Iterating through each row, and then each column within that row."],
//           "problem_statement": `
//             <div>
//               <h2>Problem Description:</h2>
//               <p>Given a 2D matrix is represented as an array of arrays, where the inner arrays are contiguous in memory. This memory layout may result in better performance when iterating through the matrix due to data locality. Considering the memory layout and the concept of CPU cache, which iteration method is optimal?</p>
//               <pre>
//       matrix = [ 
//         [a11, a12, a13], 
//         [a21, a22, a23], 
//         [a31, a32, a33] 
//       ]
//               </pre>
//             </div>
//           `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.ModeratelyChallenging
//         },
//         "explanations": {
//           "quick_html": `
//             <div>
//               <h2>Explanation:</h2>
//               <p>Considering the memory layout of a 2D matrix where inner arrays are contiguous in memory, iterating through each row and then each column within that row is optimal. This is because accessing data that's contiguous in memory results in fewer cache misses, leading to better performance.</p>
//             </div>
//           `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.Matrix],
//       },
//       {
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "Hash Table Basics",
//         "challenge": {
//           "challenge_id": 0,
//           "title": "Hash Table Test Cases",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": [
//             "[1,2,3,4,5]",
//             "[1,1,2,2,3,3]",
//             "[5,5,5,5,5]",
//             "[]",
//             "It handles all cases correctly."
//           ],
//           "acceptable_answers": ["It handles all cases correctly."],
//           "problem_statement": `
//                 <div>
//                     <h2>Given the pseudocode:</h2>
//                     <pre>
//     FUNCTION store_values(list: ARRAY):
//         hash_table = {}
//         FOR item in list:
//             IF item NOT IN hash_table:
//                 hash_table[item] = 1
//             ELSE:
//                 hash_table[item] = hash_table[item] + 1
//             END IF
//         END FOR
//         RETURN hash_table
//     END FUNCTION
//                     </pre>
//                     <h3>Question:</h3>
//                     <p>Which test case would the above function not handle correctly?</p>
//                 </div>
//             `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.Straightforward
//         },
//         "explanations": {
//           "quick_html": `
//                 <div>
//                     <h2>Explanation:</h2>
//                     <p>The function handles all the provided test cases correctly as it just counts occurrences of each item in the list.</p>
//                 </div>
//             `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.HashTable],
//       },
//       {
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "Binary Search Advanced",
//         "challenge": {
//           "challenge_id": 0,
//           "title": "Pseudocode Output Prediction",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": [
//             "An infinite loop.",
//             "Index of the largest element.",
//             "-1",
//             "0"
//           ],
//           "acceptable_answers": ["An infinite loop."],
//           "problem_statement": `
//               <div>
//                   <h2>Given the pseudocode:</h2>
//                   <pre>
//   FUNCTION BinarySearch(arr: ARRAY, x: INTEGER):
//       low = 0
//       high = length(arr) - 1
//       WHILE low <= high:
//           mid = (low + high) / 2
//           IF arr[mid] == x:
//               RETURN mid
//           ELSE IF arr[mid] < x:
//               low = mid + 1
//           ELSE:
//               high = mid
//       END WHILE
//       RETURN -1
//   END FUNCTION
//                   </pre>
//                   <h3>Question:</h3>
//                   <p>What would be the output of the above function if x is greater than the largest element in arr?</p>
//               </div>
//           `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.ModeratelyChallenging
//         },
//         "explanations": {
//           "quick_html": `
//               <div>
//                   <h2>Explanation:</h2>
//                   <p>If x is greater than the largest element in arr, the code will get into an infinite loop because the high pointer will never decrease below low.</p>
//               </div>
//           `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.BinarySearch],
//       },
//       {
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "Binary Search Basics",
//         "challenge": {
//           "challenge_id": 0,
//           "title": "Stock Search Optimization",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": [
//             "O(n) using Linear Search",
//             "O(log n) using Binary Search",
//             "O(n log n) using Binary Search",
//             "O(1) using Direct Access",
//             "O(n^2) using Bubble Search",
//             "O(log n) using Linear Search"
//           ],
//           "acceptable_answers": ["O(log n) using Binary Search"],
//           "problem_statement": `
//             <div>
//                 <p>You have a list of stocks. Each stock has a name, ticker symbol, and a last price (integer value). The stocks are sorted in ascending order based on their price.</p>
//                 <h3>Question:</h3>
//                 <p>What's the best runtime complexity to determine if a stock with a specific name and price exists in the list?</p>
//             </div>
//         `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.Straightforward
//         },
//         "explanations": {
//           "quick_html": `
//             <div>
//                 <h2>Explanation:</h2>
//                 <p>The best runtime complexity to find a specific item in a sorted list is O(log n) using Binary Search.</p>
//             </div>
//         `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.BinarySearch],
//       },
//       {
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "",
//         "challenge": {
//           "challenge_id": 0,
//           "title": "Matrix Search Issue",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": [
//             "The conditions inside the WHILE loop.",
//             "The row initialization.",
//             "The col initialization.",
//             "The ELSE clause after matrix[row][col] > target."
//           ],
//           "acceptable_answers": ["The ELSE clause after matrix[row][col] > target."],
//           "problem_statement": `
//         <div>
//           <h2>Given the pseudocode:</h2>
//           <pre>
//             FUNCTION search_matrix(matrix: 2D ARRAY, target: INTEGER):
//               row = 0
//               col = number of columns in matrix - 1
//               WHILE row < number of rows in matrix AND col >= 0:
//                   IF matrix[row][col] == target:
//                       RETURN True
//                   ELSE IF matrix[row][col] > target:
//                       col = col - 1
//                   ELSE:
//                       row = row
//               END WHILE
//               RETURN False
//             END FUNCTION
//           </pre>
//           <h3>Question:</h3>
//           <p>Identify the issue in the code that would prevent it from finding a target correctly.</p>
//         </div>
//       `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.ModeratelyChallenging
//         },
//         "explanations": {
//           "quick_html": `
//         <div>
//           <h2>Explanation:</h2>
//           <p>The problem lies in the ELSE clause after matrix[row][col] > target. The row should increment to correctly search the matrix.</p>
//         </div>
//       `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.Matrix],
//       },

//       {
//         "unit_id": 0,
//         "access_level": AccessLevel.Standard,
//         "title": "Sorting",
//         "challenge": {
//           "challenge_id": 2,
//           "title": "Identify the Sorting Algorithm",
//           "challenge_type": ChallengeType.KNOWLEDGE,
//           "assessment_type": AssessmentType.TruthOrDespair,
//           "assessment_options": [
//             "Bubble Sort",
//             "Insertion Sort",
//             "Selection Sort",
//             "Merge Sort",
//             "Radix Sort",
//             "Heap Sort",
//             "QuickSort"
//           ],
//           "acceptable_answers": ["QuickSort"],

//           "problem_statement": `
//         <div>
//           <h2>Given the pseudocode:</h2>
//           <pre>
//           FUNCTION Sort(arr: ARRAY):
//               IF length(arr) <= 1:
//                   RETURN arr
//               END IF

//               pivot = arr[0]
//               left = [element FOR element in arr IF element < pivot]
//               right = [element FOR element in arr IF element > pivot]

//               RETURN Sort(left) + [pivot] + Sort(right)
//           END FUNCTION
//           </pre>
//           <h3>Question:</h3>
//           <p>Which sorting algorithm is implemented above?</p>
//         </div>
//       `,
//           "penalty": 0,
//           "time_limit_in_minutes": 2.5,
//           "max_attempts": 1,
//           "hints": [],
//           "difficulty": Difficulty.Straightforward,
//         },
//         "explanations": {
//           "quick_html": `
//         <div>
//           <h2>Explanation:</h2>
//           <p>The given pseudocode implements the <strong>QuickSort</strong> sorting algorithm.</p>
//         </div>
//       `,
//           "explain_like_im_five_years_old_html": null,
//           "thought_process_html": null,
//           "example_based_html": null
//         },
//         "tags": [UnitConceptTags.Sorting],
//       },
// ]

interface IAssessmentContext {
  assessments: Assessment[] | null;
  error: string | null;
}

export const AssessmentContext = createContext<IAssessmentContext>({ assessments: null, error: null });

interface AssessmentProviderProps {
  children: ReactNode;
}

export const AssessmentProvider: React.FC<AssessmentProviderProps> = ({ children }) => {
  const [assessments, setAssessments] = useState<Assessment[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAssessments(assessmentsData) // Remove this line

        // *Uncomment out these lines*
        // const coursesData = await getAssessments();
        // if (coursesData) {
        //   setAssessments(coursesData);
        // } else {
        //   setError('Invalid courses data');
        // }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchData();
  }, []);

  const handleFetchError = (error: any) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          setError("Bad request for assessments");
          break;
        case 404:
          setError("Assessments not found");
          break;
        case 500:
          setError("An unexpected error occurred");
          break;
        default:
          setError(error.response.data.message || "Unknown error");
      }
    } else if (error.request) {
      setError("No response from server.");
    } else {
      setError(error.message);
    }
    console.error(error);
  };

  return (
    <AssessmentContext.Provider value={{ assessments, error }}>
      {children}
    </AssessmentContext.Provider>
  );
};