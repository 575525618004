import React, { useContext, useEffect, useMemo, useState} from 'react';
import { UserSubscription, UserSubscriptionTier } from '@/externalLayerAccessor/models/firebaseModels';
import StateAccessor from '@/StateAccessor';
import { isUserSignedIn } from '@/utils/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import { getSubscriptionByUserEmail, userHasPremiumSubscriptionPrivilege } from '@/utils/PermissionUtils';
import { logToBackendLogFile } from '@/externalLayerAccessor/BackEndRequests';
import DOMPurify from 'dompurify';

import '../../css/Subscriptions.css';
import { Alert, Button } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import PaymentIcon from '@mui/icons-material/Payment';
import AssistantIcon from '@mui/icons-material/Assistant';
import GroupIcon from '@mui/icons-material/Group';

import { UserResourceUsage, getResourceUsageByUserId } from '@/externalLayerAccessor/userResourcesAccessor';
import {
  CheckoutDetail,
  CheckoutMode,
  createSubscriptionStripeCheckoutSession,
  PaymentMethodType,
  SubscriptionMetaData
} from '@/externalLayerAccessor/paymentsServiceAccessor';
import Modal from '@/utils/Modal';

/////////////////////////////////////////////
//            Payment Success View         //
/////////////////////////////////////////////

interface PaymentSuccessPageProps {}

const PaymentSuccessPage: React.FC<PaymentSuccessPageProps> = () => {
  return (
    <div className='payment-success-container'>
      <h1>Payment Successful</h1>
      <p>Your payment was processed successfully. Thank you!</p>
      <p>You will receive an email confirmation shortly.</p>
    </div>
  );
};

/////////////////////////////////////////////
//         Payment Cancelled View          //
/////////////////////////////////////////////

const PaymentCancelledPage: React.FC = () => (
  <div className='payment-cancelled-container'>
    <h1>Payment Cancelled</h1>
    <p>Your payment was cancelled. Please try again or contact support if you need assistance.</p>
  </div>
);

/////////////////////////////////////////////
//           Subscription View             //
/////////////////////////////////////////////

enum Region {
  US = 'us',
  // Future regions like EU, UK, CA, etc., can be added here
}

enum SubscriptionDuration {
  ONE_MONTH_RECURRING = '1 month (recurring)',
  ONE_MONTH = '1 month',
  TEST_ONE_MONTH = 'test (1 month)',
  THREE_MONTHS = '3 months',
  SIX_MONTHS = '6 months',
  ONE_YEAR = '1 year',
  TEST_ONE_MONTH_RECURRING = 'test (1 month recurring)'
}

export interface SubscriptionOption {
  id: SubscriptionDuration;
  label: string;
  durationMonths: number;
  isRecurring: boolean;
  priceId: string;
  pricesByRegion: Record<Region, string>;
  isTest: boolean;
  disabledForNewSubscription: boolean;
  disabledForExistingSubscription: boolean;
}

export const subscriptionOptions: SubscriptionOption[] = [
  {
    id: SubscriptionDuration.TEST_ONE_MONTH_RECURRING,
    label: 'Test 1 Month (recurring)',
    durationMonths: 1,
    isRecurring: true,
    priceId: 'price_1QEYUYJcbbTCWMabZq0wmC91', // This is a test price ID
    pricesByRegion: {
      [Region.US]: "$1.00",
    },
    isTest: true,
    disabledForNewSubscription: false,
    disabledForExistingSubscription: true,
  },
  {
    id: SubscriptionDuration.ONE_MONTH_RECURRING,
    label: '1 Month (recurring)',
    durationMonths: 1,
    isRecurring: true,
    priceId: 'price_1QBREFJcbbTCWMab29vQk85y', // Replace with actual recurring price ID
    pricesByRegion: {
      [Region.US]: "$29.99",
    },
    isTest: false,
    disabledForNewSubscription: false,
    disabledForExistingSubscription: true,
  },
  {
    id: SubscriptionDuration.ONE_MONTH,
    label: '1 Month (one-off)',
    durationMonths: 1,
    isRecurring: false,
    priceId: 'price_1PhZrCJcbbTCWMabx4Ssc56V', // Replace with actual one-off price ID
    pricesByRegion: {
      [Region.US]: "$29.99",
    },
    isTest: false,
    disabledForNewSubscription: true,
    disabledForExistingSubscription: false,
  },
  {
    id: SubscriptionDuration.TEST_ONE_MONTH,
    label: 'Test 1 Month (one-off)',
    durationMonths: 1,
    isRecurring: false,
    priceId: 'price_1Pih3IJcbbTCWMabu7p0z2jw', // This is a test price ID
    pricesByRegion: {
      [Region.US]: "$1.00",
    },
    isTest: true,
    disabledForNewSubscription: true,
    disabledForExistingSubscription: false,
  },
  {
    id: SubscriptionDuration.THREE_MONTHS,
    label: '3 Months (one-off) (save 22%)',
    durationMonths: 3,
    isRecurring: false,
    priceId: 'price_1Pha2HJcbbTCWMabKhOcRqYM', // Replace with actual one-off price ID
    pricesByRegion: {
      [Region.US]: "$69.99",
    },
    isTest: false,
    disabledForNewSubscription: false,
    disabledForExistingSubscription: false,
  },
  {
    id: SubscriptionDuration.SIX_MONTHS,
    label: '6 Months (one-off) (save 33%)',
    durationMonths: 6,
    isRecurring: false,
    priceId: 'price_1PhaR0JcbbTCWMabBr5veixT', // Replace with actual one-off price ID
    pricesByRegion: {
      [Region.US]: "$119.99",
    },
    isTest: false,
    disabledForNewSubscription: false,
    disabledForExistingSubscription: false,
  },
  {
    id: SubscriptionDuration.ONE_YEAR,
    label: '1 Year (one-off) (save 50%)',
    durationMonths: 12,
    isRecurring: false,
    priceId: 'price_1PhZxFJcbbTCWMabw8BklTaf', // Replace with actual one-off price ID
    pricesByRegion: {
      [Region.US]: "$179.99",
    },
    isTest: false,
    disabledForNewSubscription: false,
    disabledForExistingSubscription: false,
  },
];

///////////////////////////////////////////////////////////
interface ResourceAvailabilityViewProps {
  loading: boolean;
  resourceUsage: UserResourceUsage | null;
}

const ResourceAvailabilityView: React.FC<ResourceAvailabilityViewProps> = ({
  loading,
  resourceUsage
}) => {
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'GMT', // Specify the timezone explicitly
      timeZoneName: 'short',
    });
  };

  return (
    <div className='resource-availability-view'>
      {loading && <p>Loading resource usage...</p>}
      {resourceUsage && (
        <div>
          <h2>Available Resources</h2>
          <p>
            <strong><CodeIcon /> Code Execution:</strong>{" "}
            {resourceUsage.codeExecution
              ? `${resourceUsage.codeExecution.remainingUnits} units remaining (last used on ${formatDate(
                  resourceUsage.codeExecution.lastUsedAt
                )})`
              : "No data"}
          </p>
          <p>
            <strong><AssistantIcon /> AI Assistant:</strong>{" "}
            {resourceUsage.ai_assistant
              ? `${resourceUsage.ai_assistant.remainingUnits} units remaining (last used on ${formatDate(
                  resourceUsage.ai_assistant.lastUsedAt
                )})`
              : "No data"}
          </p>
        </div>
      )}
    </div>
  );
};

interface SubscriptionDetailsViewProps {
  userEmail: string;
  subscription: UserSubscription | null;
}

const SubscriptionDetailsView: React.FC<SubscriptionDetailsViewProps> = ({
  userEmail,
  subscription
}) => {
  return (
    <div className='subscription-details-view'>
      <h2>Basic Details</h2>
      <p><strong>Email address:</strong> {userEmail}</p>
      <p>
        <strong>Current subscription tier:</strong>{" "}
        {subscription?.subscriptionTier || UserSubscriptionTier.FREE}
      </p>
      {subscription && (
        <p>
          <strong>Subscription expiry date: </strong>
          {subscription.subscriptionExpiry
            ? new Date(subscription.subscriptionExpiry).toLocaleString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZone: 'UTC',
                timeZoneName: 'short',
              })
            : "None"}
        </p>
      )}
    </div>
  );
};

const getUTCDateNow = (): Date => {
  return new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds(),
      new Date().getUTCMilliseconds()
    )
  );
};

const calculateNewExpiryUTCDate = (
  currentExpiryDate: Date | null,
  subscriptionOption: SubscriptionOption
): Date => {
  const nowInUTC: Date = getUTCDateNow();

  // if expiry date is in the future, then any extension should be atop the current expiry date, otherwise, start from now
  const startUTCDateToExtendFrom: Date =
    currentExpiryDate && currentExpiryDate > nowInUTC
      ? currentExpiryDate
      : nowInUTC;

  const newExpiryDate: Date = new Date(
    startUTCDateToExtendFrom.setMonth(
      startUTCDateToExtendFrom.getMonth() + subscriptionOption.durationMonths
    )
  );

  return newExpiryDate;
};

enum PaymentStatus {
  SUCCESS = 'success',
  CANCELLED = 'cancelled'
}

interface ViewControlSettings {
  showAvailableResourcesView: boolean;
  showSubscriptionDetailsView: boolean;
  showPremiumSubscriptionView: boolean;
}

interface SubscriptionsProps {
  viewControlSettings?: Partial<ViewControlSettings>;
}

const defaultViewControlSettings: ViewControlSettings = {
  showAvailableResourcesView: true,
  showSubscriptionDetailsView: true,
  showPremiumSubscriptionView: true,
};

const Subscriptions: React.FC<SubscriptionsProps> = ({ viewControlSettings = {} }) => {
  const { userId, userName, setUserName, userEmail } = useContext(StateAccessor);
  const [subscription, setSubscription] = useState<UserSubscription | null>(null);

  // 1) Make selectedSubscription nullable:
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionOption | null>(null);

  const [resourceUsage, setResourceUsage] = useState<UserResourceUsage | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [clientFacingErrMsg, setClientFacingErrMsg] = useState<string | null>(null);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState<boolean>(false);
  const [showPaymentCancelledModal, setShowPaymentCancelledModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const mergedViewControlSettings = useMemo(
    () => ({ ...defaultViewControlSettings, ...viewControlSettings }),
    [viewControlSettings]
  );

  useEffect(() => {
    if (isUserSignedIn(userId)) {
      try {
        syncSubscriptionDetails();
        SyncAvailableUserResourcesDetails();
      } catch (e) {
        setClientFacingErrMsg("An internal error occurred while fetching your subscription details please retry or contact support");
      } finally {
        setLoading(false);
      }
    }
  }, [userId, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paymentStatus = urlParams.get('paymentStatus');

    if (paymentStatus === PaymentStatus.SUCCESS) {
      setShowPaymentSuccessModal(true);
    } else if (paymentStatus === PaymentStatus.CANCELLED) {
      setShowPaymentCancelledModal(true);
    }

    // edit the URL to remove the payment status query param
    urlParams.delete('paymentStatus');
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
  }, [location.search]);

  const syncSubscriptionDetails = async () => {
    try {
      const userSubscription = await getSubscriptionByUserEmail(userEmail);
      setSubscription(userSubscription);
    } catch (e) {
      console.error("Error fetching subscription details:", e);
    }
  };

  // 2) Add null-check inside processSubscription:
  const processSubscription = async (currentExpiryDate: Date | null) => {
    try {
      if (!selectedSubscription) {
        logToBackendLogFile('No subscription selected. Aborting.', 'warn');
        setClientFacingErrMsg('No subscription was selected. Please pick one before proceeding.');
        return;
      }

      const newExpiryUTCDate = calculateNewExpiryUTCDate(currentExpiryDate, selectedSubscription);

      const subscriptionDetails: UserSubscription = {
        displayName: userName,
        userEmail: userEmail,
        subscriptionTier: UserSubscriptionTier.PREMIUM,
        subscriptionExpiry: newExpiryUTCDate,
      };

      const checkoutDetail: CheckoutDetail = {
        payment_method_types: [PaymentMethodType.CARD],
        line_items: [
          {
            price_id: selectedSubscription.priceId,
            quantity: 1
          }
        ],
        mode: selectedSubscription.isRecurring ? CheckoutMode.SUBSCRIPTION : CheckoutMode.PAYMENT
      };

      const subscriptionMetaData: SubscriptionMetaData = {
        durationInMonths: selectedSubscription.durationMonths
      };

      await createSubscriptionStripeCheckoutSession(userId, checkoutDetail, subscriptionDetails, subscriptionMetaData);
    } catch (e) {
      logToBackendLogFile(
        `An error occured while processing subscription for user: ${userEmail}. Error details: ${e}`,
        "error"
      );
      setClientFacingErrMsg(
        "An internal error occurred while processing your subscription please retry or contact support"
      );
    }
  };

  const handleCreatePremiumSubscription = async () => {
    logToBackendLogFile(
      `User tiggered premium subscription creation`,
      "info",
      userId
    );
    await processSubscription(null);
  };

  const handleExtendPremiumSubscription = async () => {
    const currentExpiryDate = subscription?.subscriptionExpiry || null;
    await processSubscription(currentExpiryDate);
  };

  const userHasPremiumAccess = (): boolean => {
    return userHasPremiumSubscriptionPrivilege(subscription?.subscriptionTier || UserSubscriptionTier.FREE);
  };

  const SyncAvailableUserResourcesDetails = async () => {
    try {
      const usage = await getResourceUsageByUserId(userId);
      setResourceUsage(usage);
      setClientFacingErrMsg(null);
    } catch (e) {
      setClientFacingErrMsg("An internal error occurred while fetching your details please retry or contact support");
    }
  };

  const handleUserNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedInput = DOMPurify.sanitize(e.target.value);
    setUserName(sanitizedInput);
  };

  return (
    <div className='root-container'>
      {clientFacingErrMsg && (
        <div className="notification-container">
          <Alert severity="warning">{clientFacingErrMsg}</Alert>
        </div>
      )}

      <div className='subscription-status-modal-view-container'>
        {showPaymentSuccessModal && (
          <Modal
            content={<PaymentSuccessPage />}
            onClose={() => {
              setShowPaymentSuccessModal(false);
            }}
          />
        )}

        {showPaymentCancelledModal && (
          <Modal
            content={<PaymentCancelledPage />}
            onClose={() => {
              setShowPaymentCancelledModal(false);
            }}
          />
        )}
      </div>

      {isUserSignedIn(userId) && (
        <div className="subscription-container">
          {mergedViewControlSettings.showSubscriptionDetailsView && (
            <SubscriptionDetailsView userEmail={userEmail} subscription={subscription} />
          )}
          {mergedViewControlSettings.showAvailableResourcesView && (
            <ResourceAvailabilityView loading={loading} resourceUsage={resourceUsage} />
          )}
          {mergedViewControlSettings.showPremiumSubscriptionView && (
            <PremiumSubscriptionView
              userName={userName}
              userHasPremiumAccess={userHasPremiumAccess}
              handleUserNameInput={handleUserNameInput}
              selectedSubscription={selectedSubscription} // *** could be null
              setSelectedSubscription={setSelectedSubscription} // *** expects SubscriptionOption | null
              handleCreatePremiumSubscription={handleCreatePremiumSubscription}
              handleExtendPremiumSubscription={handleExtendPremiumSubscription}
            />
          )}
        </div>
      )}

      {!isUserSignedIn(userId) && (
        <div>
          <Alert severity="warning">
            You need to be signed in to access this page. Please sign-up or sign-in to continue.
          </Alert>
        </div>
      )}
    </div>
  );
};

/////////////////////////////////////////////
//       Premium Subscription View         //
/////////////////////////////////////////////

interface PremiumSubscriptionViewProps {
  userName: string;
  userHasPremiumAccess: () => boolean;
  handleUserNameInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // updated to allow null
  selectedSubscription: SubscriptionOption | null;
  setSelectedSubscription: (subscriptionOption: SubscriptionOption | null) => void;
  handleCreatePremiumSubscription: () => void;
  handleExtendPremiumSubscription: () => void;
}

const PremiumSubscriptionView: React.FC<PremiumSubscriptionViewProps> = ({
  userName,
  handleUserNameInput,
  selectedSubscription,
  setSelectedSubscription,
  handleCreatePremiumSubscription,
  handleExtendPremiumSubscription,
  userHasPremiumAccess
}) => {
  const [showSubscriptionOptionsModal, setShowSubscriptionOptionsModal] = useState<boolean>(false);

  return (
    <div className="premium-subscription-view">
      <div>
        {!userHasPremiumAccess() ? (
          <>
            <div className='title'><h2>Unlock Full Access with Coditioning Premium</h2></div>
            <div className='subtitle members-social-proof'>
              <h3><GroupIcon style={{ marginRight: '0.5rem' }} />Join 5000+ members</h3>
            </div>
          </>
        ) : null}

        <div className='subscription-options-container'>
          <div className='subscription-option-features'>
            <h2>Coditioning Basic</h2>
            <div className='features-list'>
              <p className="feature">Basic diagnostic tests</p>
              <p className="feature">Custom learning path</p>
              <p className="feature">Non-premium content</p>
            </div>
          </div>

          <div className='subscription-option-features'>
            <h2>👑 Coditioning Premium</h2>
            <div className='features-list'>
              <p className="feature">Everything in basic plan</p>
              <p className="feature">AI Mock Interviews</p>
              <p className="feature">AI Learning Assistant</p>
              <p className="feature">System Design Course</p>
              <p className="feature">Low Level Design Course</p>
              <p className="feature">Algorithms & Data Structures</p>
              <Button
                className='payment-button'
                variant="contained"
                color="secondary"
                onClick={() => setShowSubscriptionOptionsModal(true)}
                style={{
                  backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                  color: 'black',
                  textTransform: 'none',
                  display: 'block',
                  margin: '0.5rem auto auto auto', // top right bottom left
                }}
              >
                <PaymentIcon style={{ marginRight: '0.5rem' }} />
                {!userHasPremiumAccess() ? "Subscribe to Premium": "Extend Premium Subscription"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showSubscriptionOptionsModal && (
        <Modal
          content={
            <div className="subscription-form-view">
              <div>
                <label>
                  Display Name:
                  <input
                    type="text"
                    value={userName}
                    onChange={handleUserNameInput}
                    title='Enter your preferred display name here.'
                  />
                </label>
              </div>

              <div>
                <label>
                  {!userHasPremiumAccess() ? "Subscription Duration" : "How long to extend for?"}
                  <select
                    // if null, default to empty string
                    value={selectedSubscription?.id || ''}
                    onChange={(e) => {
                      const selectedId = e.target.value as SubscriptionDuration;
                      const found = subscriptionOptions.find(
                        (option) => option.id === selectedId
                      );
                      setSelectedSubscription(found ?? null);
                    }}
                  >
                    <option value="" disabled>
                      -- Select an option --
                    </option>
                    {subscriptionOptions
                      // Include test subscriptions only in development env
                      .filter((option) =>
                        !option.isTest || process.env.REACT_APP_NODE_ENV === 'development'
                      )
                      // Filter for new vs. existing subscription logic
                      .filter((option) =>
                        userHasPremiumAccess()
                          ? !option.disabledForExistingSubscription
                          : !option.disabledForNewSubscription
                      )
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}: {option.pricesByRegion[Region.US]}
                        </option>
                      ))
                    }
                  </select>
                </label>
              </div>

              <p><i>one-off subscriptions do not auto-renew</i></p>

              <button
                onClick={() => {
                  // button-level safety check
                  if (!selectedSubscription) {
                    alert('Please select a subscription first!');
                    return;
                  }
                  userHasPremiumAccess()
                    ? handleExtendPremiumSubscription()
                    : handleCreatePremiumSubscription();
                }}
                disabled={false} // You can add logic here to disable the button if needed
              >
                {userHasPremiumAccess()
                  ? 'Extend Premium Subscription'
                  : 'Subscribe to Premium'}
              </button>
            </div>
          }
          onClose={() => {
            setShowSubscriptionOptionsModal(false);
          }}
          padding='0px'
        />
      )}
    </div>
  );
};

export default Subscriptions;
