import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/JobsPage.css";
import { CV_SERVICES_PAGE_PATH } from "@/utils/Constants";

const jobCategories = [
  {
    title: "Coditioning Opportunities",
    links: [
      { name: "Coditioning", url: "https://www.coditioning.com/careers" },
    ],
  },
  {
    title: "Recruiter Job Boards",
    links: [{ name: "The Put Group", url: "https://jobs.theputgroup.com/" }],
  },
  {
    title: "Other Job Boards",
    links: [
      { name: "Levels.fyi", url: "https://www.levels.fyi/jobs" },
      { name: "Blind", url: "https://www.teamblind.com/jobs" },
      { name: "Stack Overflow Jobs", url: "https://stackoverflow.com/jobs" },
      { name: "AngelList", url: "https://angel.co/jobs" },
    ],
  },
  {
    title: "Freelance Platforms",
    links: [
      { name: "Upwork", url: "https://www.upwork.com/" },
      { name: "Fiverr", url: "https://www.fiverr.com/" },
    ],
  },
  {
    title: "Big Tech Jobs",
    links: [
      { name: "Meta", url: "https://www.metacareers.com/" },
      { name: "Apple", url: "https://www.apple.com/careers/us/" },
      { name: "Amazon", url: "https://www.amazon.jobs/" },
      { name: "Google", url: "https://careers.google.com/" },
      { name: "Netflix", url: "https://jobs.netflix.com/" },
      { name: "Nvidia", url: "https://www.nvidia.com/en-us/about-nvidia/careers/" },
      { name: "Databricks", url: "https://www.databricks.com/company/careers" },
      { name: "OpenAI", url: "https://openai.com/careers" },
      { name: "Anthropic", url: "https://www.anthropic.com/careers" },
    ],
  },
];

const JobsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleCVReviewClick = () => {
    navigate(CV_SERVICES_PAGE_PATH); // Route to CV Review page
  };

  return (
    <div className="jobs-page-container">
      <div className="jobs-page-content">
        <h1 className="jobs-title">Job Boards</h1>

        {jobCategories.map((category, index) => (
          <section key={index} className="jobs-section">
            <h2 className="section-title">{category.title}</h2>
            {category.links.map((link, linkIndex) => (
              <a
                key={linkIndex}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="job-link"
              >
                {link.name}
              </a>
            ))}
          </section>
        ))}
      </div>

      {/* Sidebar Section */}
      <aside className="jobs-sidebar">
        <div className="cv-review">
          <h2>CV Review</h2>
          <p>Improve your CV to stand out in job applications.</p>
          <button className="cv-button" onClick={handleCVReviewClick}>
            Get Your CV Reviewed
          </button>
        </div>
      </aside>
    </div>
  );
};

export default JobsPage;
