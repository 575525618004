
export class TelephoneOrOnsiteInterviewAPIEndpoints {
  static GIVE_FEEDBACK = "/interview/telephoneOrOnsite/inteviewer/givePostInterviewFeedback";
  // other API endpoints...
}

export class TakeHomeAssessmentInterviewAPIEndpoints {
  static GIVE_FEEDBACK = "/interview/takeHomeAssessment/inteviewer/givePostInterviewFeedback";
  // other API endpoints...
}

export class UserExperienceAPIEndpoints {
  static STORE_USER_EXPERIENCE_FEEDBACK = "/feedback/userExperience";
}

export class CodeExecutionAPIEndpoints {
  static CREATE_TEST_CASE_RESULTS = "/code-execution/test-case-results";
}