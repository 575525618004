import React from "react";
import { InterviewDurations } from "../utils/Constants";

/*Enums */
//TODO: HIGH PRIORITY move all these enums to a config file, that both the front-end and back-end will use ( can maybe use meta-classes toc create the enum classes from config in python back-end)
// define the enum values for interview difficulty. OTHERWISE bugs will occur!!
export enum InterviewDifficulty {
  Straightforward = "straightforward",
  ModeratelyChallenging = "moderately challenging",
  Challenging = "challenging" //NEBUG: HIGH PRIORITY: RENABLE once there's a challenging question in the database
}

export function getInterviewDifficultyAsInteger(difficulty: InterviewDifficulty): number {
  switch (difficulty) {
    case InterviewDifficulty.Straightforward:
      return 1;
    case InterviewDifficulty.ModeratelyChallenging:
      return 2;
    // case InterviewDifficulty.Challenging:
    //   return 3;
    default:
      throw new Error('Invalid InterviewDifficulty value');
  }
}

// define the enum values for interviewer personality
export enum InterviewerPersonality {
  Lenient = "lenient",
  Fair = "fair",
  Harsh = "harsh",
  Demanding = "demanding"
}

// define the enum values for interview type
export enum InterviewStage {
  Telephone = "telephone",
  TakeHomeAssessment = "take-home assessment",
  // OnSite = "on-site"
  Onsite = "on-site" //nebug: back-end ony recognises onsite or telephone, so lumping both together for now
}

// define the enum values for company type
export enum CompanyType {
  GenericTechCompany = "GENERIC TECH COMPANY",
  GenericStartup = "STARTUP",
  Meta = "META",
  Bloomberg = "BLOOMBERG",
  Google = "GOOGLE",
  Amazon = "AMAZON",
  Apple = "APPLE",
  Netflix = "NETFLIX",
  Citadel = "CITADEL",
  HudsonRiverTrading = "HUDSON RIVER TRADING",
  JaneStreet = "JANE STREET",
  JPMorgan = "JP MORGAN",
  MorganStanley = "MORGAN STANLEY",
  Uber = "UBER",
  Stripe = "STRIPE",
  Adobe = "ADOBE",
  LinkedIn = "LINKEDIN",
  Microsoft = "MICROSOFT",
  OpenAI = "OPENAI",
}

// define the enum values for candidate experience level
export enum CandidateExperienceLevel {
  Junior = "JUNIOR",
  Senior = "SENIOR"
}

// define the enum values for target role type
export enum TargetRoleType {
  SoftwareEngineer = "SOFTWARE ENGINEER",
  BackEndSoftwareEngineer = "BACK-END SOFTWARE ENGINEER",
  FrontEndSoftwareEngineer = "FRONT-END SOFTWARE ENGINEER",
  FullStackSoftwareEngineer = "FULL-STACK SOFTWARE ENGINEER",
  DevOpsEngineer = "DEVOPS ENGINEER",
  MachineLearningEngineer = "MACHINE LEARNING ENGINEER",
  DataEngineer = "DATA ENGINEER",
  DataScientist = "DATA SCIENTIST",
  QuantitativeResearcher = "QUANTITATIVE RESEARCHER",
  QuantitativeDeveloper = "QUANTITATIVE DEVELOPER",
  BlockchainEngineer = "BLOCKCHAIN ENGINEER"
}

/*
when a setting is disabled (i.e. not enabled), the default value is used instead and the user cannot change it
*/
export interface SettingsAccessibility {
  interviewDifficulty?: { enabled: boolean; defaultValue: InterviewDifficulty };
  interviewerPersonality?: { enabled: boolean; defaultValue: InterviewerPersonality };
  interviewStage?: { enabled: boolean; defaultValue: InterviewStage };
  companyType?: { enabled: boolean; defaultValue: CompanyType };
  candidateExperienceLevel?: { enabled: boolean; defaultValue: CandidateExperienceLevel };
  candidateRoleType?: { enabled: boolean; defaultValue: TargetRoleType };
  timeLimitInMinutes?: { enabled: boolean; defaultValue: number };
  timeLeftInMinutes?: { enabled: boolean; defaultValue: number };
  numberOfInterviewers?: { enabled: boolean; defaultValue: number };
  programmingLanguage?: { enabled: boolean; defaultValue?: ProgrammingLanguage };
}

export class InterviewSettings {
  interviewDifficulty: InterviewDifficulty;
  interviewerPersonality: InterviewerPersonality;
  interviewStage: InterviewStage;
  companyType: CompanyType;
  candidateExperienceLevel: CandidateExperienceLevel;
  candidateRoleType: TargetRoleType;
  timeLimitInMinutes: number;
  timeLeftInMinutes: number;
  numberOfInterviewers: number;

  constructor(
    interviewDifficulty: InterviewDifficulty,
    interviewerPersonality: InterviewerPersonality,
    interviewType: InterviewStage,
    companyType: CompanyType,
    candidateExperienceLevel: CandidateExperienceLevel,
    candidateRoleType: TargetRoleType,
    timeLimitInMinutes: number,
    timeLeftInMinutes: number,
    numberOfInterviewers: number
  ) {
    this.interviewDifficulty = interviewDifficulty;
    this.interviewerPersonality = interviewerPersonality;
    this.interviewStage = interviewType;
    this.companyType = companyType;
    this.candidateExperienceLevel = candidateExperienceLevel;
    this.candidateRoleType = candidateRoleType;
    this.timeLimitInMinutes = timeLimitInMinutes;
    this.timeLeftInMinutes = timeLeftInMinutes;
    this.numberOfInterviewers = numberOfInterviewers;
  }
}

export enum ProgrammingLanguage { // NITO-language-single-source: single enum for programming languages!
  Python = "python",
  Cpp = "cpp",
  JavaScript = "javascript",
  Java = "java",
  Go = "go",
  Csharp = "csharp",
  C = "c",
  Typescript = "typescript",
  Ruby = "ruby",
  Swift = "swift",
  Kotlin = "kotlin",
  Php = "php",
  Scala = "scala",
  Rust = "rust",
  Haskell = "haskell",
  R = "r",
  Perl = "perl",
  Bash = "bash",
  // Cpp11 = "cpp11",
  // Cpp14 = "cpp14",
  // Cpp17 = "cpp17",
  // Cpp20 = "cpp20",
  //PseudoCode = "pseudo-code"
}

enum CodExecutionOptions {
  // Enable = "Enabled",
  Disable = "Disabled"
}


/* Read-only constants */
export const TIME_LIMIT_IN_MINUTES_DEFAULT: Readonly<number> = InterviewDurations.TWENTY_MINUTES;
export const TIME_LEFT_IN_MINUTES_DEFAULT: Readonly<number> = TIME_LIMIT_IN_MINUTES_DEFAULT;
export const NUMBER_OF_INTERVIEWERS_DEFAULT: Readonly<number> = 1;

interface SettingsViewProps {
  onClose: () => void;
  interviewSettings: InterviewSettings;
  setInterviewSettings: (interviewSettings: InterviewSettings) => void;
  programmingLanguage: ProgrammingLanguage;
  setProgrammingLanguage: (programmingLanguage: ProgrammingLanguage) => void;
  settingsAccessibility?: SettingsAccessibility;
}


function SettingsView({ onClose, interviewSettings, setInterviewSettings, programmingLanguage, setProgrammingLanguage, settingsAccessibility
}: SettingsViewProps) {

  const programmingLanguageAccessibility = settingsAccessibility?.programmingLanguage;
  //log  settingsAccessibility, print json pretty
  console.log("settingsAccessibility: ", JSON.stringify(settingsAccessibility, null, 2));

  const programmingLanguageEnabled = programmingLanguageAccessibility?.enabled !== false;
  const programmingLanguageDefaultValue = programmingLanguageAccessibility?.defaultValue || ProgrammingLanguage.Python;

  const timeOptions = [TIME_LIMIT_IN_MINUTES_DEFAULT, 25, 30, 35, 45, 60]//NEBUG: TODO  ENABLE ONCE YOU HAVE MORE PROBLEMS, 30, 60];
  // const numberOfInterviewersOptions =[1]; //NEBUG: LOW PRIORITY TODO: implement multiple interviewers

  // @ts-ignore
  const handleSave = () => {
    // log dummy code
    console.log("Mock Saving interview settings..."); //NEBUG: TODO: implement persistence of interview settings
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="settings-view">
      <h1>
        <i className="fas fa-cog"></i> Settings
      </h1>
      <span className="setting-note">
        Note some settings are not configurable and are disabled for certain interviews. All changes made here take effect automatically. 
      </span>
      <div className="settings-grid">
        <div className="setting-row">
          <label>
            <i className="fas fa-user"></i> Interviewer Personality:
            <select
              value={interviewSettings.interviewerPersonality || InterviewerPersonality.Fair}
              onChange={(event) => {
                let eventValueAsString = event.target.value.toString();

                // validate
                if (!Object.values(InterviewerPersonality).includes(eventValueAsString as InterviewerPersonality)) {
                  //log error
                  console.error(`Invalid interviewer personality value: ${eventValueAsString}. Defaulting to ${InterviewerPersonality.Fair}.`);
                  // convert InterviewerPersonality.Fair to string
                  eventValueAsString = InterviewerPersonality.Fair;
                }

                // update the interview settings
                setInterviewSettings({
                  ...interviewSettings, // shallow copy the existing interview settings
                  interviewerPersonality: eventValueAsString as InterviewerPersonality, // update the interviewer personality property of the interview settings
                });
              }
              }
              disabled={false}
            >
              {Object.values(InterviewerPersonality).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="setting-row">
          <label>
            <i className="fas fa-tasks"></i> Programming Language:
            <select
              value={programmingLanguage || programmingLanguageDefaultValue}
              onChange={(event) => {
                if (programmingLanguageEnabled) {                  
                  const eventValueAsString = event.target.value.toString();
                  const isValidLanguage = Object.values(ProgrammingLanguage).includes(eventValueAsString as ProgrammingLanguage);

                  if (!isValidLanguage) {
                    console.error(`Invalid/Unsupported programming language value: ${eventValueAsString}. Defaulting to ${programmingLanguageDefaultValue}.`);
                    setProgrammingLanguage(programmingLanguageDefaultValue);
                  } else {
                    setProgrammingLanguage(eventValueAsString as ProgrammingLanguage);
                  }
                }
              }}
              disabled={!programmingLanguageEnabled}
            >
              {programmingLanguageEnabled
                ? Object.values(ProgrammingLanguage).map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))
                : <option key={programmingLanguageDefaultValue} value={programmingLanguageDefaultValue}>{programmingLanguageDefaultValue}</option>
              }
            </select>
          </label>
        </div>
        <div className="setting-row">
          <label>
            <i className="fas fa-laptop"></i> Interview Type:
            <select
              value={interviewSettings.interviewStage || InterviewStage.Onsite}
              onChange={(event) => {
                let eventValueAsString = event.target.value.toString();

                // validate
                if (!Object.values(InterviewStage).includes(eventValueAsString as InterviewStage)) {
                  //log error
                  console.error(`Invalid interview type value: ${eventValueAsString}. Defaulting to ${InterviewStage.Onsite}.`);
                  eventValueAsString = InterviewStage.Onsite;
                }

                // update the interview settings
                setInterviewSettings({
                  ...interviewSettings, // shallow copy the existing interview settings
                  interviewStage: eventValueAsString as InterviewStage, // update the interview type property of the interview settings
                });
              }
              }
              disabled={!settingsAccessibility?.interviewStage?.enabled}
            >
              {Object.values(InterviewStage).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="setting-row">
          <label>
            <i className="fas fa-clock"></i> Interview Max Duration (minutes):
            <select
              title="Select the maximum duration for the interview"
              value={interviewSettings.timeLimitInMinutes}
              onChange={(event) => {
                // validate event.target.value and  log error if not an integer
                let newTimeLimitInMinutes = parseInt(event.target.value);
                if (isNaN(newTimeLimitInMinutes)) {
                  // set default value to first element in timeOptions array
                  newTimeLimitInMinutes = timeOptions[0];
                  console.error(
                    `Invalid time limit value: ${event.target.value}. Defaulting to ${newTimeLimitInMinutes} minutes.`
                  );
                }

                // update the interview settings
                setInterviewSettings({
                  ...interviewSettings, // shallow copy the existing interview settings
                  timeLimitInMinutes: newTimeLimitInMinutes, // update the time limit property of the interview settings
                });

              }}
              disabled={true}
            >
              {timeOptions.map((option) => (
                <option key={option} value={option}>
                  {option} minutes
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="setting-row">
          <label>
            <i className="fas fa-tasks"></i> Interview Difficulty:
            <select
              value={interviewSettings.interviewDifficulty || InterviewDifficulty.ModeratelyChallenging}
              onChange={(event) => {
                let eventValueAsString = event.target.value.toString();

                // validate
                if (!Object.values(InterviewDifficulty).includes(eventValueAsString as InterviewDifficulty)) {
                  //log error
                  console.error(`Invalid interview difficulty value: ${eventValueAsString}. Defaulting to ${InterviewDifficulty.ModeratelyChallenging}.`);
                  eventValueAsString = InterviewDifficulty.ModeratelyChallenging;
                }

                // update the interview settings
                setInterviewSettings({
                  ...interviewSettings, // shallow copy the existing interview settings
                  interviewDifficulty: eventValueAsString as InterviewDifficulty, // update the interview difficulty property of the interview settings
                });


              }}
              disabled={true}
            >

              {Object.values(InterviewDifficulty).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        </div>
        {/* <div className="setting-row">
              <label>
                <i className="fas fa-user-friends"></i> Number of Interviewers:
                <select
                  value={interviewSettings.numberOfInterviewers || 1}
                  onChange={(event) => {
                    let eventValueAsNumber = parseInt(event.target.value);

                    // validate
                    if (isNaN(eventValueAsNumber)) {
                      //log error
                      console.error(`Invalid number of interviewers value: ${event.target.value}. Defaulting to 1.`);
                      // set default value to 1
                      eventValueAsNumber = 1;
                    }

                    // update the interview settings
                    setInterviewSettings({
                      ...interviewSettings, // shallow copy the existing interview settings
                      numberOfInterviewers: eventValueAsNumber, // update the number of interviewers property of the interview settings
                    });

                  }
                  }
                >
                  {numberOfInterviewersOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
            </div> */}
        {/* <div className="setting-row">
              <label>
                <i className="fas fa-building"></i> Company Type:
                <select
                  value={interviewSettings.companyType || CompanyType.GenericTechCompany}
                  onChange={(event) => {
                    let eventValueAsString = event.target.value.toString();
                    //validate
                    if (!Object.values(CompanyType).includes(eventValueAsString as CompanyType)) {
                      //log error
                      console.error(`Invalid company type value: ${eventValueAsString}. Defaulting to ${CompanyType.GenericTechCompany}.`);
                      //convert CompanyType.GenericTechCompany to string
                      eventValueAsString = CompanyType.GenericTechCompany;
                    }

                    //update the interview settings
                    setInterviewSettings({
                      ...interviewSettings, //shallow copy the existing interview settings
                      companyType: eventValueAsString as CompanyType, //update the company type property of the interview settings
                    });

                  }
                  }
                >
                  {Object.values(CompanyType).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
            </div> */}
        {/* <div className="setting-row">
              <label>
                <i className="fas fa-user-tie"></i> Candidate Experience Level:
                <select
                  value={interviewSettings.candidateExperienceLevel || CandidateExperienceLevel.Junior}
                  onChange={(event) => {
                    let eventValueAsString = event.target.value.toString();
                    //validate
                    if (!Object.values(CandidateExperienceLevel).includes(eventValueAsString as CandidateExperienceLevel)) {
                      //log error
                      console.error(`Invalid candidate experience level value: ${eventValueAsString}. Defaulting to ${CandidateExperienceLevel.Junior}.`);
                      //convert CandidateExperienceLevel.Junior to string
                      eventValueAsString = CandidateExperienceLevel.Junior;
                    }

                    //update the interview settings
                    setInterviewSettings({
                      ...interviewSettings, //shallow copy the existing interview settings
                      candidateExperienceLevel: eventValueAsString as CandidateExperienceLevel, //update the candidate experience level property of the interview settings
                    });
                  }
                  }
                >
                  {Object.values(CandidateExperienceLevel).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
            </div> */}

        {/* <div className="setting-row">
              <label>
                <i className="fas fa-briefcase"></i> Candidate Role Type:
                <select
                  value={interviewSettings.candidateRoleType || TargetRoleType.SoftwareEngineer}
                  onChange={(event) => {
                    let eventValueAsString = event.target.value.toString();
                    //validate
                    if (!Object.values(TargetRoleType).includes(eventValueAsString as TargetRoleType)) {
                      //log error
                      console.error(`Invalid candidate role type value: ${eventValueAsString}. Defaulting to ${TargetRoleType.SoftwareEngineer}.`);
                      //convert TargetRoleType.SoftwareEngineer to string
                      eventValueAsString = TargetRoleType.SoftwareEngineer;
                    }

                    //update the interview settings
                    setInterviewSettings({
                      ...interviewSettings, //shallow copy the existing interview settings
                      candidateRoleType: eventValueAsString as TargetRoleType, //update the candidate role type property of the interview settings
                    });

                  }
                  }
                >
                  {Object.values(TargetRoleType).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
            </div> */}
        {/* add setting that controls whether to enable or disable code execution */}
        <div className="setting-row">
          <label>
            Code Execution:
            <select
              value={CodExecutionOptions.Disable}
              onChange={(event) => {
                let eventValueAsString = event.target.value.toString();

                // validate
                if (!Object.values(CodExecutionOptions).includes(eventValueAsString as CodExecutionOptions)) {
                  //log error
                  console.error(`Invalid code execution value: ${eventValueAsString}. Defaulting to ${CodExecutionOptions.Disable}.`);

                  eventValueAsString = CodExecutionOptions.Disable;
                }


                // nebug: todo low priority: update state variable for CodExecutionOptions
              }
              }
              disabled={true}
            >
              {Object.values(CodExecutionOptions).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </label>

        </div>

      </div> {/* end of settings-grid */}
      <div className="settings-buttons">
        {/* NEBUG: uncomment once save feature available <button className="save-button" onClick={handleSave}>
          <i className="fas fa-save"></i> Save
        </button> */}
        <button className="cancel-button" onClick={handleClose}>
          <i className="fas fa-times"></i> Close
        </button>
      </div>
    </div>
  );
}

export default SettingsView;