import React, { useEffect, useRef, useState, useContext } from 'react';
import { ChatMessage, ChatMessageSender, InterviewerCoachInteractionContext, SIGN_UP_PAGE_PATH } from "./utils/Constants"
import {
  ChatContainer,
  MainContainer,
  MessageList,
  Message,
  MessageInput,
  Button,
  ExpansionPanel,
  Avatar,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import LillyInterviewerImg from './img/avatar/lilly.svg'
import './css/AiAssistantChatWindow.css'
import { logToBackendLogFile, triggerInterviewerCoachDetectBugsInCodeRequest, triggerInterviewerCoachProvideClarificationRequest, triggerInterviewerCoachProvideCodeFeedbackRequest, triggerInterviewerCoachProvideHintRequest, triggerInterviewerCoachRecommendCodeImprovementsRequest, triggerInterviewerCoachRespondToMessageRequest } from './externalLayerAccessor/BackEndRequests';
import { useNavigate } from 'react-router-dom';
import { isUserSignedIn } from './utils/HelperFunctions';
import UserAccessManager from "./utils/UserAccessManager";
import StateAccessor from "../src/StateAccessor";
import { userHasPremiumSubscriptionPrivilege, userNeedsPermissionForPremiumContentAccess } from "@/utils/PermissionUtils";
import ErrorBoundary from './utils/ErrorBoundary';

export enum AIInteractionContext {
  MockInterviewing = 'MockInterviewing',
  InterviewCoaching = 'InterviewCoaching',
  CVOptimisation = 'CVOptimisation',
}

enum ChatScopeTypingIndicatorMsg {
  THINKING = 'thinking',
  LISTENING = 'listening',
  READY = 'ready',
}

enum ChatScopeMsgDirection {
  Outgoing = 'outgoing',
  Incoming = 'incoming',
}

class PredefinedInteractionsWithAIAssistant {
  messageFromCandidate: string;
  callbackForGettingResponseViaHttpRequest: (
    interactionContext: InteractionContext
  ) => Promise<ChatMessage>;

  constructor(
    message: string,
    callback: (interactionContext: InteractionContext) => Promise<ChatMessage>,
  ) {
    this.messageFromCandidate = message;
    this.callbackForGettingResponseViaHttpRequest = callback;
  }
}

const predefinedInteractionsWithAIAssistantCoach: PredefinedInteractionsWithAIAssistant[] = [
  new PredefinedInteractionsWithAIAssistant(
    "Detect bugs",
    triggerInterviewerCoachDetectBugsInCodeRequest
  ),
  new PredefinedInteractionsWithAIAssistant(
    "provide code feedback",
    triggerInterviewerCoachProvideCodeFeedbackRequest
  ),
  new PredefinedInteractionsWithAIAssistant(
    "recommend code improvements",
    triggerInterviewerCoachRecommendCodeImprovementsRequest
  ),
  new PredefinedInteractionsWithAIAssistant(
    "provide hint",
    triggerInterviewerCoachProvideHintRequest
  ),
  new PredefinedInteractionsWithAIAssistant(
    "provide clarification",
    triggerInterviewerCoachProvideClarificationRequest
  )
];

export type InteractionContext = InterviewerCoachInteractionContext; // can extend interface to support multiple context types e.g. InteractionContext = InterviewerCoachInteractionContext | MockInterviewingContext

interface AiAssistantChatWindowProps {
  aiInteractionContext: AIInteractionContext;
  isInteractionEnabled: boolean; // determines if user can interact with the AI assistant
  interactionContext: InteractionContext;
  newChatMessageHandler: (chatMessage: ChatMessage) => void;
}

const AiAssistantChatWindow: React.FC<AiAssistantChatWindowProps> = ({
  isInteractionEnabled,
  aiInteractionContext,
  interactionContext,
  newChatMessageHandler,
}) => {
  const [isProcessingResponse, setIsProcessingResponse] = useState(false);
  const callbackForGettingAiAssistantResponseFnRef = useRef<((interactionContext: InteractionContext) => Promise<ChatMessage>) | null>(null);
  const [triggerAccessFlow, setTriggerAccessFlow] = useState(false);
  const [resetFlowKey, setResetFlowKey] = useState(0);
  const { userSubscriptionTier} = useContext(StateAccessor);

  /////////////////////////////////
  // Helper functions
  /////////////////////////////////

  const getHandlerForMsgEnteredInChatWindow = (): ((interactionContext: InteractionContext) => Promise<ChatMessage>) => {
    switch (aiInteractionContext) {
      case AIInteractionContext.InterviewCoaching:
        return triggerInterviewerCoachRespondToMessageRequest;
      // case AIInteractionContext.CVOptimisation:
      //   return fakeInteractionForTesting;
      // case AIInteractionContext.MockInterviewing:
      //   return fakeInteractionForTesting;
      default:
        throw new Error("Error: aiInteractionContext not recognised");
    }
  }
  const defaultHandlerForAiAssistantResponse = (responseChatMessage: ChatMessage) => {
    newChatMessageHandler(responseChatMessage);
  }
  const getAiAssistantResponseHandlerFn = (): (responseChatMessage: ChatMessage) => void => {
    switch (aiInteractionContext) {
      case AIInteractionContext.InterviewCoaching:
        return defaultHandlerForAiAssistantResponse;
      case AIInteractionContext.CVOptimisation:
        return defaultHandlerForAiAssistantResponse;
      case AIInteractionContext.MockInterviewing:
        return defaultHandlerForAiAssistantResponse;
      default:
        return defaultHandlerForAiAssistantResponse
    }
  }

  const handlePredefinedInteraction = (
    predefinedInteraction: PredefinedInteractionsWithAIAssistant
  ) => {
    handleMsgFromCandidate(
      predefinedInteraction.messageFromCandidate,
      predefinedInteraction.callbackForGettingResponseViaHttpRequest
    );
  };
  
  
  const handleMsgFromCandidate = (
    msgFromUser: string,
    callbackForGettingResponse: ((interactionContext: InteractionContext) => Promise<ChatMessage>),
  ) => {

    if (userNeedsPermissionForPremiumContentAccess(interactionContext.userId, userSubscriptionTier)) {
      setTriggerAccessFlow(true);
      setResetFlowKey((prev) => prev + 1); // Reset the flow key to trigger unmounting & mounting of the UserAccessManager component //TODO: find a way to avoid having to do this
      return;
    }  
    //console.log(`callbackForGettingResponse is ${callbackForGettingResponse.name}`);
    const chatMessageFromCandidate = new ChatMessage(
      ChatMessageSender.Candidate,
      ChatMessageSender.InterviewerCoach,
      new Date().toISOString(),
      msgFromUser
    );

    // Note the happens before relationship between the following lines, hence the use of useRef
    callbackForGettingAiAssistantResponseFnRef.current = callbackForGettingResponse; // this will be used to get response from ai assistant to user's message
    newChatMessageHandler(chatMessageFromCandidate); // this will be used to update the chat window with the user's message
    // Note: above lines have sequence dependency, that ensures the callbacks are set before the useEffect hook is triggered
  };

  const handleAccessGranted = () => {
    // Callback when access is successfully granted
    console.log("Access granted to premium content!");
  };

  const handleAccessManagerClose = () => {
    // Reset trigger to allow the premium flow to be retried
    setTriggerAccessFlow(false);
  };

  const handleMsgFromCandidateEnteredInChatWindow = (
    innerHtml: string, textContent: string,
  ) => {

    const callbackFn = getHandlerForMsgEnteredInChatWindow();
    handleMsgFromCandidate(
      textContent,
      callbackFn
    );
  };


  /////////////////////////////////
  // useEffect hooks
  /////////////////////////////////
  useEffect(() => {
    setIsProcessingResponse(true);
    if (interactionContext.chatHistory.length > 0) { // process messages sent from candidate
      const lastChatMessage: ChatMessage = interactionContext.chatHistory[interactionContext.chatHistory.length - 1];
      // handle messages sent from candidate, by requesting response from aiAssistant
      if (callbackForGettingAiAssistantResponseFnRef.current && lastChatMessage.sender === ChatMessageSender.Candidate) {

        //@ts-ignore
        callbackForGettingAiAssistantResponseFnRef.current(interactionContext)
          .then((responseChatMessage: ChatMessage) => {
            getAiAssistantResponseHandlerFn()(responseChatMessage);
          })
          .catch((error) => {
            console.error("Error: " + error);
          });

        callbackForGettingAiAssistantResponseFnRef.current = null;
      }
    }
    setTimeout(() => { // consider dropping this to reduce re-rendering. THis was done to ensure the typing indicator is shown for a short while by bypassing the baching of state changes via setIsProcessingResponse
      setIsProcessingResponse(false);
    }, 0);
  }, [interactionContext.chatHistory]);



  return (
    <>
    <ErrorBoundary>
      <UserAccessManager
        key={resetFlowKey}
        triggerAccessFlow={triggerAccessFlow}
        onAccessGranted={handleAccessGranted}
        resetAccessFlow={handleAccessManagerClose}
      />
    </ErrorBoundary> 
    <div className="ai-chat-window-container">
      <MainContainer responsive>
        <ChatContainer>
          <MessageList
            typingIndicator={
              isProcessingResponse ? (
                <TypingIndicator content={ChatScopeTypingIndicatorMsg.THINKING} />
              ) : (
                <TypingIndicator content={ChatScopeTypingIndicatorMsg.READY} />
              )
            }
          >
            {interactionContext.chatHistory.map((msg, index) => (
              <Message
                key={index}
                model={{
                  // id: index,
                  message: msg.message,
                  sentTime: msg.timeSent,
                  sender: msg.sender,
                  direction: msg.sender === ChatMessageSender.Candidate ? ChatScopeMsgDirection.Outgoing : ChatScopeMsgDirection.Incoming,
                  position: "single",
                }}
              >
                {msg.sender === ChatMessageSender.Interviewer && <Avatar src={LillyInterviewerImg} name="Interviewer" />}
                <Message.Header sender={msg.sender !== ChatMessageSender.Candidate ? msg.sender : "you"} />
              </Message>
            ))}
          </MessageList>
          <MessageInput
            placeholder="Type your message..."
            autoFocus={true}
            onSend={handleMsgFromCandidateEnteredInChatWindow}
            attachButton={false}
            disabled={!isInteractionEnabled}
          />
        </ChatContainer>
      </MainContainer>
      <ExpansionPanel open title="Quick Actions">
        {predefinedInteractionsWithAIAssistantCoach.map(
          (predefinedInteraction, index) => (
            <Button
              border
              key={index}
              onClick={() => handlePredefinedInteraction(predefinedInteraction)}
            >
              {predefinedInteraction.messageFromCandidate}
            </Button>
          )
        )}
      </ExpansionPanel>
    </div>
    </>
  );
};

export default AiAssistantChatWindow;