import React, { useContext, useEffect, useState } from "react";
import StateAccessor from "../StateAccessor";
import CodeEditorBox from "./CodeEditorBox";
import { CodeBlockByLanguage, Language } from "../utils/Constants";
import {
  doNothingAsync,
  getPreferredProgrammingLanguage,
  updatePreferredProgrammingLanguage
} from "../utils/HelperFunctions";
import "../css/CodingSandbox.css";
import { useLocation } from "react-router-dom";
import { Snackbar } from "@mui/material";

/////////////////////////////////
// Constants
/////////////////////////////////
const CODE_STUB_BY_LANGUAGE: Readonly<CodeBlockByLanguage> = {
  Python: "# Write your code here",
  Java: "// Write your code here",
  "C++": "// Write your code here",
  C: "// Write your code here",
  "C#": "// Write your code here",
  JavaScript: "// Write your code here",
  Go: "// Write your code here",
  Swift: "// Write your code here",
  TypeScript: "// Write your code here",
  Kotlin: "// Write your code here",
  Ruby: "# Write your code here",
};

const MOCK_INTERVIEW_UNIT_ID: number = 1;
const MOCK_INTERVIEW_TIMER_KEY: number = 0;
const DEFAULT_TIME_LIMIT_IN_MINUTES: number = 45;

const CodingSandbox: React.FC = () => {
  const { userId } = useContext(StateAccessor);

  // Editor & language states
  const [codeEditorValue, setCodeEditorValue] = useState<string>("");
  const [preferredProgrammingLanguage, setPreferredProgrammingLanguage] =
    useState<Language>(() => getPreferredProgrammingLanguage(Language.Go));
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(true);

  // Timer state (parsed from query param or default)
  const [timeLimitInMinutes, setTimeLimitInMinutes] = useState<number>(
    DEFAULT_TIME_LIMIT_IN_MINUTES
  );

  // Snackbar states
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const location = useLocation();

  // 1) Parse the time limit from query params ONCE or whenever location.search changes.
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const timeLimit = parseInt(queryParams.get("timeLimitInMinutes") || "", 10);

    if (isNaN(timeLimit) || timeLimit <= 0) {
      // Invalid or missing param → default
      setSnackbarMessage(
        `Using default of ${DEFAULT_TIME_LIMIT_IN_MINUTES} minutes for time limit.`
      );
      setTimeLimitInMinutes(DEFAULT_TIME_LIMIT_IN_MINUTES);
    } else {
      // Valid param
      setSnackbarMessage(`time limit set to ${timeLimit} minutes.`);
      setTimeLimitInMinutes(Math.max(timeLimit, 1));
    }

    // Open the snackbar either way
    setIsSnackbarOpen(true);
  }, [location.search]);

  // 2) Keep track of changes to the user’s preferred language
  useEffect(() => {
    updatePreferredProgrammingLanguage(preferredProgrammingLanguage);
  }, [preferredProgrammingLanguage]);

  // 3) Called when the editor’s timer completes
  const handleTimerComplete = () => {
    alert("Time is up!");
  };

  return (
    <div className="coding-sandbox-container">
      <CodeEditorBox
        userId={userId}
        codeEditorValue={codeEditorValue}
        setCodeEditorValue={setCodeEditorValue}
        codeBlockByLanguage={CODE_STUB_BY_LANGUAGE}
        timerComplete={handleTimerComplete}
        unit_id={MOCK_INTERVIEW_UNIT_ID}
        codeSubmissionHandler={doNothingAsync}
        codeRunHandler={doNothingAsync}
        rightAnswer={null}
        timerKey={MOCK_INTERVIEW_TIMER_KEY}
        codeEditorSettings={{
          isRunningDisabled: true,
          disabled: false,
          isSubmissionDisabled: true, // Disabled for mock interviews
          programmingLanguage: preferredProgrammingLanguage,
          setProgrammingLanguage: setPreferredProgrammingLanguage,
          isAssessment: false,
          timeLimitInMinutes: timeLimitInMinutes,
          isTimerDisabled: false,
          isTimerRunning: isTimerRunning,
          setIsTimerRunning: setIsTimerRunning,
          programmingLanguageWhiteList: new Set(Object.values(Language)),
          shouldPersistCode: true,
        }}
      />

      {/* 4) Snackbar for time limit notification */}
      <Snackbar
        open={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        message={snackbarMessage}
        autoHideDuration={2000} // 2s
      />
    </div>
  );
};

export default CodingSandbox;
