/**
 * UserAccessManager Component
 * 
 * This component handles user access verification and ensures users meet the necessary 
 * criteria (e.g., signed in, premium subscription) to access specific features or content.
 * 
 * Key Props:
 * - `triggerAccessFlow`: A boolean flag used to initiate the access flow. When set to `true`,
 *   the component performs the following:
 *   1. Checks if the user is signed in:
 *      - If not, it opens the Sign-In modal (`SignInModal`).
 *   2. If signed in, checks the user's subscription tier:
 *      - If the user does not have premium privileges, it opens the Premium Subscription modal
 *        (`PremiumSubscriptionModal`).
 *      - If the user meets all access criteria, the `onAccessGranted` callback is invoked.
 * 
 * - `resetAccessFlow`: A callback function to reset the flow trigger (e.g., to allow retries or cleanup).
 * - `onAccessGranted`: A callback invoked when the user successfully completes the access flow.
 * 
 * How `triggerAccessFlow` Works:
 * - The component uses a `useEffect` to listen for changes in `triggerAccessFlow`. When it changes to `true`:
 *   - It executes the access flow logic (sign-in and subscription checks).
 *   - Depending on the result, it either opens the appropriate modal or invokes `onAccessGranted`.
 * - After the flow is complete, the parent component can reset `triggerAccessFlow` to `false` 
 *   using the `resetAccessFlow` callback to prevent re-triggering.
 * 
 * Best Practices:
 * - Use `triggerAccessFlow` for explicit and reactive control over the access flow.
 * - Ensure the `resetAccessFlow` callback is used to reset the state after a flow completes.
 * 
 * Example Usage:
 * ```tsx
 * const [triggerAccessFlow, setTriggerAccessFlow] = useState(false);
 * 
 * const handleAccessRequest = () => {
 *   setTriggerAccessFlow(true);
 * };
 * 
 * return (
 *   <UserAccessManager
 *     triggerAccessFlow={triggerAccessFlow}
 *     onAccessGranted={() => console.log("Access granted!")}
 *     resetAccessFlow={() => setTriggerAccessFlow(false)}
 *   />
 * );
 * ```
 */

import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import SignInModal from "@/utils/SignInModal";
import PremiumSubscriptionModal from "@/utils/PremiumSubscriptionModal";
import StateAccessor from "@/StateAccessor";
import { isUserSignedIn } from "@/utils/HelperFunctions";
import {
  getSubscriptionByUserEmail,
  userHasPremiumSubscriptionPrivilege,
} from "@/utils/PermissionUtils";
import { Snackbar, Alert, CircularProgress, Backdrop } from "@mui/material";
import { UserSubscriptionTier } from "@/externalLayerAccessor/models/firebaseModels";

// Import your logging function
import {
  logToBackendLogFile,
  // other imports...
} from "@/externalLayerAccessor/BackEndRequests";

/**
 * ------------------------------------
 * Enums
 * ------------------------------------
 */
enum SnackbarVerticalPosition {
  Top = "top",
  Bottom = "bottom",
}

enum SnackbarHorizontalPosition {
  Left = "left",
  Center = "center",
  Right = "right",
}

enum ErrorMessages {
  SIGN_IN_CLOSED = "Sign-in was closed before completion.",
  SIGN_IN_FAILED = "Sign-in failed or was incomplete. Please try again.",
  SUBSCRIPTION_FETCH_FAILED = "Unable to fetch subscription details. Please try again later.",
}

export enum ModalType {
  SIGN_IN = "signIn",
  PREMIUM_SUBSCRIPTION = "premiumSubscription",
}

/**
 * ------------------------------------
 * Constants
 * ------------------------------------
 */
const SNACKBAR_AUTO_HIDE_DURATION = 4000; // ms

/**
 * ------------------------------------
 * Props
 * ------------------------------------
 */
interface UserAccessManagerProps {
  onAccessGranted?: () => void; // Callback when access is granted
  triggerAccessFlow?: boolean;  // Trigger the flow manually
  resetAccessFlow?: () => void; // Reset the flow
}

/**
 * ------------------------------------
 * Component
 * ------------------------------------
 */
const UserAccessManager: React.FC<UserAccessManagerProps> = ({
  onAccessGranted = () => {},
  triggerAccessFlow = false,
  resetAccessFlow = () => {},
}) => {
  const { userId, userEmail } = useContext(StateAccessor);

  // Tracks the type of the currently displayed modal (sign-in / subscription)
  const [currentModal, setCurrentModal] = useState<ModalType | null>(null);

  // Explicitly controls whether a modal is visible
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Subscription tier is stored in a ref to avoid unnecessary re-renders
  const userSubscriptionTierRef = useRef<UserSubscriptionTier>(UserSubscriptionTier.FREE);

  // Track UI loading state (Backdrop)
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

  // Prevent multiple concurrent subscription fetches
  const isFetchingSubscriptionRef = useRef(false);

  // Tracks sign-in state
  const [isSignInSuccessful, setIsSignInSuccessful] = useState(false);

  // Tracks errors displayed in Snackbar
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Determines whether the Snackbar is open
  const snackbarOpen = Boolean(errorMessage);
  const snackbarTimerRef = useRef<NodeJS.Timeout | null>(null);

  // -----------------------------------
  // Helper: Open a specific modal
  // -----------------------------------
  const openModal = useCallback((modalType: ModalType) => {
    setCurrentModal(modalType);
    setIsModalVisible(true);
  }, []);

  // -----------------------------------
  // Helper: Close the currently open modal
  // -----------------------------------
  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setCurrentModal(null);
  }, []);

  // -----------------------------------
  // Snackbar handling
  // -----------------------------------
  const handleSnackbarClose = useCallback(() => {
    if (snackbarTimerRef.current) {
      clearTimeout(snackbarTimerRef.current);
    }
    setErrorMessage(null);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      snackbarTimerRef.current = setTimeout(
        () => setErrorMessage(null),
        SNACKBAR_AUTO_HIDE_DURATION
      );
    }
    return () => {
      if (snackbarTimerRef.current) {
        clearTimeout(snackbarTimerRef.current);
      }
    };
  }, [errorMessage]);

  // -----------------------------------
  // Fetch or update subscription details
  // -----------------------------------
  const updateSubscriptionDetails = useCallback(async () => {
    if (!isUserSignedIn(userId)) {
      return null; // If user is not signed in, do nothing
    }

    // Guard: if already fetching, skip
    if (isFetchingSubscriptionRef.current) {
      return null;
    }

    // Lock the ref to prevent concurrency and set UI to loading
    isFetchingSubscriptionRef.current = true;
    setIsSubscriptionLoading(true);

    try {
      const subscription = await getSubscriptionByUserEmail(userEmail);
      userSubscriptionTierRef.current =
        subscription?.subscriptionTier || UserSubscriptionTier.FREE;
      return subscription; // Return the full subscription object
    } catch (error: any) {
      logToBackendLogFile(`Error fetching subscription: ${error.message}`, "error");
      setErrorMessage(
        "An error occurred while fetching your details. Please contact support or try again later."
      );
      return null;
    } finally {
      // Unlock ref and turn off loading
      isFetchingSubscriptionRef.current = false;
      setIsSubscriptionLoading(false);
    }
  }, [userId, userEmail]);

  // -----------------------------------
  // When the user is already signed in (and no forced flow), fetch subscription
  // -----------------------------------
  useEffect(() => {
    if (isUserSignedIn(userId) && !triggerAccessFlow) {
      void updateSubscriptionDetails(); // Fire-and-forget
    }
  }, [userId, triggerAccessFlow, updateSubscriptionDetails]);

  // -----------------------------------
  // handleSignInAttempt
  // Called once the sign-in modal is closed or success/failure is determined
  // -----------------------------------
  const handleSignInAttempt = useCallback(
    async (isSuccess: boolean) => {
      setIsSignInSuccessful(isSuccess);
      if (isSuccess) {
        setErrorMessage(null); // Clear error on success
        const subscription = await updateSubscriptionDetails();

        if (subscription) {
          const subTier = subscription.subscriptionTier || UserSubscriptionTier.FREE;
          if (userHasPremiumSubscriptionPrivilege(subTier)) {
            // Close the modal fully
            closeModal();
            onAccessGranted(); // Access granted
          } else {
            // If user lacks privilege, open subscription modal
            openModal(ModalType.PREMIUM_SUBSCRIPTION);
          }
        }
      } else {
        setErrorMessage(ErrorMessages.SIGN_IN_FAILED);
      }
    },
    [updateSubscriptionDetails, onAccessGranted, closeModal, openModal]
  );

  // -----------------------------------
  // handleModalClose
  // Called when the currently open modal is closed
  // -----------------------------------
  const handleModalClose = useCallback(() => {
    if (currentModal === ModalType.SIGN_IN && !isSignInSuccessful) {
      setErrorMessage(ErrorMessages.SIGN_IN_CLOSED);
    }

    // Close the modal fully
    closeModal();

    setIsSignInSuccessful(false);
    resetAccessFlow(); // Reset the trigger flow in parent
  }, [currentModal, isSignInSuccessful, resetAccessFlow, closeModal]);

  // -----------------------------------
  // Trigger Access Flow
  // Checks if user is signed in. If not, open sign-in modal.
  // If signed in, fetch subscription details and decide on access.
  // -----------------------------------
  useEffect(() => {
    if (!triggerAccessFlow) {
      return;
    }

    if (!isUserSignedIn(userId)) {
      // Show sign-in modal
      openModal(ModalType.SIGN_IN);
    } else {
      void updateSubscriptionDetails().then((subscription) => {
        const subTier = subscription?.subscriptionTier || UserSubscriptionTier.FREE;
        if (!userHasPremiumSubscriptionPrivilege(subTier)) {
          // Show subscription modal
          openModal(ModalType.PREMIUM_SUBSCRIPTION);
        } else {
          onAccessGranted();
        }
      });
    }
  }, [triggerAccessFlow, userId, onAccessGranted, updateSubscriptionDetails, openModal]);

  // -----------------------------------
  // Render
  // -----------------------------------
  return (
    <>
      {/* Loading State with Backdrop */}
      <Backdrop
        open={isSubscriptionLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Sign-In Modal */}
      {isModalVisible && currentModal === ModalType.SIGN_IN && (
        <SignInModal
          onClose={handleModalClose}
          onEmailSignInSuccess={() => handleSignInAttempt(true)}
          onGmailSignInSuccess={() => handleSignInAttempt(true)}
        />
      )}

      {/* Premium Subscription Modal */}
      {isModalVisible &&
        currentModal === ModalType.PREMIUM_SUBSCRIPTION &&
        !userHasPremiumSubscriptionPrivilege(userSubscriptionTierRef.current) && (
          <PremiumSubscriptionModal
            onSubscriptionComplete={() => {
              closeModal();
              onAccessGranted();
            }}
          />
        )}

      {/* Snackbar for Error Messages */}
      {errorMessage && (
        <Snackbar
          open={snackbarOpen}
          // Omit autoHideDuration in favor of manual timer
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: SnackbarVerticalPosition.Top,
            horizontal: SnackbarHorizontalPosition.Center,
          }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default UserAccessManager;
