import React, { useState, useEffect } from "react";
import "./css/OutputWindow.css";
import { ExecutionResult, TestCaseExecutionStatus } from "./utils/Constants";

interface OutputWindowProps {
  codeExecutionResult: ExecutionResult | null;
}

enum Tabs {
  StdOut = "Std Out",
  StdErr = "Std Err",
  ExecutionMetrics = "Execution Metrics",
}

interface TabContentProps {
  selectedTab: Tabs;
  codeExecutionResult: ExecutionResult | null;
}

// Constants for byte conversions
const BYTES_IN_MB = 1024 * 1024;
const DECIMAL_PLACES = 2;

/**
 * Converts bytes to megabytes with specified decimal places.
 * @param bytes - The number of bytes.
 * @returns The converted megabytes as a string with fixed decimal places.
 */
const bytesToMegabytes = (bytes: number): string => {
  return (bytes / BYTES_IN_MB).toFixed(DECIMAL_PLACES);
};

/**
 * Determines the default tab based on the test case execution status.
 * @param status - The test case execution status.
 * @returns The default tab to be selected.
 */
const getDefaultTab = (status: TestCaseExecutionStatus): Tabs => {
  switch (status) {
    case TestCaseExecutionStatus.COMPILATION_ERROR:
    case TestCaseExecutionStatus.RUNTIME_ERROR:
    case TestCaseExecutionStatus.INTERNAL_ERROR:
    case TestCaseExecutionStatus.EXEC_FORMAT_ERROR:
      return Tabs.StdErr;
    case TestCaseExecutionStatus.IN_QUEUE:
    case TestCaseExecutionStatus.PENDING:
    case TestCaseExecutionStatus.ACCEPTED:
    case TestCaseExecutionStatus.WRONG_ANSWER:
    case TestCaseExecutionStatus.TIME_LIMIT_EXCEEDED:
      return Tabs.StdOut;
    default:
      return Tabs.StdOut; // Fallback to Std Out for any undefined statuses
  }
};

const TabContent: React.FC<TabContentProps> = ({ selectedTab, codeExecutionResult }) => {
  if (!codeExecutionResult) {
    return <p>No execution result available</p>;
  }

  const {
    test_case_status,
    actual_output,
    stderr,
    execution_time_seconds,
    memory_usage_bytes,
  } = codeExecutionResult;

  switch (selectedTab) {
    case Tabs.StdOut:
      return (
        <pre>
          <p>{actual_output || "No output to display"}</p>
        </pre>
      );
    case Tabs.StdErr:
      // Determine if a specific error message should be displayed
      let errorMessage: string | null = null;

      if (!stderr) {
        switch (test_case_status) {
          case TestCaseExecutionStatus.COMPILATION_ERROR:
            errorMessage = "Compilation error occurred.";
            break;
          case TestCaseExecutionStatus.RUNTIME_ERROR:
            errorMessage = "Runtime error occurred.";
            break;
          case TestCaseExecutionStatus.INTERNAL_ERROR:
            errorMessage = "An internal error occurred.";
            break;
          case TestCaseExecutionStatus.EXEC_FORMAT_ERROR:
            errorMessage = "Execution format error occurred.";
            break;
          default:
            errorMessage = "An unknown error occurred.";
        }
      }

      return (
        <pre>
          <p>{stderr || errorMessage || "No errors to display"}</p>
        </pre>
      );
    case Tabs.ExecutionMetrics:
      const memoryUsageMB = memory_usage_bytes
        ? bytesToMegabytes(memory_usage_bytes)
        : "N/A";
      return (
        <>
          <pre>
            <p>Execution Time: {execution_time_seconds || "N/A"} seconds</p>
          </pre>
          <pre>
            <p>Memory Usage: {memoryUsageMB} MB</p>
          </pre>
        </>
      );
    default:
      return <p>Invalid tab selected.</p>;
  }
};

const OutputWindow: React.FC<OutputWindowProps> = ({ codeExecutionResult }) => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.StdOut);

  // Determine default tab based on test_case_status
  useEffect(() => {
    if (codeExecutionResult) {
      const defaultTab = getDefaultTab(codeExecutionResult.test_case_status);
      setSelectedTab(defaultTab);
    } else {
      setSelectedTab(Tabs.StdOut); // Default to Std Out if no result
    }
  }, [codeExecutionResult]);

  return (
    <div className="output-tabbed-view-container">
      <div className="output-tabbed-view-header">
        <button
          className={`std-out-tab ${
            selectedTab === Tabs.StdOut ? "selected-tab" : "selectable-tab"
          }`}
          onClick={() => setSelectedTab(Tabs.StdOut)}
        >
          Std Out
        </button>
        <button
          className={`std-err-tab ${
            selectedTab === Tabs.StdErr ? "selected-tab" : "selectable-tab"
          }`}
          onClick={() => setSelectedTab(Tabs.StdErr)}
        >
          Std Err
        </button>
        <button
          className={`execution-metrics-tab ${
            selectedTab === Tabs.ExecutionMetrics ? "selected-tab" : "selectable-tab"
          }`}
          onClick={() => setSelectedTab(Tabs.ExecutionMetrics)}
        >
          Execution Metrics
        </button>
      </div>
      <div className="output-tabbed-view-content">
        <TabContent selectedTab={selectedTab} codeExecutionResult={codeExecutionResult} />
      </div>
    </div>
  );
};

export default OutputWindow;