import React, { useState, useEffect, useMemo } from "react";
import Modal from "@/utils/Modal";
import SignInPage from "@/pages/SignInPage";
import PremiumSubscriptionModal from "@/utils/PremiumSubscriptionModal";

interface SignInModalProps {
  onClose: () => void;
  onEmailSignInSuccess?: () => void; // Callback for successful email-password sign-in
  onGmailSignInSuccess?: () => void; // Callback for successful Gmail sign-in
}

// Constant to avoid magic strings and make future localization easier
const GMAIL_BUTTON_LABEL = "Sign In / Sign Up with Google";

const SignInModal: React.FC<SignInModalProps> = ({
  onClose,
  onEmailSignInSuccess,
  onGmailSignInSuccess,
}) => {
  // State to determine if the Premium Subscription modal is visible
  const [showPremiumSubscriptionModal, setShowPremiumSubscriptionModal] =
    useState(false);

  // Cleanup state when the component unmounts to avoid potential memory leaks
  useEffect(() => {
    return () => {
      setShowPremiumSubscriptionModal(false);
    };
  }, []);

  // Reusable function to handle sign-in success logic
  const handleSignInSuccess = (callback?: () => void) => {
    setShowPremiumSubscriptionModal(true); // Show Premium Subscription modal
    callback && callback(); // Trigger the provided callback if it exists
  };

  // Close the Premium Subscription modal and the parent modal
  const handleModalClose = () => {
    setShowPremiumSubscriptionModal(false);
    onClose();
  };

  // Memoized modal content for performance optimization
  const modalContent = useMemo(
    () => (
      <SignInPage
        fromModal={true}
        gmailSignInButtonLabel={GMAIL_BUTTON_LABEL}
        onEmailSignInSuccess={() => handleSignInSuccess(onEmailSignInSuccess)}
        onGmailSignInSuccess={() => handleSignInSuccess(onGmailSignInSuccess)}
        finalizeSignInModal={handleModalClose}
      />
    ),
    [onEmailSignInSuccess, onGmailSignInSuccess]
  );

  return (
    <>
      {showPremiumSubscriptionModal ? (
        <PremiumSubscriptionModal
          onSubscriptionComplete={() => setShowPremiumSubscriptionModal(false)}
        />
      ) : (
        <Modal content={modalContent} onClose={onClose} />
      )}
    </>
  );
};

export default SignInModal;