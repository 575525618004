//NEBUG: MVP CODE NEEDS REFACTORING
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { InterviewMode, InterviewType, Company, RoleType, companies, CompanyName, RoleTypeName, AI_INTERVIEW_PAGE_PATH } from '../utils/Constants';
import '../css/InterviewSelectionPage.css';

import { styled } from "@mui/system";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import InterviewSelectionModal from './InterviewSelectionModal';
import { InterviewDifficulty, InterviewStage, ProgrammingLanguage } from './Settings';
import { InterviewDurations } from '../utils/Constants';
import metaLogo from '../img/logos/meta_logo.png';
import amazonLogo from '../img/logos/Amazon_logo.svg.png';
import googleLogo from '../img/logos/google_logo.png';
import openAILogo from '../img/logos/open_ai_logo.png';
import genericTechCompanyLogo from '../img/logos/generic_tech_company.png';
import { InterviewScript, TECH_INTERVIEW_SCRIPTS_BY_COMPANY } from '../utils/InterviewScript';
import Sidebar from '../utils/SideBar';
import StateAccessor from '@/StateAccessor';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 120,
    width: "70%",
    backgroundColor: "#e0e0e0;",
    borderRadius: theme.shape.borderRadius,
    "& .MuiInputBase-root": {
        color: "black",
    },
    "& .Mui-disabled": {
        backgroundColor: "#b0bec5", // this color will be used when the select is disabled
    },
}));

interface InterviewCard {
    id: number;
    company: string;
    roleType: string;
    level: string;
    interviewType: string;
    imageUrl: string;
    isAvailable: boolean;
    isExperimental?: boolean;
}
export interface Interview {
    interviewId: number;
    interviewName: string;
    interviewDifficulty: InterviewDifficulty;
    interviewDurationInMinutes: InterviewDurations;
    interviewStage: InterviewStage;
    interviewType: InterviewType;
    company: CompanyName;
    roleType: RoleTypeName;
    restrictedProgrammingLanguage?: ProgrammingLanguage;
}


interface SelectFilterProps {
    value: string;
    options: { value: string; label: string }[];
    disabled?: boolean;
    placeholder: string;
    onChange: (value: string) => void;
}

const SelectFilter: React.FC<SelectFilterProps> = ({
    value,
    options,
    disabled,
    placeholder,
    onChange,
}) => (
    <StyledFormControl>
        {!value && <InputLabel>{placeholder}</InputLabel>}
        <Select
            value={value || ""}
            onChange={(e) => onChange(e.target.value as string)}
            disabled={disabled}
        >
            <MenuItem value="">
                <em>{placeholder}</em>
            </MenuItem>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    </StyledFormControl>
);

function transformToInterview(script: InterviewScript): Interview {
    return {
        interviewId: script.interviewId,
        interviewName: script.interviewName,
        interviewDifficulty: script.difficulty,
        interviewDurationInMinutes: script.requiredTimeInMinutes,
        interviewStage: script.interviewStage,
        interviewType: script.interviewType,
        company: script.company,
        roleType: script.roleType,
        restrictedProgrammingLanguage: script.restrictedProgrammingLanguage,
    };
}

/***********************************************************************************************
 GENERIC COMPANY INTERVIEW SCRIPTS
***********************************************************************************************/
 
const GENERIC_COMPANY_INTERVIEW_SCRIPTS = TECH_INTERVIEW_SCRIPTS_BY_COMPANY.get(CompanyName.GENERIC_TECH_COMPANY);
if (GENERIC_COMPANY_INTERVIEW_SCRIPTS === undefined) {
    console.error("GENERIC_COMPANY_INTERVIEW_SCRIPTS is undefined");
    throw new Error("GENERIC_COMPANY_INTERVIEW_SCRIPTS is undefined");
}
const GENERIC_COMPANY_ONSITE_INTERVIEWS = GENERIC_COMPANY_INTERVIEW_SCRIPTS.map(transformToInterview);
const GENERIC_COMPANY_TAKE_HOME_ASSESSMENTS: Interview[] = [
    {
        interviewId: 2000000000,
        interviewName: "SWE OA 1",
        interviewDifficulty: InterviewDifficulty.Straightforward,
        interviewDurationInMinutes: InterviewDurations.TWENTY_MINUTES,
        interviewStage: InterviewStage.TakeHomeAssessment,
        interviewType: InterviewType.TECHNICAL,
        company: CompanyName.GENERIC_TECH_COMPANY,
        roleType: RoleTypeName.SOFTWARE_ENGINEER,
    },
    {
        interviewId: 2000000001,
        interviewName: "SWE OA 2",
        interviewDifficulty: InterviewDifficulty.Straightforward,
        interviewDurationInMinutes: InterviewDurations.SIXTY_MINUTES,
        interviewStage: InterviewStage.TakeHomeAssessment,
        interviewType: InterviewType.TECHNICAL,
        company: CompanyName.GENERIC_TECH_COMPANY,
        roleType: RoleTypeName.SOFTWARE_ENGINEER,
    },
    {
        interviewId: 2000000002,
        interviewName: "SWE OA 3",
        interviewDifficulty: InterviewDifficulty.ModeratelyChallenging,
        interviewDurationInMinutes: InterviewDurations.TWENTY_MINUTES,
        interviewStage: InterviewStage.TakeHomeAssessment,
        interviewType: InterviewType.TECHNICAL,
        company: CompanyName.GENERIC_TECH_COMPANY,
        roleType: RoleTypeName.SOFTWARE_ENGINEER,
    },

];
const GENERIC_COMPANY_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS: Interview[] = [
    ...GENERIC_COMPANY_ONSITE_INTERVIEWS,
    ...GENERIC_COMPANY_TAKE_HOME_ASSESSMENTS
];

/***********************************************************************************************
 GOOGLE INTERVIEW SCRIPTS
***********************************************************************************************/

const GOOGLE_INTERVIEW_SCRIPTS = TECH_INTERVIEW_SCRIPTS_BY_COMPANY.get(CompanyName.GOOGLE);
if (GOOGLE_INTERVIEW_SCRIPTS === undefined) {
    console.error("GOOGLE_INTERVIEW_SCRIPTS is undefined");
    throw new Error("GOOGLE_INTERVIEW_SCRIPTS is undefined");
}
const GOOGLE_ONSITE_INTERVIEWS = GOOGLE_INTERVIEW_SCRIPTS.map(transformToInterview);
const GOOGLE_TAKE_HOME_ASSESSMENTS: Interview[] = [];
const GOOGLE_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS: Interview[] = [
    ...GOOGLE_ONSITE_INTERVIEWS,
    ...GOOGLE_TAKE_HOME_ASSESSMENTS
];

/***********************************************************************************************
    META INTERVIEW SCRIPTS
***********************************************************************************************/
const META_INTERVIEW_SCRIPTS = TECH_INTERVIEW_SCRIPTS_BY_COMPANY.get(CompanyName.META);
if (META_INTERVIEW_SCRIPTS === undefined) {
    console.error("META_INTERVIEW_SCRIPTS is undefined");
    throw new Error("META_INTERVIEW_SCRIPTS is undefined");
}
const META_SWE_ONSITE_INTERVIEWS = META_INTERVIEW_SCRIPTS.filter(script => script.roleType === RoleTypeName.SOFTWARE_ENGINEER).map(transformToInterview);
const META_SWE_TAKE_HOME_ASSESSMENTS: Interview[] = [];
const META_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS: Interview[] = [
    ...META_SWE_ONSITE_INTERVIEWS,
    ...META_SWE_TAKE_HOME_ASSESSMENTS
];

const META_PROD_ENGR_ONSITE_INTERVIEWS = META_INTERVIEW_SCRIPTS.filter(script => script.roleType === RoleTypeName.PRODUCTION_ENGINEER).map(transformToInterview);
const META_PROD_ENGR_TAKE_HOME_ASSESSMENTS: Interview[] = [];
const META_PROD_ENGR_TECHNICAL_INTERVIEWS: Interview[] = [
    ...META_PROD_ENGR_ONSITE_INTERVIEWS,
    ...META_PROD_ENGR_TAKE_HOME_ASSESSMENTS
];

/***********************************************************************************************
    AMAZON INTERVIEW SCRIPTS
***********************************************************************************************/
const AMAZON_INTERVIEW_SCRIPTS = TECH_INTERVIEW_SCRIPTS_BY_COMPANY.get(CompanyName.AMAZON);
if (AMAZON_INTERVIEW_SCRIPTS === undefined) {
    console.error("AMAZON_INTERVIEW_SCRIPTS is undefined");
    throw new Error("AMAZON_INTERVIEW_SCRIPTS is undefined");
}
const AMAZON_ONSITE_INTERVIEWS = AMAZON_INTERVIEW_SCRIPTS.map(transformToInterview);
const AMAZON_TAKE_HOME_ASSESSMENTS: Interview[] = [];
const AMAZON_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS: Interview[] = [
    ...AMAZON_ONSITE_INTERVIEWS,
    ...AMAZON_TAKE_HOME_ASSESSMENTS
];


const pseudoTechnicalInterviewsDatabaseTableMap: Map<string, Interview[]> = new Map([
    [`${CompanyName.GENERIC_TECH_COMPANY}_${RoleTypeName.SOFTWARE_ENGINEER}`, GENERIC_COMPANY_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS],
    [`${CompanyName.GOOGLE}_${RoleTypeName.SOFTWARE_ENGINEER}`, GOOGLE_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS],
    [`${CompanyName.META}_${RoleTypeName.SOFTWARE_ENGINEER}`, META_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS],
    [`${CompanyName.META}_${RoleTypeName.PRODUCTION_ENGINEER}`, META_PROD_ENGR_TECHNICAL_INTERVIEWS],
    [`${CompanyName.AMAZON}_${RoleTypeName.SOFTWARE_ENGINEER}`, AMAZON_SOFTWARE_ENGINEER_TECHNICAL_INTERVIEWS],
    //...other key-interviews pairs... FOR OTHER company-roleType combinations
]);




function getAvailableInterviews(company: string, roleType: string, interviewType: string, interviewMode: InterviewMode | null): Interview[] {
    // NEBUG: TODO replace with backend call
    if (interviewMode === InterviewMode.INTERVIEW && interviewType === "Technical") { //NITO: TODO: use enums
        const key = `${company}_${roleType}`;
        return pseudoTechnicalInterviewsDatabaseTableMap.get(key) || [];

    } else {
        console.warn(`Combination of Interview mode ${interviewMode} and Interview type ${interviewType} is not supported yet`);
        return [];
    }
}



const InterviewSelectionPage: React.FC = () => {
    const navigate = useNavigate();
    const { userId } = useContext(StateAccessor);
    // State for selected filters
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [selectedRoleType, setSelectedRoleType] = useState<RoleType | null>(null);
    const [selectedInterviewType, setSelectedInterviewType] = useState<InterviewType | null>(null);
    const [selectedInterviewMode] = useState<InterviewMode>(InterviewMode.INTERVIEW);
    const [isInterviewSelectionModalOpen, setIsInterviewSelectionModalOpen] = useState(false);
    const [selectedInterview, setSelectedInterview] = useState<Interview | null>(null);
    const [selectedInterviewCard, setSelectedInterviewCard] = useState<InterviewCard | null>(null);
    const [availableInterviews, setAvailableInterviews] = useState<Interview[]>([]);

    const allInterviewCards: InterviewCard[] = [ // nebug: TODO: generate this from the enums, and avoid duplicating urls and hardcoding
        {
            id: 100,
            company: CompanyName.GENERIC_TECH_COMPANY,
            roleType: RoleTypeName.SOFTWARE_ENGINEER,
            level: '',
            interviewType: InterviewType.TECHNICAL,
            imageUrl: genericTechCompanyLogo,
            isAvailable: true,
        },
        // {
        //     id: 200,
        //     company: CompanyName.GENERIC_TECH_COMPANY,
        //     roleType: RoleTypeName.SOFTWARE_ENGINEER,
        //     level: 'Senior',
        //     interviewType: InterviewType.TECHNICAL,
        //     imageUrl: genericTechCompanyLogo,
        //     isAvailable: false,
        // },
        {
            id: 300,
            company: CompanyName.GOOGLE,
            roleType: RoleTypeName.SOFTWARE_ENGINEER,
            level: '',
            interviewType: InterviewType.TECHNICAL,
            imageUrl: googleLogo,
            isAvailable: true,
            isExperimental: true,
        },
        // {
        //     id: ,
        //     company: CompanyName.GOOGLE,
        //     roleType: ....,
        //     level: '',
        //     interviewType: 'Behavioral',
        //     imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk4fzsbwnDUgqvGyCfYEdWOuXTS0bZWtgMIg&usqp=CAU',
        // },
        // {
        //     id: ,
        //     company: CompanyName.META,
        //     roleType: ...
        //     level: '',
        //     interviewType: 'System Design',
        //     imageUrl: 'https://logos-world.net/wp-content/uploads/2021/10/Meta-facebook-New-Logo.png',
        // },
        {
            id: 400,
            company: CompanyName.META,
            roleType: RoleTypeName.SOFTWARE_ENGINEER,
            level: '',
            interviewType: InterviewType.TECHNICAL,
            imageUrl: metaLogo,
            isAvailable: true,
            isExperimental: true,
        },
        {
            id: 410,
            company: CompanyName.META,
            roleType: RoleTypeName.PRODUCTION_ENGINEER,
            level: '',
            interviewType: InterviewType.TECHNICAL,
            imageUrl: metaLogo,
            isAvailable: true,
            isExperimental: true,
        },

        //Amazon
        {
            id: 500,
            company: CompanyName.AMAZON,
            roleType: RoleTypeName.SOFTWARE_ENGINEER,
            level: '',
            interviewType: InterviewType.TECHNICAL,
            imageUrl: amazonLogo,
            isAvailable: true,
            isExperimental: true,
        },
        {
            id: 600,
            company: CompanyName.OPENAI,
            roleType: RoleTypeName.SOFTWARE_ENGINEER,
            level: '',
            interviewType: InterviewType.TECHNICAL,
            imageUrl: openAILogo,
            isAvailable: false,
        },
        // Add more interview cards as needed
    ];
    // Filtered interview cards
    // Initialize filteredInterviewCards with allInterviewCards data
    const [filteredInterviewCards, setFilteredInterviewCards] = useState<any[]>(allInterviewCards);
    // State for pagination
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentCards, setCurrentCards] = useState<any[]>([]);
    const cardsPerPage = 25;

    const filterInterviewCards = () => {
        // Replace the following array with your actual interview cards data


        const filteredCards = allInterviewCards.filter((card) =>
            (!selectedCompany || card.company === selectedCompany.name) &&
            (!selectedRoleType || card.roleType === selectedRoleType.name) &&
            (!selectedInterviewType || card.interviewType === selectedInterviewType)
        );
        setFilteredInterviewCards(filteredCards);
    };


    // Function to handle pagination
    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    // Event handlers for filter changes
    const handleCompanyChange = (value: string) => {
        const company = companies.find((c) => c.name === value);
        if (company) {
            handleCompanySelection(company);
        } else {
            setSelectedCompany(null);
            setSelectedRoleType(null);
            setSelectedInterviewType(null);
        }
    };

    const handleRoleTypeChange = (value: string) => {
        const roleType = selectedCompany?.roleTypes.find((r) => r.name === value) || null;
        handleRoleTypeSelection(roleType);
    };


    const handleInterviewTypeChange = (value: string) => {
        handleInterviewTypeSelection(value as InterviewType);
    };

    const handleCompanySelection = (company: Company) => {
        setSelectedCompany(company);
        setSelectedRoleType(null);
        setSelectedInterviewType(null);
    };

    const handleRoleTypeSelection = (roleType: RoleType | null) => {
        setSelectedRoleType(roleType);
        setSelectedInterviewType(null);
    };

    const handleInterviewTypeSelection = (interviewType: InterviewType) => {
        setSelectedInterviewType(interviewType);
    };

    //suppress error: Parameter 'interviewCard' implicitly has an 'any' type.ts(7006)
    //@ts-ignore //NEBUG: TODO: ADDRESS THIS PROPERLY
    const handleInterviewCardClick = (interviewCard: InterviewCard) => {
        setSelectedInterviewCard(interviewCard);
    };

    const handleInterviewSelection = (interview: Interview) => {
        setSelectedInterview(interview);
        setIsInterviewSelectionModalOpen(false);
    };

    const handleInterviewSelectionModalClose = () => {
        setIsInterviewSelectionModalOpen(false);
        setSelectedInterviewCard(null);
        setAvailableInterviews([]);
    };

    useEffect(() => {
        // Get the current cards to display based on pagination
        const indexOfLastCard = currentPage * cardsPerPage;
        const indexOfFirstCard = indexOfLastCard - cardsPerPage;
        setCurrentCards(filteredInterviewCards.slice(indexOfFirstCard, indexOfLastCard));
    }, [filteredInterviewCards, currentPage]);

    useEffect(() => {
        filterInterviewCards();
    }, [selectedCompany, selectedRoleType, selectedInterviewType]);

    /*
    create a useEffect hook such that when selectedInterviewCard changes we call getAvailableInterviews 
    */
    useEffect(() => {
        if (selectedInterviewCard) {

            setAvailableInterviews(
                getAvailableInterviews(
                    selectedInterviewCard.company,
                    selectedInterviewCard.roleType,
                    selectedInterviewCard.interviewType,
                    selectedInterviewMode)
            );
            setIsInterviewSelectionModalOpen(true);

        }
    }, [selectedInterviewCard]);

    useEffect(() => {
        if (selectedInterview) {
            navigate(AI_INTERVIEW_PAGE_PATH, {
                state: {
                    interviewMode: selectedInterviewMode,
                    interview: selectedInterview,
                },
            });
        }
    }, [selectedInterview]);
    return (
        <div className='interview-selection-page-container'>
        {/* <Sidebar enableMinification={true} userId={userId}/> */}
            <h1 className="mode-title2">AI mock interviews based on real interview questions</h1>
            <div className="mock-interview-page-header">
                <span className="mode-subtitle">Discover your interview weaknesses now, not when it counts!</span>
            </div>



            {
                selectedInterviewMode && (
                    <div className="interview-selection-container">
                        {/* Filter components */}
                        <div className="filter-container">
                            {/* Company filter */}
                            <SelectFilter
                                //@ts-ignore //NEBUG: TODO: ADDRESS THIS PROPERLY
                                value={selectedCompany?.name}
                                options={companies.map((company) => ({
                                    value: company.name,
                                    label: company.name,
                                }))}
                                placeholder="Select Company"
                                onChange={handleCompanyChange}
                            />
                            {/* RoleType filter */}
                            <SelectFilter
                                //@ts-ignore //NEBUG: TODO: ADDRESS THIS PROPERLY
                                value={selectedRoleType?.name}
                                options={selectedCompany?.roleTypes.map((roleType) => ({
                                    value: roleType.name,
                                    label: roleType.name,
                                })) || []}
                                placeholder="Select Role Type"
                                onChange={handleRoleTypeChange}
                                disabled={!selectedCompany}
                            />

                            {/* InterviewType filter */}
                            <SelectFilter
                                //@ts-ignore //NEBUG: TODO: ADDRESS THIS PROPERLY
                                value={selectedInterviewType}
                                options={Object.values(InterviewType).map((interviewType) => ({
                                    value: interviewType,
                                    label: interviewType,
                                }))}
                                placeholder="Select Interview Type"
                                onChange={handleInterviewTypeChange}
                                disabled={!selectedCompany || !selectedRoleType}
                            />
                        </div>


                        <p>Select a company to reveal interviews</p>

                        <div className="interview-cards-container">
                            {currentCards.map((interviewCard) => (
                                <div
                                    key={interviewCard.id}
                                    className={`interview-card ${!interviewCard.isAvailable ? 'disabled' : 'enabled'}`}
                                    onClick={interviewCard.isAvailable ? () => handleInterviewCardClick(interviewCard) : () => { }}
                                >
                                    {!interviewCard.isAvailable && <span className="comingSoonTag">Coming Soon</span>}
                                    {interviewCard.isAvailable && (interviewCard.isExperimental ?? false) && (<span className="experimentalTag">Experimental</span>)}
                                    <img
                                        src={interviewCard.imageUrl}
                                        alt={interviewCard.company}
                                        title={interviewCard.company}
                                    />
                                    <h3>{interviewCard.company}</h3>
                                    <h4>{interviewCard.level ? `${interviewCard.level} ${interviewCard.roleType}` : interviewCard.roleType}</h4>
                                    <p>{interviewCard.interviewType} Interview</p>
                                </div>
                            ))}
                        </div>

                        <div className="pagination">
                            {Array(Math.ceil(filteredInterviewCards.length / cardsPerPage))
                                .fill(0)
                                .map((_, index) => (
                                    <button
                                        key={index}
                                        className={`page-number ${currentPage === index + 1 ? 'active' : ''}`}
                                        onClick={() => handlePageChange(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                        </div>
                    </div>
                )
            }



            {
                isInterviewSelectionModalOpen && (
                    <InterviewSelectionModal
                        availableInterviews={availableInterviews}
                        onClose={() => handleInterviewSelectionModalClose()}
                        onInterviewClick={handleInterviewSelection}
                    />
                )
            }

        </div >
    );
};
export default InterviewSelectionPage;

