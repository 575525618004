export enum UserSubscriptionTier {
    PENDING = "PENDING", // default value that can be used, until it has been determined what the user's subscription tier is
    FREE = "FREE",
    PREMIUM = "PREMIUM",
    ADMIN = "ADMIN",
  }
  
  export interface UserSubscription {
    displayName: string | null;
    userEmail: string;
    subscriptionTier: UserSubscriptionTier;
    subscriptionExpiry: Date | null;
  }
  