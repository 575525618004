// Modal.tsx
import React from 'react';
import '../css/Modal.css';
import ErrorBoundary from './ErrorBoundary';

interface ModalProps {
  content: React.ReactNode;
  closeDisabled?: boolean;
  onClose: () => void;
  backgroundColor?: string;
  padding?: string; // Add padding prop
}

const Modal: React.FC<ModalProps> = ({ content, closeDisabled = false, onClose, backgroundColor = 'white', padding = '20px' }) => {
  return (
    <div className="modal-container" onClick={onClose}>
      <div className="modal" onClick={(event) => event.stopPropagation()} style={{ backgroundColor, padding }}>
        {!closeDisabled && (
          <button className="modal-close-button" onClick={onClose}>
            X
          </button>
        )}
        <div className="modal-content">
          <ErrorBoundary>
            {content}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Modal;
