import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import "../css/PremiumContentAccessDeniedMessage.css";
import UserAccessManager from "./UserAccessManager";

const PremiumContentAccessDeniedMessage: React.FC = () => {
  const [triggerAccessFlow, setTriggerAccessFlow] = useState(false);
  const [resetFlowKey, setResetFlowKey] = useState(0); // Unique key for resetting the flow

  const handleTryPremiumClick = () => {
    // Trigger the premium access flow and re-mount UserAccessManager
    setTriggerAccessFlow(true);
    setResetFlowKey((prev) => prev + 1);
  };

  const handleAccessGranted = () => {
    // Callback when access is successfully granted
    console.log("Access granted to premium content!");
  };

  const handleAccessManagerClose = () => {
    // Reset trigger to allow the premium flow to be retried
    setTriggerAccessFlow(false);
  };

  return (
    <>
      <UserAccessManager
        key={resetFlowKey} // Force re-mounting to reset state
        triggerAccessFlow={triggerAccessFlow}
        onAccessGranted={handleAccessGranted}
        resetAccessFlow={handleAccessManagerClose}
      />

      <div className="premium-content-access-denied-message">
        <Typography variant="body1" gutterBottom>
          🚫 You need a premium subscription to access this content
        </Typography>

        <Button
          color="secondary"
          className="premium-subscribe-button"
          variant="contained"
          title="Sign up for a premium subscription to access this content"
          onClick={handleTryPremiumClick}
          style={{
            color: "black",
            textTransform: "none",
          }}
        >
          <AutoAwesomeIcon style={{ marginRight: "5px" }} />
          Try Coditioning Premium
        </Button>
      </div>
    </>
  );
};

export default PremiumContentAccessDeniedMessage;
