/*
interview scripts by difficulty and requiredTime
*/

import { Language } from "@mui/icons-material";
import { InterviewDifficulty, InterviewStage, ProgrammingLanguage } from "../pages/Settings";
import { ChallengeType, CompanyName, InterviewDurations, InterviewType, Phase, ReferenceSolution, RoleTypeName } from "./Constants";


export class InterviewScript {
    public restrictedProgrammingLanguage?: ProgrammingLanguage;

    constructor(
        public interviewId: number,
        public interviewName: string,
        public difficulty: InterviewDifficulty,
        public requiredTimeInMinutes: number,
        public interviewStage: InterviewStage,
        public interviewType: InterviewType,
        public company: CompanyName,
        public roleType: RoleTypeName,
        public phases: Phase[],
        restrictedProgrammingLanguage?: ProgrammingLanguage
    ) {
        this.restrictedProgrammingLanguage = restrictedProgrammingLanguage;
    }

    static fromObject(obj: Partial<InterviewScript>): InterviewScript {
        const {
            interviewId,
            interviewName,
            difficulty,
            requiredTimeInMinutes,
            interviewStage,
            interviewType,
            company,
            roleType,
            phases,
            restrictedProgrammingLanguage
        } = obj;

        if (
            interviewId === undefined ||
            interviewName === undefined ||
            difficulty === undefined ||
            requiredTimeInMinutes === undefined ||
            interviewStage === undefined ||
            interviewType === undefined ||
            company === undefined ||
            roleType === undefined ||
            phases === undefined
        ) {
            throw new Error("Missing properties in object");
        }

        return new InterviewScript(
            interviewId,
            interviewName,
            difficulty,
            requiredTimeInMinutes,
            interviewStage,
            interviewType,
            company,
            roleType,
            phases,
            restrictedProgrammingLanguage
        );
    }
}




const INTERVIEW_PHASES_Straight_Forward_20_MINUTES: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
        challenge: "Explain the concept of Big O notation in the context of algorithm analysis.",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [
            {
                hint: "How do you determine the performance of an algorithm independent of the hardware platform it is running on?",
            },
            {
                hint: "Have you come across the terms 'time complexity' and 'space complexity' before?",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: "Big O notation is used to describe how the runtime of an algorithm grows as the input size increases. It allows us to talk about the 'order of growth' of an algorithm's time complexity, focusing on the operations that dominate the cost as the input size gets larger. For example, if an algorithm's time complexity is O(n), it means the time taken grows linearly with the input size, while O(n^2) represents quadratic growth.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
        required: false,
        acceptableAnswer: "Big O notation is used to describe how an algorithm scales as input size varies.",
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 15 * 60 * 1000, // 15 minutes
        challenge: "Write a function to reverse a string.",
        challengeType: ChallengeType.CODING,
        hints: [
            {
                hint: "You can use the split(), reverse(), and join() methods.",
            },
            {
                hint: "Consider edge cases, like empty strings or special characters.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: `
                  function reverseString(inputString: string): string {
                      // Convert string to an array of characters
                      const charArray: string[] = inputString.split('');
                      
                      // Reverse the array
                      charArray.reverse();
                      
                      // Join the reversed characters back into a string
                      const reversedString: string = charArray.join('');
                      
                      return reversedString;
                  }
                  `,
                referenceSolutionIsCode: true,
                referenceSolutionExplanationQuick: "This TypeScript function splits the input string into an array of characters using the split() method, then reverses the array using the reverse() method, and finally joins the reversed characters back into a string using the join() method.",
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.JavaScript,
            },
        ],
        required: true,
        acceptableAnswer: `this is a valid response in typescript:
          function reverseString(inputString: string): string {
              // Convert string to an array of characters
              const charArray: string[] = inputString.split('');
              
              // Reverse the array
              charArray.reverse();
              
              // Join the reversed characters back into a string
              const reversedString: string = charArray.join('');
              
              return reversedString;
          }
          `,
    },
];



const KNOWLEDGE_QUESTION_DEDUCE_PATTERN_FROM_BINARY_REPRESENTATION = "Consider non-zero positive integers that are, one less than a power of 2. What pattern do these numbers exhibit when represented in binary? Examples of powers of 2 are 1, 2, 4, 8, 16, 32, etc. Examples of non-zero positive integers that are one less than a power of 2 are 1, 3, 7, 15, 31, etc.";
const KNOWLEDGE_QUESTION_DEDUCE_PATTERN_FROM_BINARY_REPRESENTATION_SAMPLE_ANSWER = "The pattern is that in binary form, if we ignore the zeros, then it is a sequence of '1's e.g. 1, 11, 111, 1111 etc."
const INTERVIEW_PHASES_Straight_Forward_60_MINUTES: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes
        challenge: KNOWLEDGE_QUESTION_DEDUCE_PATTERN_FROM_BINARY_REPRESENTATION,
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [
            {
                hint: "Think about how these numbers (1, 3, 7, etc.) look in binary representation.",
            },
            {
                hint: "Consider the effect of subtracting 1 from a power of 2 in binary representation.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: KNOWLEDGE_QUESTION_DEDUCE_PATTERN_FROM_BINARY_REPRESENTATION_SAMPLE_ANSWER,
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
        required: false,
        acceptableAnswer: KNOWLEDGE_QUESTION_DEDUCE_PATTERN_FROM_BINARY_REPRESENTATION_SAMPLE_ANSWER,
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 40 * 60 * 1000, // 40 minutes
        challenge: "Given a 32-character string representing a 32-bit non-negative integer that is guaranteed to be one less than a power of 2 (e.g. '00000000000000000000000000000001', '00000000000000000000000000000011', '00000000000000000000000000000111', etc.), write a function to find the index of the first bit set to 1 (from left to right) without using any built-in functions that directly find this answer. Devise an algorithm with a time complexity of O(log n) or better.",
        challengeType: ChallengeType.CODING,
        hints: [
            {
                hint: "Consider how the pattern of the binary representation of these numbers can help you find the first bit set to 1.",
            },
            {
                hint: "Think of a way to split the string in half repeatedly to find the answer more quickly.",
            },
        ],
        referenceSolutions: [
            new ReferenceSolution({
                referenceSolution: `
        def find_first_set_bit(binary_str):
            left, right = 0, len(binary_str) - 1
            while left < right:
                mid = left + (right - left) // 2
                if binary_str[mid] == '0':
                    left = mid + 1
                else:
                    right = mid
            return left if binary_str[left] == '1' else -1
        `,
                referenceSolutionIsCode: true,
                referenceSolutionExplanation: `This Python function uses binary search to find the first set bit. It starts from both ends of the string and continues to cut the search space in half by adjusting the left and right pointers based on whether the midpoint bit is set or not.`,
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
            }),
        ],
        required: true,
        acceptableAnswer: `A binary search based solution e.g. for python:

        \`\`\`python
        def find_first_set_bit(binary_str):
            left, right = 0, len(binary_str) - 1
            while left < right:
                mid = left + (right - left) // 2
                if binary_str[mid] == '0':
                    left = mid + 1
                else:
                    right = mid
            return left if binary_str[left] == '1' else -1
        \`\`\`
        `,
    },
    {
        id: 3,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes
        challenge: "What is the asymptotic runtime complexity of the function you wrote in the previous phase? Can you explain why it is what you believe it to be?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [
            {
                hint: "Consider how the search space changes with each iteration in your function.",
            },
            {
                hint: "Think about how many times the while loop in your function executes as a function of the size of the binary string.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: `The time complexity of the function is O(log n). This is because the function uses a binary search algorithm. In this case, the binary search algorithm operates by continuously dividing the search space (i.e., the range of indices of the binary string) in half at each step of the algorithm.

        Initially, the search space encompasses the entire binary string (from index 0 to index n-1). If the middle character of the current search space is '0', we know that the first bit set to 1 must be in the second half of the search space. So, we update the start of the search space to be one index after the middle. Conversely, if the middle character is '1', we know that the first bit set to 1 is in the first half of the search space or at the middle itself. Hence, we update the end of the search space to be the middle index.
        
        At each step, we effectively cut the search space in half. This division by 2 at each step is what leads to a logarithmic time complexity of O(log n).
        
        To be precise, after k steps of the binary search, the size of the search space is reduced to approximately n/2^k. Thus, the number of steps (or iterations) until the search space becomes 1 is approximately log2(n), which gives us the logarithmic time complexity of O(log n).`,
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
        required: true,
        acceptableAnswer: `If a binary search based approach that is O(log n) is used, then answer could be the time complexity of the function is O(log n). This is because the function uses a binary search algorithm. In this case, the binary search algorithm operates by continuously dividing the search space (i.e., the range of indices of the binary string) in half at each step of the algorithm.

        Initially, the search space encompasses the entire binary string (from index 0 to index n-1). If the middle character of the current search space is '0', we know that the first bit set to 1 must be in the second half of the search space. So, we update the start of the search space to be one index after the middle. Conversely, if the middle character is '1', we know that the first bit set to 1 is in the first half of the search space or at the middle itself. Hence, we update the end of the search space to be the middle index.
        
        At each step, we effectively cut the search space in half. This division by 2 at each step is what leads to a logarithmic time complexity of O(log n).
        
        To be precise, after k steps of the binary search, the size of the search space is reduced to approximately n/2^k. Thus, the number of steps (or iterations) until the search space becomes 1 is approximately log2(n), which gives us the logarithmic time complexity of O(log n).`,
    },
];



const INTERVIEW_PHASES_Moderately_Challenging_20_MINUTES: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
        challenge: "What is a Segmentation Fault? Can you give an example of a situation that might cause one?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [
            {
                hint: "Do you know how the operating system manages memory?",
            },
            {
                hint: "Think about certain programming mistakes that might lead to a segmentation fault.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: `A segmentation fault is a specific kind of error caused by accessing memory that 'does not belong to you.' It's a mechanism that prevents you from corrupting the memory and introducing hard-to-debug memory bugs. Whenever you get a segmentation fault, you know you are doing something wrong with memory — such as accessing a variable that has already been freed, writing to a read-only portion of memory, or overstepping the bounds of an array.

                Example in C:
                \`\`\`c
                int main() {
                    int *p = NULL;
                    *p = 1;
                    return 0;
                }
                \`\`\`
                This code will cause a segmentation fault, because it tries to write to a null pointer.`,
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
        required: false,
        acceptableAnswer: `A segmentation fault is a specific kind of error caused by accessing memory that 'does not belong to you.' Example triggers of segmentation faults, include: accessing a variable that has already been freed or writing to a read-only portion of memory. The exact cause can be tricky to identify, but it's typically tied to misuse of pointers, array indices going out of bounds, or incorrect use of the memory management functions.`,
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 15 * 60 * 1000, // 15 minutes
        challenge: "Write a function that checks if a given string is a palindrome.",
        challengeType: ChallengeType.CODING,
        hints: [
            {
                hint: "A palindrome is a string that reads the same forwards and backwards.",
            },
            {
                hint: "Consider how you could compare characters from the beginning and end of the string.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: 
`
def isPalindrome(s):
    return s == s[::-1]
`,
                referenceSolutionIsCode: true,
                referenceSolutionExplanationQuick: "This Python function uses a simple trick with slicing. The slicing operation s[::-1] creates a new string which is a reversed copy of the input string s. Then it checks whether this reversed string is equal to the original, returning True for a palindrome and False otherwise.",
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
            },
        ],
        required: true,
        acceptableAnswer: `This is a valid response in Python:
  
        def isPalindrome(s):
            return s == s[::-1]
        `,
    },
];



const KNOWLEDGE_QUESTION_DIFFERENCE_BETWEEN_UDP_AND_TCP = "What's a difference between the TCP and UDP protocols, and when would you choose one over the other?";
const KNOWLEDGE_QUESTION_DIFFERENCE_BETWEEN_TCP_AND_UDP_SAMPLE_ANSWER = `TCP is a connection-oriented protocol, whereas UDP is a connectionless protocol. I would use TCP when reliability is important, such as when sending a file or an email. I would use UDP when speed is important, such as when streaming a video or audio file.`;
const INTERVIEW_PHASES_Moderately_Challenging_60_MINUTES: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes
        challenge: "What is a networking protocol, and can you give an example of one with which you're familiar?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [
            {
                hint: "Think about both internet and transport layer protocols.",
            },
            {
                hint: "You might consider discussing not just TCP/IP, but also HTTP, HTTPS, FTP, etc.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: "A networking protocol is a set of rules that governs the communication between devices over a network. Examples include: TCP/IP, HTTP, HTTPS, FTP, UDP, ICMP, DNS, SMTP, POP3, IMAP, etc.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
        required: false,
        acceptableAnswer: "A networking protocol is a set of rules that governs the communication between devices over a network. Examples include: TCP/IP, HTTP, HTTPS, FTP, UDP, ICMP, DNS, SMTP, POP3, IMAP, etc.",
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes
        challenge: KNOWLEDGE_QUESTION_DIFFERENCE_BETWEEN_UDP_AND_TCP,
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [
            {
                hint: "Consider the key characteristics and uses of each protocol.",
            },
            {
                hint: "Think about the reliability, ordering, and speed of TCP and UDP.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: KNOWLEDGE_QUESTION_DIFFERENCE_BETWEEN_TCP_AND_UDP_SAMPLE_ANSWER,
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
        required: false,
        acceptableAnswer: KNOWLEDGE_QUESTION_DIFFERENCE_BETWEEN_TCP_AND_UDP_SAMPLE_ANSWER,
    },
    {
        id: 3,
        phaseTimeInMilliSeconds: 40 * 60 * 1000, // 40 minutes
        challenge: "You throw six dice, each having values from 1 to 6 on their faces. An array, diceValues, represents the values on the thrown dice. You aim to reach a target sum by adjusting the values on as few dice as possible. Create a function that takes diceValues and a target sum, and returns the minimum number of dice values needing adjustment to reach the target sum. Return -1 if the target sum can't be reached following the rules.",
        challengeType: ChallengeType.CODING,
        hints: [
            {
                hint: "Start by sorting the dice values in ascending order.",
            },
            {
                hint: "Remember to consider the maximum and minimum possible sum of dice values.",
            },
            {
                hint: "Try using a greedy algorithm approach to adjust the dice values.",
            },
        ],
        referenceSolutions: [
            {
                referenceSolution: `
                def min_dice_changes(diceValues, target):
                    diceValues.sort()
                    total = sum(diceValues)
                    diff = target - total
                    num_dice_changes = 0
                    if diff < 0 or target > 6*len(diceValues):
                        return -1
                    if diff == 0:
                        return 0
                    for i in range(len(diceValues)):
                        if diff > 0:
                            change = min(diff, 6-diceValues[i])
                            diff -= change
                            if change > 0:
                                num_dice_changes += 1
                    return num_dice_changes if diff == 0 else -1
                `,
                referenceSolutionIsCode: true,
                referenceSolutionExplanationQuick: "This Python function first sorts the diceValues array and calculates the difference between the target and the sum of diceValues. It then loops over the diceValues array, in each iteration, it adjusts the dice value as much as possible, decreases the difference by the adjusted value, and increments the num_dice_changes by 1 if the adjusted value is greater than 0. In the end, it checks if the difference is 0, if so, it returns num_dice_changes, otherwise, it returns -1.",
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
            },
        ],
        required: true,
        acceptableAnswer: `this is a valid response in python:
          def min_dice_changes(diceValues, target):
              diceValues.sort()
              total = sum(diceValues)
              diff = target - total
              num_dice_changes = 0
              if diff < 0 or target > 6*len(diceValues):
                  return -1
              if diff == 0:
                  return 0
              for i in range(len(diceValues)):
                  if diff > 0:
                      change = min(diff, 6-diceValues[i])
                      diff -= change
                      if change > 0:
                          num_dice_changes += 1
              return num_dice_changes if diff == 0 else -1
          `,
    },
];


const INTERVIEW_PHASES_PYTHON_DEVELOPER_Moderately_Challenging_60_MINUTES: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes
        challenge: "Can you explain how Python's (the CPython implementation to be specific) memory management works, particularly how objects are typically allocated in memory and deallocated?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "In CPython, all Python objects are allocated on the heap. Python manages memory using a system of reference counting. Each object has a count of references to it. When this count drops to zero, the memory is deallocated. Python also has a garbage collector to handle circular references.",
        referenceSolutions: [
            {
                referenceSolution: "In CPython, all Python objects are allocated on the heap. Python manages memory using a system of reference counting. Each object has a count of references to it. When this count drops to zero, the memory is deallocated. Python also has a garbage collector to handle circular references.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
    },

    {
        id: 2,
        phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
        challenge: "I have pasted some code in the text editor. Can you refactor it so that memory is used more efficiently? Your result should be a single-line pythonic improvement.",
        codeStub: {
            value: "my_list = [i**2 for i in range(1000000)]",
            isCode: true,
        },
        challengeType: ChallengeType.CODING,
        hints: [],
        required: false,
        acceptableAnswer: "The code could be improved by using a generator expression instead of a list comprehension. This would save memory as the generator only generates values on-the-fly and doesn't store them in memory. The modified code could look like this: `my_list = (i**2 for i in range(1000000))`",
        referenceSolutions: [
            {
                referenceSolution: "my_list = (i**2 for i in range(1000000))",
                referenceSolutionIsCode: true,
                referenceSolutionExplanationQuick: "This solution uses a generator expression instead of a list comprehension. It has similar syntax to list comprehensions, but it returns a generator instead of a list. Generators are lazily evaluated, meaning values are generated on the fly without storing all the values in memory, which can save a lot of memory for large datasets. Here, instead of creating a list of one million elements, the generator expression will yield the squared values one at a time when needed, saving memory.",
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
            },
        ],
    },
    

    {
        id: 3,
        phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
        challenge: "Can you explain the difference between a generator, generator function, and a generator expression in Python?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "A generator in Python is a type of iterator. A generator function is a function that uses the yield keyword to produce values, and when called, it returns a generator. A generator expression, on the other hand, is a high-performance, memory–efficient generalization of list comprehensions and generators.",
        referenceSolutions: [
            {
                referenceSolution: "In Python, a generator is a type of iterator, meaning it's a data type that produces a sequence of results instead of a single value. A generator function is a special type of function that returns a generator object. It looks like a normal function but instead of the return statement, it uses the 'yield' keyword to produce a series of values. This allows the function to be paused and resumed, maintaining its internal state across iterations. Lastly, a generator expression is a compact way to create a generator, and can be thought of as a high-performance, memory-efficient alternative to a list comprehension. For example, '(x**2 for x in range(10))' is a generator expression that yields the squares of numbers from 0 to 9, on demand.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
    },
    
    {
        id: 4,
        phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
        challenge: `I have pasted a Python code snippet in the code editor. What are the final values of my_list and my_string and why?`,
        codeStub:{
            value:
    `def change_list(a_list):
        a_list.append('Python')
    def change_string(a_string):
        a_string += 'Python'
    my_list = ['I', 'love']
    my_string = 'I love '
    change_list(my_list)
    change_string(my_string)
    print(my_list)
    print(my_string)
    `,
            isCode: true,
        },
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "The final value of my_list is ['I', 'love', 'Python'] and my_string is 'I love '. This is because lists are mutable and strings are immutable in Python. When a mutable object (like a list) is passed to a function, changes made to it inside the function are visible outside. But when an immutable object (like a string) is passed, changes made to it inside the function won't affect the original object.",
        referenceSolutions: [
            {
                referenceSolution: `['I', 'love', 'Python']`,
                referenceSolutionIsCode: true,
                referenceSolutionExplanationQuick: "The final value of my_list is ['I', 'love', 'Python'] and my_string remains 'I love '. This happens due to the difference in mutability between lists and strings in Python. Lists are mutable, meaning their elements can be changed in-place. So, when we pass my_list to the function change_list, the append operation affects the original list, adding 'Python' to it. On the other hand, strings in Python are immutable. This means that their value cannot be changed once they have been assigned. When we attempt to add 'Python' to my_string in the function change_string, a new string 'I love Python' is created in the function scope, but this does not change the value of the original my_string.",
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
            },
        ],
    },
    
    // NITO NEBUG: fix the corner cases in the solution, and update solutions
    {
        id: 5,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes
        challenge: `I have pasted a Python code snippet in the code editor. Can you explain the flow of this code? Specifically, what will happen when the await asyncio.sleep(delay) statement is executed?`,
        codeStub: {
            value:`
import asyncio
async def greet(name, delay):
    print(f'Starting to greet: {name}')
    await asyncio.sleep(delay)
    print(f'Finished greeting: {name}')
async def main():
    await asyncio.gather(
        greet('Alice', 2),
        greet('Bob', 1),
    )
asyncio.run(main())
    `,
            isCode: true,
        },
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "The greet function is an async function (coroutine). When await asyncio.sleep(delay) is called, the function execution is suspended, freeing up the event loop to execute other tasks. In this case, while 'Alice' is waiting for 2 seconds, the greeting process for 'Bob' starts and finishes, as his delay is shorter. asyncio.gather is used to run multiple coroutines concurrently.",
        referenceSolutions: [
            {
                referenceSolution: "The provided code uses Python's asyncio library to perform concurrent tasks. There are two async functions defined: greet and main. greet simulates a greeting process that takes some time (delay) to finish, which is simulated by the asyncio.sleep(delay) statement. When the await asyncio.sleep(delay) statement is encountered, the execution of the greet function is paused, freeing up the event loop to execute other tasks. In this case, it starts executing the other greet function (for 'Bob') in the asyncio.gather call. This enables the execution of multiple tasks concurrently. The asyncio.gather function is a way to launch multiple coroutines and wait for them to complete. The overall execution order would be: Start greeting Alice, start greeting Bob, finish greeting Bob, and then finish greeting Alice.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
    },
    
    {
        /*
        Python Test cases:
        def test_find_pair():
            # Test case 1: Single valid pair exists
            players1 = {"player1": 5, "player2": 3, "player3": 7, "player4": 2}
            target1 = 10
            assert set(find_pair(players1, target1)) == {"player3", "player2"}, set(find_pair(players1, target1))

            # Test case 2: Multiple valid pairs exist
            players2 = {"player1": 5, "player2": 3, "player3": 7, "player4": 2, "player5": 8, "player6": 2}
            target2 = 10
            assert set(find_pair(players2, target2)) in [ {"player3", "player2"},{"player4", "player5"}, {"player5", "player6"}]

            # Test case 3: No pair exists
            players3 = {"player1": 5, "player2": 3, "player3": 7, "player4": 2}
            target3 = 15
            assert find_pair(players3, target3) == None

            players3b = {"player1": 25, "player2": 3, "player3": 7, "player4": 2}
            target3b = 50
            assert find_pair(players3b, target3b) == None

            # Test case 4: Empty dictionary
            players4 = {}
            target4 = 10
            assert find_pair(players4, target4) == None

            # Test case 5: Single player with the exact target score
            players5 = {"player1": 5}
            target5 = 5
            assert find_pair(players5, target5) == None


            # Test case 7: Large input with a valid pair
            players7 = {"player" + str(i): i for i in range(1, 100001)}
            target7 = 3
            assert set(find_pair(players7, target7)) == {"player1", "player2"}

            # Test case 8: Large input with no pair
            players8 = {"player" + str(i): i for i in range(1, 100001)}
            target8 = 899900000
            assert find_pair(players8, target8) == None

            print("All test cases passed!")

        # Run the test cases
        test_find_pair()

        */

        id: 6,
        phaseTimeInMilliSeconds: 15 * 60 * 1000, // 15 minutes
        challenge: "Imagine you have a dictionary of football players, where each key is the player's name (a string) and the corresponding value is the number of goals they have scored (an integer >= 0). You want to find a pair of different players whose goals scored add up to a certain target total. The function `find_pair` in the code editor is provided for you. Please complete this function according to the defined signature. Return None if no such pair exists.",
        challengeType: ChallengeType.CODING,
        hints: [],
        codeStub: {
            value:`
def find_pair(players: Dict[str, int], target: int) -> Optional[Tuple[str, str]]:
    """
    This function takes in two arguments:
    1. players: A dictionary where each key-value pair represents a player's name and their respective goals scored.
        For example: {"player1": 5, "player2": 3, "player3": 7, ...}
    2. target: An integer representing the target total goals.

    The function should return a tuple of two players (as strings) whose goals add up to the target.
    If no such pair is found, the function should return None.

    Please complete the function below.
    """

    # Write your code here

    pass
            `,
            isCode: true,
        },
        required: true,
        acceptableAnswer: `Here is a sample O(n) python solution: 
        def find_pair(players: Dict[str, int], target: int) -> Optional[Tuple[str, str]]:
            goal_map = {}
            for player, goals in players.items():
                complement = target - goals
                if complement in goal_map and goal_map[complement] != player:
                    return (goal_map[complement], player)
                goal_map[goals] = player
            return None`,
        referenceSolutions: [
            {
                referenceSolutionLabel: "Python Solution",
                referenceSolution: `
from typing import Dict, Optional, Tuple
def find_pair(players: Dict[str, int], target: int) -> Optional[Tuple[str, str]]:
    # Create a dictionary to store the player names by their goals scored
    player_by_goals_scored: dict = {}

    # Iterate through each player and their respective goals
    for player, goals in players.items():
        # Calculate the remaining goals needed to reach the target
        remaining_goals = target - goals

        # Check if the remaining goals exist in the player_by_goals_scored dictionary and is not the same player
        if remaining_goals in player_by_goals_scored: 
            # Found a pair of players whose goals add up to the target, return the pair as a tuple
            return (player_by_goals_scored[remaining_goals], player)

        # Add the current player and their goals to the player_by_goals_scored dictionary
        player_by_goals_scored[goals] = player

    # No valid pair found, return None
    return None
`,
                referenceSolutionIsCode: true,
                referenceSolutionExplanationQuick: "The solution leverages a dictionary to efficiently find a pair of players whose goals scored add up to a target total. By iterating through each player and calculating the remaining goals needed to reach the target, the algorithm checks if the remaining goals exist in the dictionary. If a match is found, a valid pair is returned; otherwise, the player's data is added to the dictionary for subsequent comparisons. This approach ensures an optimal time complexity of O(n), where n is the number of players, and avoids the need for a quadratic solution.",
                referenceSolutionExplanationThoughtProcessHtml:`
<p> Here's a thought process-based explanation of how the solution was arrived at:</p>
<ol>
<li>
    <p>We start with the problem of finding a pair of players whose goals scored add up to a target total. The input consists of a dictionary of players, where each key is the player's name (a string) and the corresponding value is the number of goals they have scored (an integer).</p>
</li>
<li>
    <p>The first consideration is to determine a suitable approach. Initially, a naive O(n^2) solution may come to mind, involving nested loops to check all possible pairs. However, this approach would not be efficient for larger inputs.</p>
</li>
<li>
    <p>To optimize the solution, we explore an alternative approach. We observe that instead of checking all possible pairs, we can leverage the concept of complements. If we subtract the goals scored by each player from the target, we can search for the remaining goals needed in the existing player data.</p>
</li>
<li>
    <p>To implement this approach, we create a dictionary called <code>player_by_goals_scored</code> to store player names based on their goals scored. The goals scored become the keys, and the corresponding player names become the values.</p>
</li>
<li>
    <p>Next, we iterate through each player and their respective goals in the <code>players</code> dictionary.</p>
</li>
<li>
    <p>For each player, we calculate the remaining goals needed to reach the target by subtracting their goals from the target. This value is stored in the variable <code>remaining_goals</code>.</p>
</li>
<li>
    <p>We check if the <code>remaining_goals</code> exist in the <code>player_by_goals_scored</code> dictionary. If it does and the player name associated with the <code>remaining_goals</code> is not the same as the current player being processed, we have found a valid pair whose goals add up to the target. In this case, we return a tuple containing the player names.</p>
</li>
<li>
    <p>If the <code>remaining_goals</code> do not exist in the <code>player_by_goals_scored</code> dictionary, we add the current player and their goals to the dictionary. This allows us to later check if any subsequent player can form a pair with the current player to reach the target.</p>
</li>
<li>
    <p>If no valid pair is found after iterating through all players, we return <code>None</code> to indicate that no pair satisfies the given condition.</p>
</li>
</ol>

<p>By following this thought process, we arrive at an optimized solution that has a time complexity of O(n), where n is the number of players. The use of a dictionary allows for efficient lookup and comparison of player data, eliminating the need for nested loops and ensuring a more scalable solution.</p>
                `,
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
            },
        ],
    },
    
    {
        id: 7,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes
        challenge: "Could you please walk me through the code snippet you just wrote? Make sure your code is still in the editor.",
        challengeType: ChallengeType.EXPLAIN_CODE,
        hints: [],
        required: true,
        acceptableAnswer: "Candidate should be able to explain the flow of their code, why they decided to use the specific data structures and algorithms, and what the time complexity of their solution is.",
        referenceSolutions: [
            {
                referenceSolution: "The solution uses a dictionary in Python (goal_map) to keep track of players and their scores. We start by looping through all players in the given dictionary. For each player, we calculate the complement, which is the difference between the target and the player's score. Then we check if this complement exists in our goal_map. If it exists, that means we have found a pair whose scores add up to the target, so we return a tuple containing the two players' names. If the complement does not exist in our goal_map, we add the current player and their score to the goal_map and proceed with the next player. If no pair is found after checking all players, we return None. The time complexity of this solution is O(n), as we only loop through the players once.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
    },
    //!
    
];


const PASTED_CODE_MESSAGE_PREFIX = "I have pasted a code snippet in the code editor. ";
const PASTED_CODE_PROBLEM_INTERVIEWER_MSG = "I have pasted a problem description in the code editor. Review the problem, and let me know if you need any clarification. ";
const INTERVIEW_PHASES_CPP_DEVELOPER_Moderately_Challenging_60_MINUTES: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: "Given that `i` is an integer, can you explain the difference between the pre-increment (++i) and post-increment (i++) operators in C++?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "++i (pre-increment) increments the value of i and returns the incremented value. i++ (post-increment) increments the value of i, but returns the original value that i held before being incremented. In some cases, pre-increment can be more efficient than post-increment because it doesn't need to create a temporary object to hold the original value.",
        referenceSolutions: [
            {
                referenceSolution: "++i (pre-increment) increments the value of i and returns the incremented value. i++ (post-increment) increments the value of i, but returns the original value that i held before being incremented. In some cases, pre-increment can be more efficient than post-increment because it doesn't need to create a temporary object to hold the original value.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: "What is the difference between const and constexpr in C++?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "Both const and constexpr are used to declare constants in C++. The key difference is that constexpr tells the compiler that the variable can be computed at compile-time. This is useful for performance optimization, especially when used with functions, as it allows for computations to be done at compile time rather than runtime. const, on the other hand, is used when the variable's value is constant and cannot be changed but may not be known until runtime.",
        referenceSolutions: [
            {
                referenceSolution: "Both const and constexpr are used to declare constants in C++. The key difference is that constexpr tells the compiler that the variable can be computed at compile-time. This is useful for performance optimization, especially when used with functions, as it allows for computations to be done at compile time rather than runtime. const, on the other hand, is used when the variable's value is constant and cannot be changed but may not be known until runtime.",
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            }
        ]
    },
    {
        id: 3,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: `${PASTED_CODE_MESSAGE_PREFIX}The message 'constructor called' is not being printed for some reason. Can you fix the issue, only make changes within the main method.?`,
        challengeType: ChallengeType.CODING,
        hints: [],
        required: false,
        codeStub: {
            value: `
#include <iostream> // for std::cout
class A {}; // this is fine
class B
{
    public:
    B(A a) {
        std::cout<<"constructor called"<<std::endl;
    }
};

int main(){
    B b(A());
}
`,
            isCode: true,
        },
referenceSolutions: [
    new ReferenceSolution({ 
    referenceSolution: `
    #include <iostream> // for std::cout
    class A {}; // this is fine
    class B
    {
        public:
        B(A a) {
            std::cout<<"constructor called"<<std::endl;
        }
    };
    
    int main(){
        B b{A()}; // braced initialization added here
    }
    `,
    referenceSolutionIsCode: true,
    referenceSolutionExplanation: `This reference solution addresses the 'most vexing parse' issue in C++. The problem arises when the compiler gets confused between an object and function declaration due to similar syntax. In the corrected code, the object 'b' of class 'B' is created using direct initialization syntax, 'B b{A()}', which is unambiguous. Thus, the 'B' class object gets successfully instantiated by passing an instance of class 'A' to its constructor, resolving the original problem.`,
    referenceSolutionProgrammingLanguage: ProgrammingLanguage.Cpp
  })
],
        acceptableAnswer: "The problem here is due to the \"most vexing parse\", which is a syntactic ambiguity in C++ that arises when an object declaration looks like a function declaration. To fix it, you can use unambiguous initialization like so:\n\nint main(){\n    B b{A()};\n}."
    },
    {
        id: 4,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: `${PASTED_CODE_MESSAGE_PREFIX}Could you review the code and let me know your thoughts? (Do not make any changes to the code)`,
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        codeStub: {
            value:`
class MyClass {
    int* myResource;
public:
    MyClass() {
        myResource = new int[100]; // assume this succeeds
        foo(); // can throw exception
    }
    ~MyClass() {
        delete[] myResource;
    }
};
`,
            isCode: true,
        },
referenceSolutions: [
    new ReferenceSolution({ 
    referenceSolution: `The code defines a C++ class MyClass with a constructor that allocates an array of 100 integers using the new operator and a destructor that deallocates the array using the delete[] operator. However, there is a potential memory leak if the foo() function throws an exception after the memory has been allocated but before the constructor finishes executing. To avoid this, a try-catch block can be added to the constructor to catch any exceptions thrown by foo() and deallocate the memory in the catch block if an exception is caught.`, 
    referenceSolutionIsCode: false,
    referenceSolutionExplanation: null, 
    referenceSolutionProgrammingLanguage: null
  })
],
        acceptableAnswer: "In this code, if an exception is thrown by foo i.e. after myResource is allocated with new, then the destructor will never be called, and a memory leak will occur. It's generally better to use a smart pointer type, such as std::unique_ptr<int[]>, which would automatically deallocate the memory when it goes out of scope, even if an exception is thrown."
    },
    {
        id: 5,
        phaseTimeInMilliSeconds: 15 * 60 * 1000,
        challenge: `Can you fix the code so that the memory leak is eliminated.`,
        challengeType: ChallengeType.CODING,
        hints: [],
        required: false,
        codeStub: {
            value:`
class MyClass {
    int* myResource;
public:
    MyClass() {
        myResource = new int[100]; // assume this succeeds
        foo(); // can throw exception
    }
    ~MyClass() {
        delete[] myResource;
    }
};
`,
            isCode: true,
        },
        referenceSolutions: [
            new ReferenceSolution({
            referenceSolution: `
class MyClass {
    std::unique_ptr<int[]> myResource;
public:
    MyClass() {
        myResource = std::make_unique<int[]>(100);
        foo();
    }
};
`,
            referenceSolutionIsCode: true,
            referenceSolutionExplanation: `The updated code uses a std::unique_ptr to manage the memory allocation instead of using new. The std::unique_ptr is a smart pointer that automatically deallocates the memory when the object goes out of scope, even if an exception is thrown. This ensures that the memory is always deallocated, preventing a memory leak.`,
            referenceSolutionProgrammingLanguage: ProgrammingLanguage.Cpp
        })
    ],

        acceptableAnswer: `One way to handle this problem is to use a smart pointer, such as std::unique_ptr, which would automatically deallocate the memory when it goes out of scope, even if an exception is thrown.
For example
class MyClass {
    std::unique_ptr<int[]> myResource;
public:
    MyClass() {
        myResource = std::make_unique<int[]>(100);
        foo();
    }
};
`
    },
    {
        id: 6,
        phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
        challenge: "Can you explain what the acronym RAII stands for and means in the context of C++?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "RAII stands for Resource Acquisition Is Initialization. It's a common idiom in C++ that involves tying the lifecycle of a resource (such as memory, file handles, network connections, etc.) that must be acquired before use to the lifecycle of an object.",
        referenceSolutions: [
            new ReferenceSolution({ 
            referenceSolution: `RAII stands for Resource Acquisition Is Initialization. It's a common idiom in C++ that involves tying the lifecycle of a resource (such as memory, file handles, network connections, etc.) that must be acquired before use to the lifecycle of an object.`,
            referenceSolutionIsCode: false,
            referenceSolutionExplanation: null, 
            referenceSolutionProgrammingLanguage: null
          })
        ],
    },
    {
        id: 7,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: "Can you describe what stack unwinding is in C++?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "Stack unwinding is the process by which the C++ runtime system cleans up the resources (like memory or system resources) when an exception is thrown. This happens by invoking the destructors for all local objects that were successfully constructed before the exception was thrown, in the reverse order of their construction.",
        referenceSolutions: [
            new ReferenceSolution({ 
            referenceSolution: `Stack unwinding is the process by which the C++ runtime system cleans up the resources (like memory or system resources) when an exception is thrown. This happens by invoking the destructors for all local objects that were successfully constructed before the exception was thrown, in the reverse order of their construction.`,
            referenceSolutionIsCode: false,
            referenceSolutionExplanation: null, 
            referenceSolutionProgrammingLanguage: null
          })
        ],
    },
    {
        id: 8,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: "What happens when an uncaught exception is thrown in a destructor in C++?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "In C++, if a destructor throws an exception and that exception is not caught, the behavior is undefined which often leads to program termination.",
        referenceSolutions: [
            new ReferenceSolution({ 
            referenceSolution: `In C++, if a destructor throws an exception and that exception is not caught, the behavior is undefined which often leads to program termination.`,
            referenceSolutionIsCode: false,
            referenceSolutionExplanation: null, 
            referenceSolutionProgrammingLanguage: null
          })
        ],
    },

    {
        id: 9,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: "What's the key difference between new and malloc, in terms of what they do when invoked in C++?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "malloc is a C library function that allocates a block of memory and returns a pointer to it. It does not call constructors for the allocated memory if it's an object. new is a C++ operator that not only allocates memory, but also constructs objects in the allocated memory by calling the appropriate constructor.",
        referenceSolutions: [
            new ReferenceSolution({ 
            referenceSolution: `malloc is a C library function that allocates a block of memory and returns a pointer to it. It does not call constructors for the allocated memory if it's an object. new is a C++ operator that not only allocates memory, but also constructs objects in the allocated memory by calling the appropriate constructor.`,
            referenceSolutionIsCode: false,
            referenceSolutionExplanation: null, 
            referenceSolutionProgrammingLanguage: null
          })
        ],
    },
    {
        id: 10,
        phaseTimeInMilliSeconds: 5 * 60 * 1000,
        challenge: "What happens when the required amount of memory is not available, in the case of new and malloc?",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: false,
        acceptableAnswer: "If malloc fails to allocate memory, it returns a NULL pointer. On the other hand, new throws an exception of type std::bad_alloc when it fails to allocate memory.",
        referenceSolutions: [
            new ReferenceSolution({ 
            referenceSolution: `If malloc fails to allocate memory, it returns a NULL pointer. On the other hand, new throws an exception of type std::bad_alloc when it fails to allocate memory.`,
            referenceSolutionIsCode: false,
            referenceSolutionExplanation: null, 
            referenceSolutionProgrammingLanguage: null
          })
        ],
    }



];

//META
const META_INTERVIEW_Round_1_35_MINS_MODERATELY_CHALLENGING: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 15 * 60 * 1000, // 15 minutes
        challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}`,
        codeStub: {
            value: `
    Given the root of a binary tree, containing only base 5 digits i.e. 0 to 4

    Each root-to-leaf path in the tree represents a number e.g.
    the root-to-leaf path 1 -> 2 -> 3 represents the number 123 in base 5 which is 38 in decimal.
    This is defined as the root-to-leaf path value.

    Determine the decimal value of the max root-to-leaf value
    `,
            isCode: false,
        },
        challengeType: ChallengeType.CODING,
        hints: [],
        required: true,
        acceptableAnswer: `
        O(n) Python implementations of a DFS or BFS based solution that leverages horner's rule is considered optimal

        ========
        APPROACH 1: DFS
        ========
        from dataclasses import dataclass
        from typing import Optional

        @dataclass
        class TreeNode:
            """
            A node in a binary tree, holding a value and pointers to left and right child nodes.
            """
            val: int
            left: Optional['TreeNode'] = None
            right: Optional['TreeNode'] = None

        def calculate_max_val(current_node, current_sum):
            """
            Recursively calculates the maximum value of all root-to-leaf paths from the current node.

            Args:
                current_node (TreeNode): The current node in the binary tree.
                current_sum (int): The sum of the current path in base 5.

            Returns:
                int: The maximum value of all root-to-leaf paths from this node down.
            """
            if current_node is None:
                # Base case: if the current node is None, return the current sum
                return current_sum

            # Calculate new sum for the current path
            new_sum = current_sum * 5 + current_node.val

            # If it's a leaf node, return the new sum directly
            if current_node.left is None and current_node.right is None:
                return new_sum

            # Recursive case: calculate max value for both subtrees
            left_max = calculate_max_val(current_node.left, new_sum) if current_node.left else current_sum
            right_max = calculate_max_val(current_node.right, new_sum) if current_node.right else current_sum

            # Return the maximum value obtained from either subtree
            return max(left_max, right_max)

        def get_max_root_to_leaf_value(root):
            """
            Determines the maximum decimal value of all root-to-leaf paths in the binary tree.

            Args:
                root (TreeNode): The root of the binary tree.

            Returns:
                int: The maximum decimal value among all root-to-leaf paths.
            """
            if root is None:
                # Handle empty tree case
                return 0
            return calculate_max_val(root, 0)

        # Test cases
        assert get_max_root_to_leaf_value(None) == 0, "Empty tree test failed."
        assert get_max_root_to_leaf_value(TreeNode(3)) == 3, "Single node tree test failed."

        # Tree with multiple nodes
        root = TreeNode(1)
        root.left = TreeNode(2)
        root.right = TreeNode(3)
        assert get_max_root_to_leaf_value(root) == 8, "Multiple node tree test failed."

        # Tree with 3 levels
        root = TreeNode(4)
        root.left = TreeNode(2)
        root.right = TreeNode(0)
        root.left.left = TreeNode(3)
        root.left.right = None
        assert get_max_root_to_leaf_value(root) == 113, "Tree with 3 levels test failed."


        print("All tests pass")

    ========
    APPROACH 2: BFS
    ========
        
    from dataclasses import dataclass
    from collections import deque
    from typing import Optional
    
    @dataclass
    class TreeNode:
        """
        A node in a binary tree, holding a value and pointers to left and right child nodes.
        """
        val: int
        left: Optional['TreeNode'] = None
        right: Optional['TreeNode'] = None
    
    def get_max_root_to_leaf_value_bfs(root):
        """
        Determines the maximum decimal value of all root-to-leaf paths in the binary tree using BFS.
    
        Args:
            root (TreeNode): The root of the binary tree.
    
        Returns:
            int: The maximum decimal value among all root-to-leaf paths.
        """
        if root is None:
            return 0  # Handle empty tree case
    
        # Initialize the queue with the root node and its value
        queue = deque([(root, root.val)])
        max_val = 0  # To keep track of the maximum value found
    
        while queue:
            current_node, current_sum = queue.popleft()
            
            # If it's a leaf node, update max_val if current_sum is greater
            if not current_node.left and not current_node.right:
                max_val = max(max_val, current_sum)
    
            # For non-leaf nodes, add children to the queue with updated sums
            if current_node.left:
                queue.append((current_node.left, current_sum * 5 + current_node.left.val))
            if current_node.right:
                queue.append((current_node.right, current_sum * 5 + current_node.right.val))
    
        return max_val


        Any other solution that is O(n) runtime complexity or better is considered optimal.
        Correct solutions with worse runtime complexity are accepted but don't lead to a strong hire
`,
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 20 * 60 * 1000, // 20 minutes
        challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}`,
        codeStub: {
            value:`
    Given the root of a binary tree, calculate the vertical order traversal of the binary tree.
    The vertical order traversal of a binary tree is a list of top-to-bottom orderings for each column index,
    starting from the leftmost column and ending on the rightmost column.
    There may be multiple nodes in the same row and same column.
    In such a case, sort these nodes by their values.
    
    Return the vertical order traversal of the binary tree, as an array containing the node values in ascending order
    `,
            isCode: false,
        },
        challengeType: ChallengeType.CODING,
        hints: [],
        required: true,
        acceptableAnswer: `
        ========
        APPROACH 1: Using a heap to sort
        ========
        import heapq

        def vertical_order_traversal_heap(root):
            nodes = []

            def dfs(node, row, col):
                if node:
                    heapq.heappush(nodes, (col, row, node.val))
                    dfs(node.left, row + 1, col - 1)
                    dfs(node.right, row + 1, col + 1)

            dfs(root, 0, 0)
            return [heapq.heappop(nodes) for _ in range(len(nodes))]

        ========
        APPROACH 2: Using built-in sorting
        ========    
        class TreeNode:
            def __init__(self, val=0, left=None, right=None):
                self.val = val
                self.left = left
                self.right = right

        def vertical_order_traversal_sort(root):
            nodes = []

            def dfs(node, row, col):
                if node:
                    nodes.append((col, row, node.val))
                    dfs(node.left, row + 1, col - 1)
                    dfs(node.right, row + 1, col + 1)

            dfs(root, 0, 0)
            nodes.sort()
            return nodes

        

        Any other solution that is O(n) runtime complexity or better is considered optimal.
        Correct solutions with worse runtime complexity are accepted but don't lead to a strong hire
`,
    },
]

const META_PROD_ENGR_CODING_INTERVIEW_ROUND_45_MINS_MODERATELY_CHALLENGING: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 25 * 60 * 1000, // 25 minutes for main coding challenge
        challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}`,
        codeStub: {
            value: `
You are given two CSV datasets containing dinosaur information. 
Using these datasets, compute the speed of bipedal dinosaurs based on the formula:

    speed = ((STRIDE_LENGTH / LEG_LENGTH) - 1) * sqrt(LEG_LENGTH * 9.8)

Then, output the names of the bipedal dinosaurs sorted from fastest to slowest.

Details:
- dataset1.csv includes columns: NAME, LEG_LENGTH, DIET
- dataset2.csv includes columns: NAME, STRIDE_LENGTH, STANCE
- Filter for dinosaurs where STANCE == "bipedal"
- Use their LEG_LENGTH and STRIDE_LENGTH to compute speed.
- Sort the bipedal dinosaurs by computed speed in descending order and print their names.
        `,
        isCode: false,
        },
        challengeType: ChallengeType.CODING,
        hints: [],
        required: true,
        acceptableAnswer: `
A correct implementation would:
- Read dataset1.csv and store a map of NAME -> LEG_LENGTH.
- Read dataset2.csv, filter for bipedal dinosaurs, and for each:
  - Retrieve LEG_LENGTH from the map,
  - Compute speed using the provided formula,
  - Store (NAME, speed) in a list.
- Sort the resulting list by speed descending and print the names.

Any O(n) solution with straightforward parsing and sorting is acceptable.
        `,
        referenceSolutions: [
            new ReferenceSolution({
                referenceSolutionLabel: "Python Reference Solution",
                referenceSolutionIsCode: true,
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
                referenceSolutionExplanation: "This Python solution reads the input CSV files, filters for bipedal dinosaurs, calculates their speed, and prints them sorted by speed in descending order.",
                referenceSolution: `#!/usr/bin/env python3
import csv
import math

def read_dataset1(file_path):
    leg_length_map = {}
    with open(file_path, newline='') as csvfile:
        reader = csv.DictReader(csvfile)
        for row in reader:
            name = row['NAME'].strip()
            leg_length_map[name] = float(row['LEG_LENGTH'])
    return leg_length_map

def read_dataset2(file_path, leg_length_map):
    dinosaurs = []
    with open(file_path, newline='') as csvfile:
        reader = csv.DictReader(csvfile)
        for row in reader:
            stance = row['STANCE'].strip().lower()
            if stance == 'bipedal':
                name = row['NAME'].strip()
                stride_length = float(row['STRIDE_LENGTH'])
                leg_length = leg_length_map.get(name)
                if leg_length:
                    speed = ((stride_length / leg_length) - 1) * math.sqrt(leg_length * 9.8)
                    dinosaurs.append((name, speed))
    return dinosaurs

def main():
    dataset1 = 'dataset1.csv'
    dataset2 = 'dataset2.csv'

    leg_length_map = read_dataset1(dataset1)
    bipedal_dinosaurs = read_dataset2(dataset2, leg_length_map)
    sorted_dinosaurs = sorted(bipedal_dinosaurs, key=lambda x: x[1], reverse=True)

    for name, _ in sorted_dinosaurs:
        print(name)

if __name__ == "__main__":
    main()
`
            }),
            new ReferenceSolution({
                referenceSolutionLabel: "Bash Reference Solution",
                referenceSolutionIsCode: true,
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Bash,
                referenceSolutionExplanation: "This Bash solution processes CSV files line by line, filters for bipedal dinosaurs, computes their speed using bc for arithmetic, and then sorts and prints their names by speed.",
                referenceSolution: `#!/bin/bash
                G=9.8
                declare -A leg_length_map
                
                tail -n +2 dataset1.csv | while IFS=',' read -r name leg_length diet; do
                    leg_length_map["\${name}"]=\${leg_length}
                done
                
                declare -A speed_map
                tail -n +2 dataset2.csv | while IFS=',' read -r name stride_length stance; do
                    if [[ "\${stance}" == "bipedal" ]]; then
                        leg_length=\${leg_length_map["\${name}"]}
                        if [[ -n "\${leg_length}" ]]; then
                            ratio=$(echo "\${stride_length} / \${leg_length}" | bc -l)
                            intermediate=$(echo "\${ratio} - 1" | bc -l)
                            sqrt_part=$(echo "sqrt(\${leg_length} * \${G})" | bc -l)
                            speed=$(echo "\${intermediate} * \${sqrt_part}" | bc -l)
                            speed_map["\${name}"]=\${speed}
                        fi
                    fi
                done
                
                for name in "\${!speed_map[@]}"; do
                    echo "\${speed_map[\${name}]},\${name}"
                done | sort -nr | cut -d',' -f2
                `
                
                
                

            })
        ]
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes follow-up
        challenge: `
Follow-up:
Modify your solution so that the user can specify whether to sort by speed or by name. 
If the user chooses "speed", sort in descending order of speed. 
If the user chooses "name", sort in ascending order by name.
        `,
        challengeType: ChallengeType.CODING,
        hints: [],
        required: true,
        acceptableAnswer: `
A correct solution might introduce a command-line argument or an environment variable 
that specifies the sort key ("speed" or "name"). Based on the chosen sort key, 
adjust the sorting logic accordingly:

- If sorting by speed: sort by speed descending.
- If sorting by name: sort by name ascending.
        `,
        referenceSolutions: [
            new ReferenceSolution({
                referenceSolutionLabel: "Python Reference Solution for Sorting by Speed or Name",
                referenceSolutionIsCode: true,
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
                referenceSolutionExplanation: "This Python solution adds a command-line argument to specify sorting by speed or name. It uses the `argparse` library to handle user input and adjusts the sorting logic accordingly.",
                referenceSolution: `#!/usr/bin/env python3
            import csv
            import math
            import argparse
            
            def read_dataset1(file_path):
                leg_length_map = {}
                with open(file_path, newline='') as csvfile:
                    reader = csv.DictReader(csvfile)
                    for row in reader:
                        name = row['NAME'].strip()
                        leg_length_map[name] = float(row['LEG_LENGTH'])
                return leg_length_map
            
            def read_dataset2(file_path, leg_length_map):
                dinosaurs = []
                with open(file_path, newline='') as csvfile:
                    reader = csv.DictReader(csvfile)
                    for row in reader:
                        stance = row['STANCE'].strip().lower()
                        if stance == 'bipedal':
                            name = row['NAME'].strip()
                            stride_length = float(row['STRIDE_LENGTH'])
                            leg_length = leg_length_map.get(name)
                            if leg_length:
                                speed = ((stride_length / leg_length) - 1) * math.sqrt(leg_length * 9.8)
                                dinosaurs.append((name, speed))
                return dinosaurs
            
            def main():
                parser = argparse.ArgumentParser(description="Compute and sort dinosaur speeds.")
                parser.add_argument('--sort-by', choices=['speed', 'name'], default='speed', help='Sort by "speed" or "name".')
                args = parser.parse_args()
            
                dataset1 = 'dataset1.csv'
                dataset2 = 'dataset2.csv'
            
                leg_length_map = read_dataset1(dataset1)
                bipedal_dinosaurs = read_dataset2(dataset2, leg_length_map)
            
                if args.sort_by == 'speed':
                    sorted_dinosaurs = sorted(bipedal_dinosaurs, key=lambda x: x[1], reverse=True)
                else:
                    sorted_dinosaurs = sorted(bipedal_dinosaurs, key=lambda x: x[0])
            
                for name, _ in sorted_dinosaurs:
                    print(name)
            
            if __name__ == "__main__":
                main()
            `
            }),
            new ReferenceSolution({
                referenceSolutionLabel: "Bash Reference Solution for Sorting by Speed or Name",
                referenceSolutionIsCode: true,
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Bash,
                referenceSolutionExplanation: "This Bash solution adds a command-line argument to specify sorting by speed or name. It uses `getopts` for argument parsing and adjusts the sorting command accordingly.",
                referenceSolution: `#!/bin/bash
            G=9.8
            declare -A leg_length_map
            sort_by="speed" # default sorting
            
            # Parse command-line arguments
            while getopts "s:" opt; do
              case \$opt in
                s)
                  if [[ "\$OPTARG" == "speed" || "\$OPTARG" == "name" ]]; then
                    sort_by="\$OPTARG"
                  else
                    echo "Invalid sort option. Use 'speed' or 'name'."
                    exit 1
                  fi
                  ;;
                \?)
                  echo "Invalid option: -\$OPTARG" >&2
                  exit 1
                  ;;
              esac
            done
            
            # Read dataset1.csv and map NAME to LEG_LENGTH
            tail -n +2 dataset1.csv | while IFS=',' read -r name leg_length diet; do
                leg_length_map["\${name}"]=\${leg_length}
            done
            
            declare -A speed_map
            # Read dataset2.csv, filter bipedal, compute speed
            tail -n +2 dataset2.csv | while IFS=',' read -r name stride_length stance; do
                if [[ "\${stance}" == "bipedal" ]]; then
                    leg_length=\${leg_length_map["\${name}"]}
                    if [[ -n "\${leg_length}" ]]; then
                        ratio=$(echo "\${stride_length} / \${leg_length}" | bc -l)
                        intermediate=$(echo "\${ratio} - 1" | bc -l)
                        sqrt_part=$(echo "sqrt(\${leg_length} * \${G})" | bc -l)
                        speed=$(echo "\${intermediate} * \${sqrt_part}" | bc -l)
                        speed_map["\${name}"]=\${speed}
                    fi
                fi
            done
            
            # Prepare sortable data
            for name in "\${!speed_map[@]}"; do
                echo "\${speed_map[\${name}]},\${name}"
            done > sortable_data.txt
            
            # Sort based on the sort_by variable
            if [[ "\${sort_by}" == "speed" ]]; then
                sort -nr sortable_data.txt | cut -d',' -f2
            elif [[ "\${sort_by}" == "name" ]]; then
                sort sortable_data.txt -t',' -k2,2 | cut -d',' -f2
            fi
            
            # Clean up
            rm sortable_data.txt
            `
            }),
            
            
        ]
    },
    {
        id: 3,
        phaseTimeInMilliSeconds: 10 * 60 * 1000, // 10 minutes knowledge follow-up
        challenge: `
Follow-up:
Discuss how you would optimize your solution to handle very large datasets 
(e.g., millions of records) efficiently.
        `,
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        required: true,
        acceptableAnswer: `
Possible optimizations include:
- Using generators and streaming the input line-by-line rather than loading it all into memory at once.
- Processing chunks of data and sorting them incrementally (external sorting) for very large datasets.
- Using more memory-efficient data structures.
- Leveraging parallel processing or distributed computing frameworks.
- Avoiding unnecessary data structures and focusing on in-place calculations.
        `,
        referenceSolutions: [
            new ReferenceSolution({
                referenceSolutionLabel: "Optimization Strategies for Large Datasets",
                referenceSolutionIsCode: false,
                referenceSolutionProgrammingLanguage: null,
                referenceSolutionExplanation:"This reference solution outlines various optimization strategies to handle very large datasets efficiently, such as streaming data processing, external sorting, memory-efficient data structures, parallel processing etc",
                referenceSolution: `
            Possible optimizations include:
1) Using generators and streaming the input line-by-line rather than loading it all into memory at once.
2) Processing chunks of data and sorting them incrementally (external sorting) for very large datasets.
3) Using more memory-efficient data structures.
4) Leveraging parallel processing or distributed computing frameworks.
5) Avoiding unnecessary data structures and focusing on in-place calculations.
            `
            }),
            
        ]
    }
];

const META_PROD_ENGR_SYSTEMS_INTERVIEW_1: Phase[] = [
    {
      id: 1,
      phaseTimeInMilliSeconds: 8 * 60 * 1000, // 8 minutes
      challenge: "What happens during the boot process from the moment you turn on the machine until you get a login prompt?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  A thorough answer should demonstrate understanding of:
  1) The initial power-on and BIOS or UEFI process.
  2) The boot loader (e.g., GRUB) loading the kernel into memory.
  3) Kernel initialization (e.g., device discovery, initramfs, mounting root filesystem).
  4) The init or systemd process starting user space services.
  5) Reaching a login prompt (getty, display manager, etc.).
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  During boot:
  1) Firmware/BIOS or UEFI runs POST and identifies boot devices.
  2) A boot loader like GRUB loads the kernel and initramfs.
  3) The kernel initializes hardware, mounts the root filesystem.
  4) Systemd (or init) spawns services and getty/display manager.
  5) The login prompt appears after user-land processes start.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Covers each stage from power-on to the login prompt with mention of BIOS/UEFI, boot loader, kernel init, and systemd.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 2,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "What happens in Linux, at the kernel level, when you execute ls -l?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Candidates should discuss:
  1) How user space triggers a system call (execve).
  2) Kernel-level file system access, including VFS (virtual file system) interactions.
  3) Reading directory contents, retrieving metadata (inodes).
  4) Copying data back to user space for display.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  When executing "ls -l", the shell calls fork() + execve() to run /bin/ls.
  The kernel interfaces with the file system via the VFS layer to read the directory and retrieve file metadata.
  The kernel then returns the file info to user space, where "ls" formats and prints it.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Focuses on execve, VFS, reading directory entries, returning data to user space.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 3,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How does DNS work?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  A solid answer includes:
  1) The hierarchy of DNS (root servers, TLD servers, authoritative servers).
  2) Recursive vs. iterative lookups.
  3) Caching mechanisms (DNS resolvers).
  4) How records like A, AAAA, CNAME, etc. are resolved.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  DNS uses a hierarchical naming system. A client sends a query to a recursive resolver,
  which queries root servers, TLD servers, and then authoritative servers.
  Records (A, AAAA, CNAME, etc.) are resolved, and results are cached to speed up future lookups.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains hierarchy (root → TLD → authoritative), recursion vs. iteration, and caching.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 4,
      phaseTimeInMilliSeconds: 8 * 60 * 1000, // 8 minutes
      challenge: "What happens on a browser when facebook.com is entered?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Key points:
  1) DNS lookup for facebook.com.
  2) TCP handshake (and possibly TLS handshake).
  3) HTTP request creation (headers, cookies, etc.).
  4) Server response (HTML, CSS, JavaScript).
  5) Browser rendering process.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  1) The browser checks DNS to resolve facebook.com to an IP.
  2) A TCP handshake (SYN, SYN-ACK, ACK) occurs, potentially followed by TLS negotiation.
  3) The browser sends an HTTP request and receives HTML/CSS/JS in response.
  4) Rendering engine parses and displays the page, handling scripts and additional requests.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Essentially covers DNS resolution, TCP/TLS handshake, HTTP request/response, and rendering.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 5,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "What is a zombie process, and can it be terminated?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  An adequate explanation covers:
  1) A zombie process is a defunct child process that remains because its parent hasn't yet read its exit status.
  2) The 'zombie' remains in the process table until the parent calls wait() or is killed.
  3) Zombie processes cannot be 'killed' directly because they're already finished; the remedy is to fix or kill the parent to allow it to reap the child.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  A zombie process is a child that has exited but whose exit status wasn't collected by the parent via wait().
  It remains in the process table, so you can't "kill" it again. Typically, you fix the parent or kill the parent so the zombie is reaped.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains that zombies are already dead processes needing the parent to call wait().",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 6,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "Compare TLS 1.2 to TLS 1.3.",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Expected coverage:
  1) TLS 1.3 offers a simpler handshake with fewer round trips.
  2) Stronger default cipher suites and forward secrecy.
  3) Removal of older, insecure algorithms present in TLS 1.2.
  4) Potential performance improvements due to the shorter handshake.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  TLS 1.3 reduces handshake steps, removing older ciphers and requiring forward-secret key exchanges.
  It typically speeds up connections and improves security over TLS 1.2 by dropping outdated algorithms.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Highlights fewer round trips, removal of older ciphers, stronger default security in 1.3.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    }
  ];

const META_PROD_ENGR_SYSTEMS_INTERVIEW_2: Phase[] = [
    {
      id: 1,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How does a system call transition from user space to kernel space?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Key areas to mention:
  1) The user process triggers a system call via an interrupt or special CPU instruction (e.g., syscall).
  2) CPU mode switch from user mode to kernel mode.
  3) The kernel looks up the appropriate system call handler (via syscall table).
  4) After execution, it switches back to user mode.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  A system call involves a transition from user mode to kernel mode.
  Typically, a special CPU instruction (e.g., syscall on x86_64) triggers this, saving registers and switching to a privileged stack.
  The kernel then services the request (file I/O, networking, etc.) and returns to user mode when done.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Describes how an interrupt or instruction triggers the kernel to handle a system call.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 2,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "What is the purpose of the swap area in memory management?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Important concepts:
  1) Swap is an area on disk used to move pages out of RAM when memory is low.
  2) It allows the OS to free up physical memory for active processes.
  3) Overuse of swap can lead to performance issues (thrashing).
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Swap space extends the amount of usable memory by moving inactive pages to disk.
  When RAM is full, the OS can page out less-used data.
  However, accessing swap is far slower than physical memory, so excessive swapping degrades performance.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains how swap extends memory but can reduce performance if overused.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 3,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How does TCP work?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  A good answer covers:
  1) Connection setup via a three-way handshake (SYN, SYN-ACK, ACK).
  2) Reliable data transfer (sequence numbers, ACKs, retransmissions).
  3) Flow control (windowing) and congestion control.
  4) Connection teardown (FIN, ACK).
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  TCP provides reliable, ordered, and error-checked delivery of a stream of bytes.
  It starts with a three-way handshake, uses sequence numbers and acknowledgments for reliability,
  and has mechanisms like flow control (sliding window) and congestion control (e.g., slow start).
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Describes TCP’s handshake, reliability, and flow/congestion control strategies.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 4,
      phaseTimeInMilliSeconds: 7 * 60 * 1000, // 7 minutes
      challenge: "Why is TCP congestion control a problem?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Candidates should note:
  1) TCP congestion control can lead to suboptimal usage if the path is congested or if it misestimates available bandwidth.
  2) It's a problem because flows compete for shared resources, and naive approaches can collapse the network.
  3) Solutions include algorithms like Reno, Cubic, BBR, etc.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Congestion control is tricky because TCP must probe the network to find available bandwidth
  while avoiding congestion collapse. Suboptimal or outdated algorithms can cause slow throughput
  or unfair bandwidth sharing among flows.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Highlights balancing network resource usage, preventing collapse, and algorithm complexity.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 5,
      phaseTimeInMilliSeconds: 8 * 60 * 1000, // 8 minutes
      challenge: "How would you troubleshoot a system where an application fails to start on the server?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Key steps:
  1) Check logs (syslog, application logs) to identify errors.
  2) Verify system resources (RAM, disk space, open file limits).
  3) Ensure dependencies or services are running.
  4) Possibly use tools like strace or ldd to check for missing libraries.
  5) Check systemd or init scripts if it's a service.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Start by reviewing logs (system logs, app logs).
  Check if there are missing dependencies, insufficient permissions, or resource limits reached.
  Tools like strace can show where the process fails. Ensure required services or ports are available.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Emphasizes logs, resource checks, dependencies, and using strace if needed.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 6,
      phaseTimeInMilliSeconds: 7 * 60 * 1000, // 7 minutes
      challenge: "How does strace function?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Should discuss:
  1) strace uses ptrace (a debugging interface) to intercept system calls.
  2) It logs arguments and results of each call to show process activity.
  3) It's helpful for diagnosing failures or unexpected behavior in userland processes.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  strace attaches to a process via ptrace.
  It intercepts system calls, logs their parameters and return values,
  which helps diagnose what a process is doing at the kernel interface level.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains that strace leverages ptrace to track syscalls, arguments, and return codes.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    }
  ];

const META_PROD_ENGR_SYSTEMS_INTERVIEW_3: Phase[] = [
    {
      id: 1,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How does the kill command work at the kernel level?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Points to cover:
  1) kill sends a signal to a target process ID.
  2) The kernel dispatches the signal to the target process.
  3) Process either handles, ignores, or terminates based on the signal type.
  4) Implementation uses system calls like kill() or tgkill().
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  kill triggers a system call that instructs the kernel to send a signal to the specified process.
  If the signal isn’t blocked or handled, the default action (e.g., terminate) occurs.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Describes how kill works via system calls to deliver signals in the kernel.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 2,
      phaseTimeInMilliSeconds: 7 * 60 * 1000, // 7 minutes
      challenge: "Explain the process of mounting and unmounting filesystems in Linux.",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  A good answer includes:
  1) mount() system call, specifying device and mount point.
  2) The VFS layer integrates the new filesystem into the directory tree.
  3) The kernel tracks mount references, and umount() detaches it.
  4) Tools like /etc/fstab automate mounting at boot.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  When mounting, the kernel uses the mount() syscall to attach a filesystem to the global hierarchy at a mount point.
  Unmounting uses umount() to detach the filesystem. The VFS (Virtual File System) layer tracks these mounts.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Covers how mount() attaches a filesystem to the directory tree and umount() detaches it.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 3,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How does HTTP work?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Important aspects:
  1) Application-layer protocol over TCP (possibly TLS).
  2) Request-response model using methods (GET, POST, etc.).
  3) Headers, status codes, and the concept of statelessness.
  4) Persistent connections with HTTP/1.1, chunked transfers, etc.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  HTTP is a stateless, request-response protocol.
  A client sends requests (GET, POST) with headers; the server responds with a status code, headers, and content.
  It typically runs over TCP, possibly encrypted with TLS in HTTPS.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Outlines request-response nature, key headers, and how it runs over TCP/HTTPS.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 4,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "What is the purpose of tcpdump?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  A good answer:
  1) tcpdump is a packet capture utility.
  2) It allows real-time analysis or capturing packets for offline analysis.
  3) Works by hooking into the kernel's packet capture mechanisms (libpcap).
  4) Useful for troubleshooting and security analysis.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  tcpdump captures network traffic at the packet level using libpcap.
  It can filter, display headers, and save raw packets for later analysis, aiding in debugging network issues.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains how tcpdump hooks into the kernel to capture and analyze packets.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 5,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "Which signal is sent to a parent process when a child process terminates?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  An answer should identify:
  1) SIGCHLD is sent to the parent on child termination.
  2) The parent can handle or ignore it, or wait() to collect exit status.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Upon child exit, the kernel sends SIGCHLD to the parent.
  The parent can catch this signal and call wait() to reap the child's status.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Describes that SIGCHLD is sent on child exit and how the parent can handle it.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 6,
      phaseTimeInMilliSeconds: 9 * 60 * 1000, // 9 minutes
      challenge: "What options do you have (nefarious or otherwise) to stop people on a wireless network you are also on (but have no admin rights to) from hogging bandwidth by streaming videos?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Points to note:
  1) Non-admin solutions might include packet spoofing, ARP poisoning, or deauthentication attacks, which are unethical or illegal in many places.
  2) More ethical approach: local rate-limiting or Quality of Service (QoS) if you control your own access point (but you said no admin rights).
  3) The question tests knowledge of network-level interference methods, with an emphasis on the ethical implications.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Techniques include ARP spoofing or sending deauth frames to disrupt streaming, but they’re unethical and may be illegal.
  With no admin rights, legitimate throttling options are limited. This question highlights awareness of network hacking methods.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Acknowledges unethical nature of ARP poisoning or deauth attacks, along with minimal legal alternatives.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    }
  ];

const META_PROD_ENGR_SYSTEMS_INTERVIEW_4: Phase[] = [
    {
      id: 1,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How can you determine which disks are currently mounted?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Candidates might mention:
  1) Using commands like 'mount' or 'df' to list currently mounted filesystems.
  2) Checking /proc/mounts or /etc/mtab in Linux for a list of mounted devices.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  The 'mount' or 'df' commands show currently mounted filesystems.
  Also, /proc/mounts and /etc/mtab provide this information at the system level.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Mentions mount/df commands and checking /proc/mounts.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 2,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "What tools would you use to debug a kernel panic in Linux?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Key tools and approaches:
  1) kdump/kexec to capture crash dumps.
  2) Using netconsole or serial console for kernel logs.
  3) GDB with kernel crash dumps.
  4) Checking dmesg logs or persistent journaling if set up.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Common methods include enabling kdump to capture a memory dump for post-mortem analysis,
  using gdb to analyze the vmcore dump, and checking kernel logs or serial/netconsole outputs.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Mentions kdump, gdb, and kernel logs for post-mortem analysis.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 3,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How does UDP work?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Points to mention:
  1) UDP is a connectionless protocol.
  2) It doesn't guarantee delivery, ordering, or reliability.
  3) Faster and simpler than TCP, useful for streaming or realtime data.
  4) Uses datagrams without establishing a handshake.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  UDP sends datagrams with no handshake, no guaranteed delivery, and no ordering.
  It's lightweight, low-overhead, and good for scenarios where speed matters more than reliability.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains UDP’s connectionless, best-effort nature.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 4,
      phaseTimeInMilliSeconds: 7 * 60 * 1000, // 7 minutes
      challenge: "Where is DNS information stored on the system?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Candidate should cover:
  1) /etc/resolv.conf for resolver configuration.
  2) /etc/hosts for static host-to-IP mappings.
  3) The OS may also use NSS (Name Service Switch) to determine lookup order.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Typically, /etc/resolv.conf sets DNS servers.
  The /etc/hosts file can provide static mappings.
  The system’s NSS configuration (/etc/nsswitch.conf) defines lookup order.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Points to /etc/resolv.conf, /etc/hosts, and NSS configuration.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 5,
      phaseTimeInMilliSeconds: 7 * 60 * 1000, // 7 minutes
      challenge: "What methods can you use to catch a signal for a program without access to its source code?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Possible approaches:
  1) Using ptrace-based tools like strace or gdb to attach and observe signals.
  2) LD_PRELOAD with a custom library to intercept signal-related functions (if applicable).
  3) Using coredumps or systemd-coredump to analyze post-mortem.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  You can attach to the running process with gdb or use ptrace to intercept system calls and signals.
  LD_PRELOAD can hook signal handling functions. Without source code, these external tools are key.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Mentions gdb/ptrace and LD_PRELOAD tricks to intercept signals.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 6,
      phaseTimeInMilliSeconds: 8 * 60 * 1000, // 8 minutes
      challenge: "Tell me everything that happens on a browser when facebook.com is entered.",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Similar to a previous question:
  1) DNS lookup to resolve facebook.com.
  2) TCP (and likely TLS) handshake.
  3) Browser sends HTTP request, receives HTML/CSS/JS.
  4) Rendering engine parses and displays the page.
  5) Additional resources (images, scripts) may be fetched.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  This involves DNS resolution, establishing a secure (TLS) TCP connection, sending an HTTP request,
  receiving and rendering HTML/CSS/JS, and fetching additional resources as needed.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Outlines DNS, TCP/TLS handshake, HTTP exchange, and rendering pipeline.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    }
  ];

const META_PROD_ENGR_SYSTEMS_INTERVIEW_5: Phase[] = [
    {
      id: 1,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "If a program requires 1TB of RAM but only 16GB is available, how does the Linux system manage memory allocation?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Key points:
  1) Linux uses virtual memory and can overcommit memory.
  2) The OS uses swap space to offload inactive pages.
  3) The system might face performance issues and possibly OOM (Out of Memory) scenarios.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Linux provides virtual memory and may overcommit.
  Pages not currently needed can be swapped out to disk.
  If the memory pressure is too high, the OOM killer may terminate processes.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Describes virtual memory, swapping, and potential OOM conditions.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id:2,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How are shared libraries loaded into memory?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Important details:
  1) The dynamic linker (ld.so on Linux) maps shared libraries into a process's address space at load or runtime.
  2) Uses lazy loading techniques, and can leverage memory mapping for efficient sharing.
  3) Symbols are resolved at runtime.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  The dynamic linker uses mmap to load shared libraries into memory.
  It resolves symbols either at load time or lazily.
  This allows multiple processes to share the same library code segments.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Mentions dynamic linker, mmap, and shared code segments.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 3,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "What's your favorite protocol? What are its drawbacks, and how could you improve it?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Candidates can choose any protocol (e.g., HTTP, TCP, QUIC).
  Should identify:
  1) The protocol's purpose and strengths.
  2) Its main drawbacks (latency, overhead, complexity).
  3) Potential improvements (better compression, encryption, reduced round trips).
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Example: If they choose HTTP, mention it's simple and widely adopted but can be inefficient (overhead, latency).
  They could improve it by using HTTP/2 or HTTP/3 (QUIC) for multiplexing and lower latency.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Provides an example with HTTP and suggests improvements like HTTP/2 or HTTP/3.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 4,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How does a router work?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Main points:
  1) Routers operate at the network layer.
  2) They examine IP headers to forward packets to the next hop.
  3) They maintain routing tables and run routing protocols.
  4) They do not typically alter payload data, only layer 3 headers.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  A router inspects packet IP addresses, consults its routing table, and forwards packets along the optimal path.
  It may run routing protocols (OSPF, BGP) to update its routing table.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Describes how routers forward packets based on routing tables and IP headers.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 5,
      phaseTimeInMilliSeconds: 8 * 60 * 1000, // 8 minutes
      challenge: "What is a filesystem, and how does it work in Linux?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  A good answer should include:
  1) Filesystems organize data on storage media into files and directories.
  2) Linux uses VFS as an abstraction, supporting multiple filesystem types.
  3) Inodes store metadata, directories map names to inodes.
  4) The kernel provides a unified namespace so everything appears as a file.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  A filesystem defines how data is stored and retrieved.
  Linux’s VFS layer provides a common interface.
  File data and metadata (inodes, directories) are managed by specific filesystem drivers (ext4, xfs).
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains VFS, inodes, and how files/directories map to underlying data structures.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 6,
      phaseTimeInMilliSeconds: 8 * 60 * 1000, // 8 minutes
      challenge: "We have a database running unusually slow in production. Why might this be happening?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Areas to consider:
  1) Slow queries due to missing indexes or poor schema design.
  2) Resource contention: CPU, memory, disk I/O bottlenecks.
  3) Network latency or increased load.
  4) Locks or deadlocks.
  5) Configuration issues or lack of caching.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Possibilities include missing indexes causing full table scans,
  I/O bottlenecks on disk, high CPU load, network latency, or suboptimal configuration.
  Investigate query plans, system metrics, and logs to find the bottleneck.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Mentions indexes, I/O, CPU, locks, and configuration issues as common culprits.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    }
  ];

const META_PROD_ENGR_SYSTEMS_INTERVIEW_6: Phase[] = [
    {
      id: 1,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "What is the role of the `proc` filesystem? How is it used to interact with the kernel?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Key points:
  1) /proc is a virtual filesystem exposing kernel data structures as files.
  2) It provides runtime system information (processes, CPU, memory).
  3) Reading/writing certain files in /proc changes kernel parameters dynamically.
  4) Useful for monitoring and tuning the system without recompiling the kernel.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  /proc is a pseudo-filesystem presenting kernel data structures as files.
  By reading /proc files, you can see process info, hardware stats, and kernel parameters.
  Writing to some files can modify kernel behavior at runtime.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Describes /proc as a virtual FS for kernel info and runtime configuration.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 2,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "Explain the difference between `fork()` and `exec()` in process creation.",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  A proper answer:
  1) fork() creates a new process by duplicating the current process.
  2) exec() replaces the current process image with a new program.
  3) Typically, you fork first and then exec a new binary in the child process.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  fork() creates a copy of the current process.
  exec() takes the current process (often the child after fork) and loads a new program into it, replacing the old code and data.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Emphasizes that fork duplicates a process, exec replaces its image.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 3,
      phaseTimeInMilliSeconds: 6 * 60 * 1000, // 6 minutes
      challenge: "How is a network process terminated?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Candidates should cover:
  1) Sending a signal (e.g., SIGTERM or SIGKILL) to the process.
  2) The kernel closes sockets upon process termination.
  3) Graceful shutdown might involve the process handling signals and closing connections.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  You typically terminate a process by sending it a signal (kill command).
  The kernel then cleans up resources, including network sockets.
  If the process handles the signal gracefully, it can close connections before exiting.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Mentions signals, kernel cleanup of sockets, and graceful vs. forced termination.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 4,
      phaseTimeInMilliSeconds: 7 * 60 * 1000, // 7 minutes
      challenge: "Why wouldn't you want a root DNS server to answer queries for you, instead of delegating to an authoritative server?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Key reasoning:
  1) Root servers are meant to provide references to TLD servers, not host final records.
  2) Scalability and caching benefits rely on the hierarchy.
  3) If root servers answered all queries, they'd be overloaded and less efficient.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Root DNS servers are designed for delegating queries down the chain.
  They don't hold all domain records. If they provided final answers, they'd become a bottleneck, breaking the hierarchical, distributed design of DNS.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Explains that root servers’ role is delegation, not serving all queries, ensuring scalability.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 5,
      phaseTimeInMilliSeconds: 7 * 60 * 1000, // 7 minutes
      challenge: "How can you trace all function calls in a running process?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Methods include:
  1) Using tools like strace or ltrace to intercept system and library calls.
  2) Using ptrace-based debuggers like gdb or perf.
  3) On some platforms, ftrace or eBPF-based tools can trace kernel/user functions.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  Tools like strace (for syscalls) and ltrace (for library calls) can trace functions.
  For deeper insight, gdb or advanced tracing frameworks (ftrace, eBPF) can record function calls in real-time.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Lists strace, ltrace, and advanced tools (gdb, ftrace, eBPF) for call tracing.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    },
    {
      id: 6,
      phaseTimeInMilliSeconds: 8 * 60 * 1000, // 8 minutes
      challenge: "How would you send packets to remote machines and try to upgrade the packet remotely? How would you troubleshoot if some machines are not updated?",
      challengeType: ChallengeType.KNOWLEDGE,
      hints: [],
      required: true,
      acceptableAnswer: `
  Points to consider:
  1) Clarify if this refers to sending software updates or packet upgrades. Possibly use package managers or configuration management tools (Ansible, Puppet, Chef).
  2) If network-level packets, you might use scripts and SSH, or orchestration tools.
  3) Troubleshoot by checking network connectivity, logs, verifying package sources, ensuring correct credentials, and checking for firewalls.
      `,
      referenceSolutions: [
        {
          referenceSolutionLabel: "Explanation",
          referenceSolution: `
  You might use SSH-based automation (Ansible, Chef, Puppet) to push updates.
  To troubleshoot, check connectivity (ping, traceroute), logs on target machines, authentication failures, and ensure the remote package repositories are accessible and not blocked by firewalls.
          `,
          referenceSolutionIsCode: false,
          referenceSolutionExplanationQuick: "Highlights using config management tools and standard network debugging steps.",
          referenceSolutionExplanationThoughtProcessHtml: null,
          referenceSolutionExplanationExplainLikeImFive: null,
          referenceSolutionProgrammingLanguage: null
        }
      ]
    }
];
  

// GOOGLE
const GOOGLE_INTERVIEW_Round_1_60_MINS_CHALLENGING: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 45 * 60 * 1000, // 45 minutes
        challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}\nI strongly advice you take your time to understand the question, and keep an eye on the timer.`,
        codeStub: {
            value:`
You're tasked with creating a function that performs string replacements,
based on a given map of keys and values. 

Your function will receive an input string and a replacement map, and will 
return a new string with all instances of each key,replaced by its corresponding value. 
The keys and values are strings.

A key in the input string is a word enclosed in '%' symbols on both sides. 
You need to replace the entire key (including the '%' symbols) with its corresponding value.
        
A key may be replaced with a string that contains other keys. 
When this happens, all the keys in the newly inserted string also need to be replaced, and so on.

Consider the following examples:
        
    1)  Given the map {X=>123, Y=>456} and the input string "%X%_%Y%",
        your function should return the string "123_456".
        
    2)  Given the map {USER=>admin, HOME=>/%USER%/home} and the input string
        "I am %USER% My home is %HOME%", your function should return the string 
        "I am admin My home is /admin/home".
        
    3)  Given the map {ID=>id, USER=>admin, HOME=>/%USER%/home, VAR=> %HOME%_%ID%},
    and the input string "The var is %VAR%", 
    your function should return the string "The var is /admin/home_id".
        
You can assume that the input will always be well-formed; that is, every '%' symbol 
will have a matching pair enclosing a key, and that every key enclosed in '%' symbols 
will have a matching entry in the map. 
There will be no nested '%' pairs. 
Finally, there are no circular replacements in the map (i.e. the replacement of a key doesn't 
lead back to the original key).
`,
            isCode: false,
        },
        challengeType: ChallengeType.CODING,
        hints: [],
        required: true,
        acceptableAnswer: `
        possible java implementation
        import java.util.*;

        public class Main {
            public static String foo(String inputString, Map<String, String> replacementMap) {
                StringBuilder output = new StringBuilder();
        
                for (int i = 0; i < inputString.length(); i++) {
                    char ch = inputString.charAt(i);
                    if (ch == '%') {
                        // find the closing '%'
                        int end = inputString.indexOf('%', i + 1);
                        if (end == -1) {
                            throw new IllegalArgumentException("Unmatched '%' in input string");
                        }
                        // get the key enclosed by '%' symbols
                        String key = inputString.substring(i + 1, end);
                        if (!replacementMap.containsKey(key)) {
                            throw new IllegalArgumentException("Undefined key in input string");
                        }
                        // call the function recursively on the enclosing value
                        String replacedKey = foo(replacementMap.get(key), replacementMap);
                        output.append(replacedKey);
                        // move the index to start after the closing '%'
                        i = end;
                    } else {
                        output.append(ch);
                    }
                }
        
                return output.toString();
            }
        
            public static void main(String[] args) {
                Map<String, String> map = new HashMap<String, String>() {{
                    put("X", "123");
                    put("Y", "456");
                }};
                System.out.println(foo("%X%_%Y%", map)); // prints 123_456
        
                map = new HashMap<String, String>() {{
                    put("USER", "admin");
                    put("HOME", "/%USER%/home");
                }};
                System.out.println(foo("I am %USER% My home is %HOME%", map)); // prints I am admin My home is /admin/home
        
                map = new HashMap<String, String>() {{
                    put("ID", "id");
                    put("USER", "admin");
                    put("HOME", "/%USER%/home");
                    put("VAR", "%HOME%_%ID%");
                }};
                System.out.println(foo("The var is %VAR%", map)); // prints The var is /admin/home_id
            }
        }
        
here's a topological sort based python solution
from collections import defaultdict, deque

# Function to build the directed graph from the replacement map
def build_graph(replacement_map):
    graph = defaultdict(list)
    for key, value in replacement_map.items():
        # For each key-value pair in the map
        for k in replacement_map.keys():
            # If a key is in the value of another key, add an edge from the first key to the second
            if '%' + k + '%' in value:
                graph[k].append(key)
    return graph

# Function to perform a topological sort on the graph
def topological_sort(graph, replacement_map):
    sorted_order = []
    indegree = {key: 0 for key in replacement_map}  # initialize the indegree of all nodes to 0
    for key in graph:
        for child in graph[key]:  # update the indegree of each node
            indegree[child] += 1
    sources = deque([key for key in indegree if indegree[key] == 0])  # get the nodes with 0 indegree
    while sources:
        key = sources.popleft()  # remove a node from sources
        sorted_order.append(key)  # append the node to the sorted order
        # Decrease the indegree of each child node
        if key in graph:
            for child in graph[key]:
                indegree[child] -= 1
                # If the indegree of a node becomes 0, add it to sources
                if indegree[child] == 0:
                    sources.append(child)
    # If the graph has a cycle (i.e., not all nodes are included in the sorted order), return an empty list
    if len(sorted_order) != len(replacement_map):
        return []
    return sorted_order

# Function to replace each key in the string with its value according to the topological order
# Function to replace each key in the string with its value according to the topological order
def replace_string(input_string, sorted_order, replacement_map):
    # First, iterate through the sorted keys, replacing the values in the replacement map with their final forms
    for key in sorted_order:
        parts = replacement_map[key].split('%')
        for i in range(len(parts)):
            if parts[i] in replacement_map:
                parts[i] = replacement_map[parts[i]]
        replacement_map[key] = ''.join(parts)

    # Then split the input string into parts, replacing each key in the map with its final value
    parts = input_string.split('%')
    for i in range(1, len(parts) - 1):  # Here we skip the first and last parts, as they can't be keys
        if parts[i] in replacement_map:
            parts[i] = replacement_map[parts[i]]
    return ''.join(parts)



# Main function
def string_replacement(input_string, replacement_map):
    # Build the graph from the replacement map
    graph = build_graph(replacement_map)
    # Perform the topological sort on the graph
    sorted_order = topological_sort(graph, replacement_map)
    
    # Replace the keys in the string
    return replace_string(input_string, sorted_order, replacement_map)

# Test cases
print(string_replacement("%X%_%Y%", {"X": "123", "Y": "456"}))  # prints: 123_456
print(string_replacement("I am %USER% My home is %HOME%", {"USER": "admin", "HOME": "/%USER%/home"}))  # prints: I am admin My home is /admin/home
print(string_replacement("The var is %VAR%", {"ID": "id", "USER": "admin", "HOME": "/%USER%/home", "VAR": "%HOME%_%ID%"}))  # prints: The var is /admin/home_id

#more complicated test case
replacement_map = {
    "USER": "admin",
    "HOME": "/%USER%/home",
    "VAR1": "%HOME%_%ID%",
    "ID": "id",
    "VAR2": "*%VAR1%**%HOME%_%ID%",
    "VAR3": "_%VAR2%_ID",
    "BIGVAR": "%VAR1%_%VAR2%_%VAR3%"
}

input_string = "The big var is %BIGVAR%"
expected_output = "The big var is /admin/home_id_*/admin/home_id**/admin/home_id__*/admin/home_id**/admin/home_id_ID"
assert string_replacement(input_string, replacement_map) == expected_output
`,
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 15 * 60 * 1000,
        challenge: "Could you please walk me through the code snippet you just wrote? (Make sure your code is still in the editor.)",
        challengeType: ChallengeType.EXPLAIN_CODE,
        hints: [],
        required: true,
        acceptableAnswer: "Candidate should be able to explain the flow of their code, why they decided to use the specific data structures and algorithms.",
    }
];

// AMAZON
const AMAZON_INTERVIEW_Round_25_MINS_MODERATELY_CHALLENGING: Phase[] = [
    {
        id: 1,
        phaseTimeInMilliSeconds: 20 * 60 * 1000, // 20 minutes
        challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}`,
        codeStub: {
            value:`
    Implement a function that adds two n digit numbers.
    Note:
    - Each number is represented as a string
    - n is an integer in range [1, 10^5]

    You can use the following function signatures for inspiration
    def addNumbers(num1: str, num2: str) -> str // python
    string addNumbers(string num1, string num2) // java
    string addNumbers(const string& num1, const string& num2) // c++
    function addNumbers(num1, num2) //javascript

    You can adapt the interface to suit any language of your choice.
    `,
            isCode: false,
        },
        challengeType: ChallengeType.CODING,
        hints: [
            {
                hint: "Consider focusing on digits in the same place value",
            },
            {
                hint: "What happens if the sum for a place value is greater than 9?"
            }
        ],
        referenceSolutions: [
            {
                referenceSolution: `
                #include <string>
                #include <cassert>
                using namespace std;
                
                string addNumbers(const string& num1, const string& num2) {
                    // Initialize the result string to store the sum
                    string result = "";
                    int n = num1.length(); // Length of the numbers
                    int carry = 0; // Carry can be either 0 or 1
                
                    // Iterate through the strings from the end and add the digits and carry
                    for (int i = n - 1; i >= 0; i--) {
                        // Convert char digits to int
                        int digit1 = num1[i] - '0';
                        int digit2 = num2[i] - '0';
                        
                        // Add the digits and carry
                        int sum = digit1 + digit2 + carry;
                        int remainder = sum % 10; // Remainder to be appended to the result
                        carry = sum / 10; // Carry for the next digit
                
                        // Append the remainder to the result string
                        result = to_string(remainder) + result;
                    }
                
                    // Handle any remaining carry
                    if (carry > 0) {
                        result = to_string(carry) + result;
                    }
                
                    return result;
                }
                
                
                int main(){
                    assert(addNumbers("0", "4") == "4");
                    assert(addNumbers("1", "1") == "2");
                    assert(addNumbers("1", "9") == "10");
                    assert(addNumbers("9", "9") == "18");
                    
                    assert(addNumbers("10", "10") == "20");
                    assert(addNumbers("29", "11") == "40");
                    assert(addNumbers("99", "99") == "198");
                    
                    assert(addNumbers("100", "100") == "200");
                    assert(addNumbers("299", "101") == "400");
                    assert(addNumbers("245", "955") == "1200");
                    
                    assert(addNumbers("811111899", "611111899") == "1422223798");
                }
                  `,
                referenceSolutionIsCode: true,
                referenceSolutionExplanationQuick: "The approach used involves iterating through the place values of the numbers from right to left, adding the digits (in the same place value) along with any carry from the previous sum. The remainder of the sum is appended to the result string, and any carry is carried over to the next place value.",
                referenceSolutionProgrammingLanguage: ProgrammingLanguage.Cpp,
            },
        ],
        required: true,
        acceptableAnswer: `
        Sample C++ solution

        ========
        APPROACH 1:
        ========
        #include <string>
        #include <cassert>
        using namespace std;

        string addNumbers(const string& num1, const string& num2) {
            // Initialize the result string to store the sum
            string result = "";
            int n = num1.length(); // Length of the numbers
            int carry = 0; // Carry can be either 0 or 1

            // Iterate through the strings from the end and add the digits and carry
            for (int i = n - 1; i >= 0; i--) {
                // Convert char digits to int
                int digit1 = num1[i] - '0';
                int digit2 = num2[i] - '0';
                
                // Add the digits and carry
                int sum = digit1 + digit2 + carry;
                int remainder = sum % 10; // Remainder to be appended to the result
                carry = sum / 10; // Carry for the next digit

                // Append the remainder to the result string
                result = to_string(remainder) + result;
            }

            // Handle any remaining carry
            if (carry > 0) {
                result = to_string(carry) + result;
            }

            return result;
        }


        int main(){
            assert(addNumbers("0", "4") == "4");
            assert(addNumbers("1", "1") == "2");
            assert(addNumbers("1", "9") == "10");
            assert(addNumbers("9", "9") == "18");
            
            assert(addNumbers("10", "10") == "20");
            assert(addNumbers("29", "11") == "40");
            assert(addNumbers("99", "99") == "198");
            
            assert(addNumbers("100", "100") == "200");
            assert(addNumbers("299", "101") == "400");
            assert(addNumbers("245", "955") == "1200");
            
            assert(addNumbers("811111899", "611111899") == "1422223798");
        }
        
    `,
    },
    {
        id: 2,
        phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
        challenge: "Without writing any code, how would adjust your solution if the numbers were of different lengths? We can assume that the lengths of the numbers are within the range [1, 10^5].",
        challengeType: ChallengeType.KNOWLEDGE,
        hints: [],
        referenceSolutions: [
            {
                referenceSolution: `If the numbers are of different lengths, one possible way to adjust the solution to account for this by:
                \n(i) padding the shorter number with leading zeros to make both numbers of equal length.
                \n(ii) proceeding with the addition as before, iterating from right to left and adding the digits, along with any carry.`,
                referenceSolutionIsCode: false,
                referenceSolutionExplanationQuick: null,
                referenceSolutionProgrammingLanguage: null,
            },
        ],
        required: true,
        acceptableAnswer: `
        If the numbers are of different lengths, we can adjust the solution by:
        - Padding the shorter number with leading zeros to make both numbers of equal length.
        - Then, we can proceed with the addition as before, iterating from right to left and adding the digits along with any carry.
`,
    },
]

const AMAZON_LLD_INTERVIEW_ROUND_30_MINS_MODERATELY_CHALLENGING: Phase[] = [
    {
            id: 1,
            phaseTimeInMilliSeconds: 25 * 60 * 1000, // 25 minutes
            challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}`,
            codeStub: {
                value: `
        This problem involves designing a class to represent a card game where players are dealt cards with specific values and one of three colors. The objective is to determine the winner based on scoring combinations from the cards dealt.

        Game Setup:
        - Cards come in three colors: Red, Green, and Blue.
        - Card values range from 1 to 10.
        - Each player receives exactly three cards randomly from a deck assumed to have an infinite number of cards.
        - The game accommodates 2 to 6 players.

        Scoring Combinations:
        Points are awarded based on the following card combinations:

        - Color Combo: All three cards are of the same color.
            Points: 10 points
        - Value Combo: All three cards have the same value.
            Points: 15 points
        - Sequential Combo: All three cards have consecutive values, regardless of color.
            Points: 5 points
        - Mixed Combo: One card of each color, irrespective of value.
            Points: 7 points
        
        Additional Rules:

        - Only the highest scoring combination for a set of cards is counted.
        - In the event of a tie in total points, the player with the highest single card value is declared the winner. If a tie persists, the win is shared.
        
        Class Design Objective:
        Design a class named CardGame with methods to:

        - Distribute three cards to each player.
        - Calculate the score for each player's set of cards.
        - Determine the winner based on the highest score.

        Let's focus on supporting just a single round of the game for now.
        
        The class should facilitate initializing a game with a specific number of players and manage the distribution of cards and scoring internally.
        `,
                isCode: false,
            },
            challengeType: ChallengeType.CODING,
            hints: [
                {
                    hint: "Consider an object-oriented approach to represent the core objects in the game"
                },
                {
                    hint: "What are the core objects in the game that need to be represented?"
                },
                {
                    hint: "It might help to start with an interface, and then implement the methods based on the interface"
                },
                {
                    hint: "Might also help to consider how one round of the game would be played"
                }
            ],
            referenceSolutions: [
                {
                    referenceSolution: `
                    // C++ Solution
                    #include <iostream>
                    #include <vector>
                    #include <algorithm>

                    // Define Card Color using enum for better readability and maintenance
                    enum class Color { Red, Green, Blue };

                    // Card structure
                    struct Card {
                        int value;
                        Color color;

                        Card(int v, Color c) : value(v), color(c) {}
                    };

                    // Player class
                    class Player {
                        std::vector<Card> cards;

                    public:
                        void receiveCard(const Card& card) {
                            if (cards.size() < 3) {
                                cards.push_back(card);
                            }
                        }

                        // Determine if all cards are the same color
                        bool isSameColor() const {
                            return (cards[0].color == cards[1].color && cards[1].color == cards[2].color);
                        }

                        // Determine if all cards have the same value
                        bool isSameValue() const {
                            return (cards[0].value == cards[1].value && cards[1].value == cards[2].value);
                        }

                        // Determine if all cards are sequential
                        bool isSequential() const {
                            std::vector<int> values = { cards[0].value, cards[1].value, cards[2].value };
                            std::sort(values.begin(), values.end());
                            return (values[1] == values[0] + 1 && values[2] == values[1] + 1);
                        }

                        // Determine if the cards have one of each color
                        bool isMixedColor() const {
                            return (cards[0].color != cards[1].color && cards[1].color != cards[2].color && cards[0].color != cards[2].color);
                        }

                        // Calculate score based on predefined rules
                        int calculateScore() const {
                            if (isSameValue()) return 15;
                            if (isSameColor()) return 10;
                            if (isMixedColor()) return 7;
                            if (isSequential()) return 5;
                            return 0;
                        }

                        int highestCardValue() const {
                            return std::max({ cards[0].value, cards[1].value, cards[2].value });
                        }

                        void printCards() const {
                            for (const auto& card : cards) {
                                std::cout << "Value: " << card.value << ", Color: " << static_cast<int>(card.color) << std::endl;
                            }
                        }
                    };

                    // CardGame class
                    class CardGame {
                        std::vector<Player> players;

                    public:
                        CardGame(int numPlayers) {
                            if (numPlayers < 2 || numPlayers > 6) {
                                throw std::invalid_argument("Number of players must be between 2 and 6.");
                            }
                            players.resize(numPlayers);
                        }

                        // Simulated card distribution for simplicity
                        void distributeCards() {
                            // Just for demonstration, dealing fixed cards to players
                            // Normally you would shuffle and deal randomly
                            std::vector<Card> deck = {
                                Card(1, Color::Red), Card(1, Color::Green), Card(1, Color::Blue),
                                Card(2, Color::Red), Card(2, Color::Green), Card(2, Color::Blue),
                                Card(3, Color::Red), Card(3, Color::Green), Card(3, Color::Blue)
                            };
                            int cardIndex = 0;
                            for (auto& player : players) {
                                for (int i = 0; i < 3; ++i) {
                                    player.receiveCard(deck[cardIndex++ % deck.size()]);
                                }
                            }
                        }

                        void determineWinner() {
                            int maxScore = 0;
                            std::vector<int> winnerIndices;

                            // Calculate scores and find maximum
                            for (size_t i = 0; i < players.size(); ++i) {
                                int score = players[i].calculateScore();
                                if (score > maxScore) {
                                    maxScore = score;
                                    winnerIndices.clear();
                                    winnerIndices.push_back(i);
                                } else if (score == maxScore) {
                                    winnerIndices.push_back(i);
                                }
                            }

                            // Handle tie with the highest single card value
                            if (winnerIndices.size() == 1) {
                                std::cout << "Winner: Player " << winnerIndices.front() + 1 << std::endl;
                            } else {
                                int highestCard = 0;
                                std::vector<int> finalWinners;
                                for (int index : winnerIndices) {
                                    int cardValue = players[index].highestCardValue();
                                    if (cardValue > highestCard) {
                                        highestCard = cardValue;
                                        finalWinners.clear();
                                        finalWinners.push_back(index);
                                    } else if (cardValue == highestCard) {
                                        finalWinners.push_back(index);
                                    }
                                }
                                std::cout << "Tie, winners: ";
                                for (int winner : finalWinners) {
                                    std::cout << "Player " << winner + 1 << " ";
                                }
                                std::cout << std::endl;
                            }
                        }
                    };

                    // Main function to run the game
                    int main() {
                        CardGame game(4);
                        game.distributeCards();
                        game.determineWinner();
                        return 0;
                    }
                      `,
                    referenceSolutionIsCode: true,
                    referenceSolutionExplanationQuick: "The solution involves defining a Card structure and a Player class to represent the cards and players in the game. The CardGame class manages the game setup, card distribution, scoring, and determining the winner based on the predefined rules.",
                    referenceSolutionProgrammingLanguage: ProgrammingLanguage.Cpp,
                },
            ],
            required: true,
            acceptableAnswer: `
            Sample C++ solution
    
            ========
            APPROACH 1:
            ========
            #include <iostream>
            #include <vector>
            #include <algorithm>
            
            // Define Card Color using enum for better readability and maintenance
            enum class Color { Red, Green, Blue };
            
            // Card structure
            struct Card {
                int value;
                Color color;
            
                Card(int v, Color c) : value(v), color(c) {}
            };
            
            // Player class
            class Player {
                std::vector<Card> cards;
            
            public:
                void receiveCard(const Card& card) {
                    if (cards.size() < 3) {
                        cards.push_back(card);
                    }
                }
            
                // Determine if all cards are the same color
                bool isSameColor() const {
                    return (cards[0].color == cards[1].color && cards[1].color == cards[2].color);
                }
            
                // Determine if all cards have the same value
                bool isSameValue() const {
                    return (cards[0].value == cards[1].value && cards[1].value == cards[2].value);
                }
            
                // Determine if all cards are sequential
                bool isSequential() const {
                    std::vector<int> values = { cards[0].value, cards[1].value, cards[2].value };
                    std::sort(values.begin(), values.end());
                    return (values[1] == values[0] + 1 && values[2] == values[1] + 1);
                }
            
                // Determine if the cards have one of each color
                bool isMixedColor() const {
                    return (cards[0].color != cards[1].color && cards[1].color != cards[2].color && cards[0].color != cards[2].color);
                }
            
                // Calculate score based on predefined rules
                int calculateScore() const {
                    if (isSameValue()) return 15;
                    if (isSameColor()) return 10;
                    if (isMixedColor()) return 7;
                    if (isSequential()) return 5;
                    return 0;
                }
            
                int highestCardValue() const {
                    return std::max({ cards[0].value, cards[1].value, cards[2].value });
                }
            
                void printCards() const {
                    for (const auto& card : cards) {
                        std::cout << "Value: " << card.value << ", Color: " << static_cast<int>(card.color) << std::endl;
                    }
                }
            };
            
            // CardGame class
            class CardGame {
                std::vector<Player> players;
            
            public:
                CardGame(int numPlayers) {
                    if (numPlayers < 2 || numPlayers > 6) {
                        throw std::invalid_argument("Number of players must be between 2 and 6.");
                    }
                    players.resize(numPlayers);
                }
            
                // Simulated card distribution for simplicity
                void distributeCards() {
                    // Just for demonstration, dealing fixed cards to players
                    // Normally you would shuffle and deal randomly
                    std::vector<Card> deck = {
                        Card(1, Color::Red), Card(1, Color::Green), Card(1, Color::Blue),
                        Card(2, Color::Red), Card(2, Color::Green), Card(2, Color::Blue),
                        Card(3, Color::Red), Card(3, Color::Green), Card(3, Color::Blue)
                    };
                    int cardIndex = 0;
                    for (auto& player : players) {
                        for (int i = 0; i < 3; ++i) {
                            player.receiveCard(deck[cardIndex++ % deck.size()]);
                        }
                    }
                }
            
                void determineWinner() {
                    int maxScore = 0;
                    std::vector<int> winnerIndices;
            
                    // Calculate scores and find maximum
                    for (size_t i = 0; i < players.size(); ++i) {
                        int score = players[i].calculateScore();
                        if (score > maxScore) {
                            maxScore = score;
                            winnerIndices.clear();
                            winnerIndices.push_back(i);
                        } else if (score == maxScore) {
                            winnerIndices.push_back(i);
                        }
                    }
            
                    // Handle tie with the highest single card value
                    if (winnerIndices.size() == 1) {
                        std::cout << "Winner: Player " << winnerIndices.front() + 1 << std::endl;
                    } else {
                        int highestCard = 0;
                        std::vector<int> finalWinners;
                        for (int index : winnerIndices) {
                            int cardValue = players[index].highestCardValue();
                            if (cardValue > highestCard) {
                                highestCard = cardValue;
                                finalWinners.clear();
                                finalWinners.push_back(index);
                            } else if (cardValue == highestCard) {
                                finalWinners.push_back(index);
                            }
                        }
                        std::cout << "Tie, winners: ";
                        for (int winner : finalWinners) {
                            std::cout << "Player " << winner + 1 << " ";
                        }
                        std::cout << std::endl;
                    }
                }
            };
            
            // Main function to run the game
            int main() {
                CardGame game(4);
                game.distributeCards();
                game.determineWinner();
                return 0;
            }
        `,
        },
        {
            id: 2,
            phaseTimeInMilliSeconds: 5 * 60 * 1000, // 5 minutes
            challenge: "Without writing any code, describe how you would modify your solution to support an arbitrary number of rounds. The overall winners should be the players with the highest cumulative score across all rounds. How would you optimally track the scores and determine the overall winner?",
            challengeType: ChallengeType.KNOWLEDGE,
            hints: [],
            referenceSolutions: [
                {
                    referenceSolution: `An efficient way to track the scores is to use a hash table that maps the players to their cumulative scores across all rounds. After each round, the scores can be updated in the hash table. To avoid iterating through the hash table to determine the overall winner, it would be optimal to keep track of the current highest score and the corresponding player. Therefore, after each round, we should implement logic to track the maximum score and the current winner, updating these two variables as necessary after each hash table update.`,
                    referenceSolutionIsCode: false,
                    referenceSolutionExplanationQuick: "The approach involves using a hash table to map players to their total scores across all rounds. After each round, the scores are updated in the hash table, and the current winner is tracked to determine the overall winner efficiently.",
                    referenceSolutionProgrammingLanguage: null,
                },
            ],
            required: true,
            acceptableAnswer: `
            Reasonable solutions that leverage a hash table to track cumulative scores across rounds and efficiently determine the overall winner based on the highest score and corresponding player would be acceptable.
            If other datastructures are suggested, then provided the winner can be determine in constant time at the end of the game, this should be acceptable.
    `,
        },
    ]

const AMAZON_CODING_INTERVIEW_ROUND_30_MINS_MODERATELY_CHALLENGING_I: Phase[] = [
        {
                id: 1,
                phaseTimeInMilliSeconds: 25 * 60 * 1000, // 30 minutes
                challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}`,
                codeStub: {
                    value:`
Given an n x n binary matrix grid where cells consist of just 0s and 1s,
form a binary number by moving from the top-left cell to adjacent cells 
(horizontally or vertically). Each cell can only contribute once, to the number being formed

Find the largest Mersenne number that can be formed this way.

A Mersenne number is one less than a power of 2, i.e., 2^i - 1, where i is an integer >= 1.

It is guaranteed that the top-left cell has a value of 1.

Return the value of the largest Mersenne number in decimal form.
`,
                    isCode: false,
                },
                challengeType: ChallengeType.CODING,
                hints: [
                    {
                        hint: "can you divide the problem into subproblems?"
                    },
                ],
                referenceSolutions: [
                    {
                        referenceSolution: `
                        from functools import lru_cache

                        def find_largest_mersenne_number(grid):
                            n = len(grid)
                            
                            # Memoization with lru_cache to optimize the recursion
                            @lru_cache(maxsize=None)
                            def get_largest_mersenne_number_starting_from(i, j, visited):
                                # If out of bounds or the cell is 0, return an empty string (no Mersenne number)
                                if i < 0 or i >= n or j < 0 or j >= n or grid[i][j] == 0:
                                    return ""
                                
                                # Mark the current cell as visited by adding it to the visited set
                                visited = visited | {(i, j)} # frozen set is immutable so we need to create a new one
                                
                                max_mersenne_number_so_far = "1" # max so far is just the current cell's value
                                
                                # Explore all four possible directions: right, down, left, up
                                for x, y in [(0, 1), (1, 0), (0, -1), (-1, 0)]:
                                    ni, nj = i + x, j + y
                                    # Only consider valid and connected cells
                                    if 0 <= ni < n and 0 <= nj < n and grid[ni][nj] == 1 and (ni, nj) not in visited:
                                        # Get the largest Mersenne number from the neighbor
                                        neighbor_number = get_largest_mersenne_number_starting_from(ni, nj, visited)
                                        # Combine with the current value
                                        combined_number = "1" + neighbor_number
                                        # Update the current Mersenne number if the combined is larger
                                        if max_mersenne_number_so_far < combined_number:
                                            max_mersenne_number_so_far = combined_number
                                
                                # Unmark the current cell by removing it from the visited set
                                visited = visited - {(i, j)}
                                
                                return max_mersenne_number_so_far
                            
                            # Get the largest Mersenne number starting from the top-left cell
                            largest_mersenne_number = get_largest_mersenne_number_starting_from(0, 0, frozenset())
                            
                            # Convert the binary string to a decimal number
                            mersenne_number_length = len(largest_mersenne_number)
                            return (1 << mersenne_number_length) - 1

                        # Test cases
                        grid1 = [[1]]
                        expected_output1 = 1
                        result1 = find_largest_mersenne_number(grid1)
                        assert result1 == expected_output1, f"expected_output: {expected_output1}, output: {result1}"

                        grid2 = [
                            [1, 1, 1],
                            [1, 0, 0],
                            [0, 0, 0]
                        ]
                        expected_output2 = 7
                        result2 = find_largest_mersenne_number(grid2)
                        assert result2 == expected_output2, f"expected_output: {expected_output2}, output: {result2}"

                        grid3 = [
                            [1, 1, 0],
                            [1, 1, 0],
                            [1, 1, 1]
                        ]
                        expected_output3 = 127
                        result3 = find_largest_mersenne_number(grid3)
                        assert result3 == expected_output3, f"expected_output: {expected_output3}, output: {result3}"

                        grid4 = [
                            [1, 1, 1, 1],
                            [1, 0, 0, 1],
                            [1, 0, 0, 1],
                            [1, 1, 1, 1]
                        ]
                        expected_output4 = 4095
                        result4 = find_largest_mersenne_number(grid4)
                        assert result4 == expected_output4, f"expected_output: {expected_output4}, output: {result4}"

                        grid5 = [
                            [1, 1, 1, 1, 1],
                            [1, 0, 1, 0, 0],
                            [1, 0, 1, 0, 0],
                            [1, 1, 1, 0, 0],
                            [0, 0, 0, 0, 0]
                        ]
                        expected_output5 = 2047
                        result5 = find_largest_mersenne_number(grid5)
                        assert result5 == expected_output5, f"expected_output: {expected_output5}, output: {result5}"

                        print("All test cases passed successfully.")
                          `,
                        referenceSolutionIsCode: true,
                        referenceSolutionExplanationQuick: "The solution involves recognizing that a mersenne number such as '1111' is '1'+'111' i.e. to find the largest mersenne number starting from a given cell, we can find the largest mersenne number starting from its neighbors and add '1' to it.",
                        referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
                    },
                ],
                required: true,
                acceptableAnswer: `
                Any reasonable solution that correctly identifies the largest Mersenne number starting from the top-left cell in the binary matrix would be acceptable.
                
                Example Python solution:
                from functools import lru_cache

                def find_largest_mersenne_number(grid):
                    n = len(grid)
                    
                    # Memoization with lru_cache to optimize the recursion
                    @lru_cache(maxsize=None)
                    def get_largest_mersenne_number_starting_from(i, j, visited):
                        # If out of bounds or the cell is 0, return an empty string (no Mersenne number)
                        if i < 0 or i >= n or j < 0 or j >= n or grid[i][j] == 0:
                            return ""
                        
                        # Mark the current cell as visited by adding it to the visited set
                        visited = visited | {(i, j)} # frozen set is immutable so we need to create a new one
                        
                        max_mersenne_number_so_far = "1" # max so far is just the current cell's value
                        
                        # Explore all four possible directions: right, down, left, up
                        for x, y in [(0, 1), (1, 0), (0, -1), (-1, 0)]:
                            ni, nj = i + x, j + y
                            # Only consider valid and connected cells
                            if 0 <= ni < n and 0 <= nj < n and grid[ni][nj] == 1 and (ni, nj) not in visited:
                                # Get the largest Mersenne number from the neighbor
                                neighbor_number = get_largest_mersenne_number_starting_from(ni, nj, visited)
                                # Combine with the current value
                                combined_number = "1" + neighbor_number
                                # Update the current Mersenne number if the combined is larger
                                if max_mersenne_number_so_far < combined_number:
                                    max_mersenne_number_so_far = combined_number
                        
                        # Unmark the current cell by removing it from the visited set
                        visited = visited - {(i, j)}
                        
                        return max_mersenne_number_so_far
                    
                    # Get the largest Mersenne number starting from the top-left cell
                    largest_mersenne_number = get_largest_mersenne_number_starting_from(0, 0, frozenset())
                    
                    # Convert the binary string to a decimal number
                    mersenne_number_length = len(largest_mersenne_number)
                    return (1 << mersenne_number_length) - 1

                # Test cases
                grid1 = [[1]]
                expected_output1 = 1
                result1 = find_largest_mersenne_number(grid1)
                assert result1 == expected_output1, f"expected_output: {expected_output1}, output: {result1}"

                grid2 = [
                    [1, 1, 1],
                    [1, 0, 0],
                    [0, 0, 0]
                ]
                expected_output2 = 7
                result2 = find_largest_mersenne_number(grid2)
                assert result2 == expected_output2, f"expected_output: {expected_output2}, output: {result2}"

                grid3 = [
                    [1, 1, 0],
                    [1, 1, 0],
                    [1, 1, 1]
                ]
                expected_output3 = 127
                result3 = find_largest_mersenne_number(grid3)
                assert result3 == expected_output3, f"expected_output: {expected_output3}, output: {result3}"

                grid4 = [
                    [1, 1, 1, 1],
                    [1, 0, 0, 1],
                    [1, 0, 0, 1],
                    [1, 1, 1, 1]
                ]
                expected_output4 = 4095
                result4 = find_largest_mersenne_number(grid4)
                assert result4 == expected_output4, f"expected_output: {expected_output4}, output: {result4}"

                grid5 = [
                    [1, 1, 1, 1, 1],
                    [1, 0, 1, 0, 0],
                    [1, 0, 1, 0, 0],
                    [1, 1, 1, 0, 0],
                    [0, 0, 0, 0, 0]
                ]
                expected_output5 = 2047
                result5 = find_largest_mersenne_number(grid5)
                assert result5 == expected_output5, f"expected_output: {expected_output5}, output: {result5}"

                print("All test cases passed successfully.")
                `,
            },
        ]

// ! End of AMAZON
const GENERIC_LLD_INTERVIEW_ROUND_45_MINS_MODERATELY_CHALLENGING: Phase[] = [
    {
            id: 1,
            phaseTimeInMilliSeconds: 15 * 60 * 1000, // 15 minutes
            challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}
            \nNote that the focus at this stage is on the interface of the ATM system, not the implementation details.
            \nIt would be wise to carefully consider any data structures or helper functions that might be needed to support the core functionality of the ATM system.
            `,
            codeStub: 
            {
                value:`
            Design an ATM machine that can handle deposits and withdrawals. 

            Start by laying out the interface of the relevant objects and their methods.

            Here is a basic structure in pseudo-code to get you started:

            class ATM:

                function deposit(the_deposit) -> None:
                    # Deposit bills into the ATM
                
                function can_satisfy_withdrawal_request(amount) -> bool:
                    # Check if the ATM can satisfy a withdrawal request for the given amount

        `,
                isCode: false,
            },
            challengeType: ChallengeType.CODING,
            hints: [
                {
                    hint: "Consider an object-oriented approach to represent the ATM system"
                },
                {
                    hint: "In addition to the functions provided, are there any other helper functions or objects that might be needed?"
                },
                {
                    hint: "What data structures would be useful for tracking the reserves and the deposits?"
                },
            ],
            referenceSolutions: [
                {
                    referenceSolution: `
                    class ATM:
          
                        def __init__(self, reserves: Dict[int, int]):
                            self._reserves = # TODO: initialize the reserves
                            self._total_reserves = # TODO: calculate the total reserves
                            
                        def deposit(self, the_deposit: Dict[int, int]) -> None:
                            pass
                        
                        def _can_satisfy_withdrawal_request_with_single_bill_type(self, amount: int) -> bool:
                            pass

                        def _can_satisfy_withdrawal_request(self, amount: int) -> bool:
                            pass

                        def can_satisfy_withdrawal_request(self, amount: int) -> bool:
                            pass
                      `,
                    referenceSolutionIsCode: true,
                    referenceSolutionExplanationQuick: "An interface for the ATM class is provided, with methods for depositing bills and checking if a withdrawal request can be satisfied. The class structure is designed to handle the ATM's reserves and the withdrawal logic.",
                    referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
                },
            ],
            required: true,
            acceptableAnswer: `
            Any reasonable interface design that includes methods for depositing bills and checking if a withdrawal request can be satisfied would be acceptable.
            `,
        },
        {
            id: 2,
            phaseTimeInMilliSeconds: 30 * 60 * 1000, // 30 minutes
            challenge: "Now, implement the ATM class, feel free to adjust the interface as needed. I'm looking for optimal implementations of the core methods.",
            challengeType: ChallengeType.CODING,
            hints: [],
            referenceSolutions: [
                {
                    referenceSolution: `
                    from typing import Dict
                    class ATM:
                            
                        def __init__(self, reserves: Dict[int, int]):
                            self._reserves = reserves
                            self._total_reserves = sum([bill_type * count for bill_type, count in reserves.items()])
                            self._reserves_temp = reserves.copy()  # used for calculations
                            
                            # cache for memoization used to store results of recursive calls to avoid redundant computation
                            # entries are tuples
                            # e.g. (frozenset({5: 10, 10: 10, 20: 10, 50: 10}), 25): True means that the ATM can satisfy a withdrawal request for $25 with the given reserves
                            self._cache: Dict[frozenset, bool] = {} 
                            
                        def deposit(self, the_deposit: Dict[int, int]) -> None:
                            """
                            # Runtime Complexity: O(B) where B is the number of bill types supported by the ATM, if B is constant then O(1)
                            the_deposit is a dictionary where the key is the bill type and the value is the count of bills of that type to deposit e.g.
                            Dict[int, int] = {
                                5: 10,
                                10: 10,
                                20: 10,
                                50: 10
                            }
                            """
                            # Deposit bills into the ATM
                            for bill_type, count in the_deposit.items():
                                self._reserves[bill_type] += count
                                self._total_reserves += bill_type * count
                            self._reserves_temp = self._reserves.copy()  # reset the temp reserves to the current reserves
                        
                        def _can_satisfy_withdrawal_request_with_single_bill_type(self, amount: int) -> bool:
                            # Check if can satisfy withdrawal request with using 1 bill type (potentially multiple amounts)
                            # Runtime Complexity: O(B) where B is the number of bill types supported by the ATM, if B is constant then O(1)
                            for bill_type in self._reserves:
                                is_amount_multiple_of_bill_type: bool = (amount % bill_type == 0)
                                if (
                                    is_amount_multiple_of_bill_type and
                                    amount // bill_type <= self._reserves[bill_type]  # (amount // bill_type is the number of bills needed to satisfy the request
                                ):
                                    return True
                            return False

                        def _can_satisfy_withdrawal_request(self, amount: int) -> bool:
                            # Base case: if target_sum is 0, then the target is met
                            
                            # use memoization to avoid redundant computation
                            memo_key = (frozenset(self._reserves_temp.items()), amount)
                                
                            if memo_key in self._cache:
                                return self._cache[memo_key]
                                
                            if amount == 0:
                                return True

                            # Consider each possible first choice of bill type
                            for bill_type, count in self._reserves_temp.items():
                                if count > 0 and bill_type <= amount:
                                    # Reduce the count of the current bill type as we are considering it for this recursive step
                                    self._reserves_temp[bill_type] -= 1

                                    # Recursively call to check if the remaining sum can be matched
                                    found_matching_combination_of_bills = self._can_satisfy_withdrawal_request(amount - bill_type)
                                    
                                    # If successful, return True
                                    if found_matching_combination_of_bills:
                                        self._cache[memo_key] = True
                                        self._reserves_temp[bill_type] += 1
                                        return True

                                    # Restore the count of the current bill type for the next iteration
                                    self._reserves_temp[bill_type] += 1
                                    

                            # If logic reaches here, no combination was found, so return False
                            self._cache[memo_key] = False
                            return False

                        def can_satisfy_withdrawal_request(self, amount: int) -> bool:
                            # Check if can satisfy withdrawal request with using 1 bill type (potentially multiple amounts)
                            if amount > self._total_reserves: # impossible satisfy even if we distribute all the bills
                                return False
                            elif self._can_satisfy_withdrawal_request_with_single_bill_type(amount):  # O(B) which is O(1) if B is constant (e.g., 4 bill types)
                                return True
                            else:  # Try to satisfy with a combination of more than 1 bill type
                                return self._can_satisfy_withdrawal_request(amount)
                      `,
                    referenceSolutionIsCode: true,
                    referenceSolutionExplanationQuick: "Dynamic programming has been used to solve the problem of finding if a withdrawal request can be satisfied with the given reserves. Implementations for other methods have been done such that they are efficient and handle edge cases.",
                    referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
                },
            ],
            required: true,
            acceptableAnswer: `
            Reasonable solutions that leverage dynamic programming whether bottom-up or top-down to solve the problem of finding if a withdrawal request can be satisfied with the given reserves would be acceptable.
            Efficient solutions should be provided for the other methods as well.

            Below is an example of a possible solution in Python:
            from typing import Dict
            class ATM:
                    
                def __init__(self, reserves: Dict[int, int]):
                    self._reserves = reserves
                    self._total_reserves = sum([bill_type * count for bill_type, count in reserves.items()])
                    self._reserves_temp = reserves.copy()  # used for calculations
                    
                    # cache for memoization used to store results of recursive calls to avoid redundant computation
                    # entries are tuples
                    # e.g. (frozenset({5: 10, 10: 10, 20: 10, 50: 10}), 25): True means that the ATM can satisfy a withdrawal request for $25 with the given reserves
                    self._cache: Dict[frozenset, bool] = {} 
                    
                def deposit(self, the_deposit: Dict[int, int]) -> None:
                    """
                    # Runtime Complexity: O(B) where B is the number of bill types supported by the ATM, if B is constant then O(1)
                    the_deposit is a dictionary where the key is the bill type and the value is the count of bills of that type to deposit e.g.
                    Dict[int, int] = {
                        5: 10,
                        10: 10,
                        20: 10,
                        50: 10
                    }
                    """
                    # Deposit bills into the ATM
                    for bill_type, count in the_deposit.items():
                        self._reserves[bill_type] += count
                        self._total_reserves += bill_type * count
                    self._reserves_temp = self._reserves.copy()  # reset the temp reserves to the current reserves
                
                def _can_satisfy_withdrawal_request_with_single_bill_type(self, amount: int) -> bool:
                    # Check if can satisfy withdrawal request with using 1 bill type (potentially multiple amounts)
                    # Runtime Complexity: O(B) where B is the number of bill types supported by the ATM, if B is constant then O(1)
                    for bill_type in self._reserves:
                        is_amount_multiple_of_bill_type: bool = (amount % bill_type == 0)
                        if (
                            is_amount_multiple_of_bill_type and
                            amount // bill_type <= self._reserves[bill_type]  # (amount // bill_type is the number of bills needed to satisfy the request
                        ):
                            return True
                    return False

                def _can_satisfy_withdrawal_request(self, amount: int) -> bool:
                    # Base case: if target_sum is 0, then the target is met
                    
                    # use memoization to avoid redundant computation
                    memo_key = (frozenset(self._reserves_temp.items()), amount)
                        
                    if memo_key in self._cache:
                        return self._cache[memo_key]
                        
                    if amount == 0:
                        return True

                    # Consider each possible first choice of bill type
                    for bill_type, count in self._reserves_temp.items():
                        if count > 0 and bill_type <= amount:
                            # Reduce the count of the current bill type as we are considering it for this recursive step
                            self._reserves_temp[bill_type] -= 1

                            # Recursively call to check if the remaining sum can be matched
                            found_matching_combination_of_bills = self._can_satisfy_withdrawal_request(amount - bill_type)
                            
                            # If successful, return True
                            if found_matching_combination_of_bills:
                                self._cache[memo_key] = True
                                self._reserves_temp[bill_type] += 1
                                return True

                            # Restore the count of the current bill type for the next iteration
                            self._reserves_temp[bill_type] += 1
                            

                    # If logic reaches here, no combination was found, so return False
                    self._cache[memo_key] = False
                    return False

                def can_satisfy_withdrawal_request(self, amount: int) -> bool:
                    # Check if can satisfy withdrawal request with using 1 bill type (potentially multiple amounts)
                    if amount > self._total_reserves: # impossible satisfy even if we distribute all the bills
                        return False
                    elif self._can_satisfy_withdrawal_request_with_single_bill_type(amount):  # O(B) which is O(1) if B is constant (e.g., 4 bill types)
                        return True
                    else:  # Try to satisfy with a combination of more than 1 bill type
                        return self._can_satisfy_withdrawal_request(amount)
    `,
        },
    ]

const GENERIC_CODING_INTERVIEW_ROUND_25_MINS_MODERATELY_CHALLENGING_1: Phase[] = [
        {
                id: 1,
                phaseTimeInMilliSeconds: 25 * 60 * 1000, // 25 minutes
                challenge: `${PASTED_CODE_PROBLEM_INTERVIEWER_MSG}`,
                codeStub: {
                    value:`
Given an n x n binary matrix grid where cells consist of only 0s and 1s:

A group of interconnected 1s forms a Mersenne number.

Two 1s are considered connected if they are adjacent horizontally or vertically (not diagonally).

Find the largest Mersenne number in the grid.

Note that a Mersenne number is a sequence of one or more 1s that are connected.
In binary form, this could be: 1, 11, 111, 1111, etc.

Return the value of the largest Mersenne number within the grid in decimal form.
`,
                    isCode: false,
                },
                challengeType: ChallengeType.CODING,
                hints: [
                    {
                        hint: "could graph traversal algorithms be useful here?"
                    },
                ],
                referenceSolutions: [
                    {
                        referenceSolution: `
                        from typing import List, Optional

                        def find_largest_mersenne_number(grid: List[List[int]]) -> Optional[int]:
                            n = len(grid)
                            visited = [[False for _ in range(n)] for _ in range(n)]
                            
                            def dfs_count_connected_ones(x, y) -> int:
                                """Perform DFS to count the number of connected 1s."""
                                # Mark the cell as visited
                                visited[x][y] = True
                                count = 1
                                
                                # Possible directions of movement (horizontally and vertically)
                                directions = [(-1, 0), (1, 0), (0, -1), (0, 1)]
                                for dx, dy in directions:
                                    nx, ny = x + dx, y + dy
                                    if 0 <= nx < n and 0 <= ny < n and grid[nx][ny] == 1 and not visited[nx][ny]:
                                        count += dfs_count_connected_ones(nx, ny)
                                return count

                            max_length = 0
                            for i in range(n):
                                for j in range(n):
                                    if grid[i][j] == 1 and not visited[i][j]:
                                        # Perform DFS to get the length of the connected component of 1s
                                        connected_ones_length = dfs_count_connected_ones(i, j)
                                        max_length = max(max_length, connected_ones_length)
                            
                            # Calculate the largest Mersenne number
                            if max_length == 0:
                                return None
                            else:
                                largest_mersenne_number = (1 << max_length) - 1  # 2^max_length - 1
                                return largest_mersenne_number

                        # Test cases
                        grid0 = [
                            [0, 0, 0],
                            [0, 0, 0],
                            [0, 0, 0]
                        ]
                        expected_output0 = None
                        result0 = find_largest_mersenne_number(grid0)
                        assert result0 == expected_output0, f"expected_output: {expected_output0}, output: {result0}"

                        grid1 = [
                            [1]
                        ]
                        expected_output1 = 1
                        result1 = find_largest_mersenne_number(grid1)
                        assert result1 == expected_output1, f"expected_output: {expected_output1}, output: {result1}"

                        grid2 = [
                            [1, 1, 1],
                            [1, 0, 0],
                            [0, 0, 0]
                        ]
                        expected_output2 = 15
                        result2 = find_largest_mersenne_number(grid2)
                        assert result2 == expected_output2, f"expected_output: {expected_output2}, output: {result2}"

                        grid3 = [
                            [1, 1, 0],
                            [1, 1, 0],
                            [1, 1, 1]
                        ]
                        expected_output3 = 127
                        result3 = find_largest_mersenne_number(grid3)
                        assert result3 == expected_output3, f"expected_output: {expected_output3}, output: {result3}"

                        grid4 = [
                            [1, 1, 1, 1],
                            [1, 0, 0, 1],
                            [1, 0, 0, 1],
                            [1, 1, 1, 1]
                        ]
                        expected_output4 = 4095
                        result4 = find_largest_mersenne_number(grid4)
                        assert result4 == expected_output4, f"expected_output: {expected_output4}, output: {result4}"

                        print("All test cases passed successfully.")

                          `,
                        referenceSolutionIsCode: true,
                        referenceSolutionExplanationQuick: "The solution involves performing a depth-first search (DFS) to count the number of connected 1s in the grid. The largest Mersenne number is then calculated based on the length of the largest connected component of 1s.",
                        referenceSolutionProgrammingLanguage: ProgrammingLanguage.Python,
                    },
                ],
                required: true,
                acceptableAnswer: `
                Any reasonable solution that correctly identifies the largest Mersenne number in the grid would be acceptable such as:

                from typing import List, Optional

                def find_largest_mersenne_number(grid: List[List[int]]) -> Optional[int]:
                    n = len(grid)
                    visited = [[False for _ in range(n)] for _ in range(n)]
                    
                    def dfs_count_connected_ones(x, y) -> int:
                        """Perform DFS to count the number of connected 1s."""
                        # Mark the cell as visited
                        visited[x][y] = True
                        count = 1
                        
                        # Possible directions of movement (horizontally and vertically)
                        directions = [(-1, 0), (1, 0), (0, -1), (0, 1)]
                        for dx, dy in directions:
                            nx, ny = x + dx, y + dy
                            if 0 <= nx < n and 0 <= ny < n and grid[nx][ny] == 1 and not visited[nx][ny]:
                                count += dfs_count_connected_ones(nx, ny)
                        return count

                    max_length = 0
                    for i in range(n):
                        for j in range(n):
                            if grid[i][j] == 1 and not visited[i][j]:
                                # Perform DFS to get the length of the connected component of 1s
                                connected_ones_length = dfs_count_connected_ones(i, j)
                                max_length = max(max_length, connected_ones_length)
                    
                    # Calculate the largest Mersenne number
                    if max_length == 0:
                        return None
                    else:
                        largest_mersenne_number = (1 << max_length) - 1  # 2^max_length - 1
                        return largest_mersenne_number

                # Test cases
                grid0 = [
                    [0, 0, 0],
                    [0, 0, 0],
                    [0, 0, 0]
                ]
                expected_output0 = None
                result0 = find_largest_mersenne_number(grid0)
                assert result0 == expected_output0, f"expected_output: {expected_output0}, output: {result0}"

                grid1 = [
                    [1]
                ]
                expected_output1 = 1
                result1 = find_largest_mersenne_number(grid1)
                assert result1 == expected_output1, f"expected_output: {expected_output1}, output: {result1}"

                grid2 = [
                    [1, 1, 1],
                    [1, 0, 0],
                    [0, 0, 0]
                ]
                expected_output2 = 15
                result2 = find_largest_mersenne_number(grid2)
                assert result2 == expected_output2, f"expected_output: {expected_output2}, output: {result2}"

                grid3 = [
                    [1, 1, 0],
                    [1, 1, 0],
                    [1, 1, 1]
                ]
                expected_output3 = 127
                result3 = find_largest_mersenne_number(grid3)
                assert result3 == expected_output3, f"expected_output: {expected_output3}, output: {result3}"

                grid4 = [
                    [1, 1, 1, 1],
                    [1, 0, 0, 1],
                    [1, 0, 0, 1],
                    [1, 1, 1, 1]
                ]
                expected_output4 = 4095
                result4 = find_largest_mersenne_number(grid4)
                assert result4 == expected_output4, f"expected_output: {expected_output4}, output: {result4}"

                print("All test cases passed successfully.")

                `,
            },
        ]
/*
SOFTWARE_ENGINEER_INTERVIEW_SCRIPTS_BY_COMPANY[<companyName>][<interviewId>] => InterviewScript
*/
export const TECH_INTERVIEW_SCRIPTS_BY_COMPANY: Map<string, InterviewScript[]> = new Map([
    [
        CompanyName.GENERIC_TECH_COMPANY,
        [
            InterviewScript.fromObject({
                interviewId: 0,
                interviewName: "Python Developer(I)",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.SIXTY_MINUTES,
                restrictedProgrammingLanguage: ProgrammingLanguage.Python,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: INTERVIEW_PHASES_PYTHON_DEVELOPER_Moderately_Challenging_60_MINUTES
            }),
            InterviewScript.fromObject({
                interviewId: 1,
                interviewName: "C++ Developer(I)",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.SIXTY_MINUTES,
                restrictedProgrammingLanguage: ProgrammingLanguage.Cpp,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: INTERVIEW_PHASES_CPP_DEVELOPER_Moderately_Challenging_60_MINUTES
            }),
            InterviewScript.fromObject({
                interviewId: 2,
                interviewName: "SWE Coding 1",
                difficulty: InterviewDifficulty.Straightforward,
                requiredTimeInMinutes: InterviewDurations.TWENTY_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: INTERVIEW_PHASES_Straight_Forward_20_MINUTES
            }),
            InterviewScript.fromObject({
                interviewId: 3,
                interviewName: "SWE Coding 2",
                difficulty: InterviewDifficulty.Straightforward,
                requiredTimeInMinutes: InterviewDurations.SIXTY_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: INTERVIEW_PHASES_Straight_Forward_60_MINUTES
            }),
            InterviewScript.fromObject({
                interviewId: 4,
                interviewName: "SWE Coding 3",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.TWENTY_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: INTERVIEW_PHASES_Moderately_Challenging_20_MINUTES
            }),
            InterviewScript.fromObject({
                interviewId: 5,
                interviewName: "SWE Coding 4",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.SIXTY_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: INTERVIEW_PHASES_Moderately_Challenging_60_MINUTES
            }),
            InterviewScript.fromObject({
                interviewId: 6,
                interviewName: "Low-level Design I",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: GENERIC_LLD_INTERVIEW_ROUND_45_MINS_MODERATELY_CHALLENGING,
            }),
            InterviewScript.fromObject({
                interviewId: 7,
                interviewName: "SWE Coding 5",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.TWENTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GENERIC_TECH_COMPANY,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: GENERIC_CODING_INTERVIEW_ROUND_25_MINS_MODERATELY_CHALLENGING_1,
            }),
        ]
    ],// end of map entry

    [
        CompanyName.GOOGLE,
        [
            InterviewScript.fromObject({
                interviewId: 0,
                interviewName: "Google Round 1",
                difficulty: InterviewDifficulty.Challenging,
                requiredTimeInMinutes: InterviewDurations.SIXTY_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.GOOGLE,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: GOOGLE_INTERVIEW_Round_1_60_MINS_CHALLENGING,
            }),
        ]
    ],// end of map entry
    [
        CompanyName.META,
        [
            InterviewScript.fromObject({
                interviewId: 0,
                interviewName: "Coding I",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.THIRTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Telephone,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: META_INTERVIEW_Round_1_35_MINS_MODERATELY_CHALLENGING,
            }),
            
            InterviewScript.fromObject({
                interviewId: 1,
                interviewName: "Prod Engr Coding",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.PRODUCTION_ENGINEER,
                phases: META_PROD_ENGR_CODING_INTERVIEW_ROUND_45_MINS_MODERATELY_CHALLENGING,
            }),

            // InterviewScript.fromObject({
            //     interviewId: 2,
            //     interviewName: "Prod Engr Basics",
            //     difficulty: InterviewDifficulty.ModeratelyChallenging,
            //     requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
            //     interviewStage: InterviewStage.Onsite,
            //     interviewType: InterviewType.TECHNICAL,
            //     company: CompanyName.META,
            //     roleType: RoleTypeName.PRODUCTION_ENGINEER,
            //     phases: [], //TODO
            // }),

            InterviewScript.fromObject({
                interviewId: 3,
                interviewName: "Prod Engr Systems I",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.PRODUCTION_ENGINEER,
                phases: META_PROD_ENGR_SYSTEMS_INTERVIEW_1,
            }),
            InterviewScript.fromObject({
                interviewId: 4,
                interviewName: "Prod Engr Systems II",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.PRODUCTION_ENGINEER,
                phases: META_PROD_ENGR_SYSTEMS_INTERVIEW_2,
            }),
            InterviewScript.fromObject({
                interviewId: 5,
                interviewName: "Prod Engr Systems III",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.PRODUCTION_ENGINEER,
                phases: META_PROD_ENGR_SYSTEMS_INTERVIEW_3,
            }),
            InterviewScript.fromObject({
                interviewId: 6,
                interviewName: "Prod Engr Systems IV",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.PRODUCTION_ENGINEER,
                phases: META_PROD_ENGR_SYSTEMS_INTERVIEW_4,
            }),
            InterviewScript.fromObject({
                interviewId: 7,
                interviewName: "Prod Engr Systems V",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.PRODUCTION_ENGINEER,
                phases: META_PROD_ENGR_SYSTEMS_INTERVIEW_5,
            }),
            InterviewScript.fromObject({
                interviewId: 8,
                interviewName: "Prod Engr Systems VI",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.META,
                roleType: RoleTypeName.PRODUCTION_ENGINEER,
                phases: META_PROD_ENGR_SYSTEMS_INTERVIEW_6,
            }),

            
        ]
    ],// end of map entry
    [
        CompanyName.AMAZON,
        [
            InterviewScript.fromObject({
                interviewId: 0,
                interviewName: "Data Structures and Algos I",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.TWENTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.AMAZON,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: AMAZON_INTERVIEW_Round_25_MINS_MODERATELY_CHALLENGING,
            }),
            InterviewScript.fromObject({
                interviewId: 1,
                interviewName: "Logical and Maintainable I",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.THIRTY_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.AMAZON,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: AMAZON_LLD_INTERVIEW_ROUND_30_MINS_MODERATELY_CHALLENGING,
            }),
            InterviewScript.fromObject({
                interviewId: 2,
                interviewName: "Logical and Maintainable II",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.FORTY_FIVE_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.AMAZON,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: GENERIC_LLD_INTERVIEW_ROUND_45_MINS_MODERATELY_CHALLENGING,
            }),
            InterviewScript.fromObject({
                interviewId: 3,
                interviewName: "Data Structures and Algos II",
                difficulty: InterviewDifficulty.ModeratelyChallenging,
                requiredTimeInMinutes: InterviewDurations.THIRTY_MINUTES,
                interviewStage: InterviewStage.Onsite,
                interviewType: InterviewType.TECHNICAL,
                company: CompanyName.AMAZON,
                roleType: RoleTypeName.SOFTWARE_ENGINEER,
                phases: AMAZON_CODING_INTERVIEW_ROUND_30_MINS_MODERATELY_CHALLENGING_I,
            }),
        ]
    ],// end of map entry
]) // end of map
