import React, { useState, useEffect } from "react";
import "../css/CodeEditorBox.css";
import CountdownTimer from "./CountdownTimer";
import CodeEditor from "../CodeEditor";
import {
  CodeBlockByLanguage,
  LEARNING_PATH_COURSE_ID,
  LEARNING_PATH_MODULE_ID,
  LEARNING_PATH_SUBMODULE_ID,
  Language,
  SIGN_UP_PAGE_PATH,
} from "../utils/Constants";
import {
  isUserSignedIn,
  mapLanguagetoCodeBlockLanguageLabel,
} from "../utils/HelperFunctions";
import { logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faLock, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

const MAX_RUN_COUNT: number = Infinity;

const CODE_EDITOR_HEIGHT_IN_PX_DEFAULT: Readonly<number> = 440;
// A mapping object for language display name
const LANGUAGE_DISPLAY_NAMES = {
  // NITO: ideally should eliminae need for this and have single enum class used for all things to do with programming languages. Worst case single class that encapusaltes all programming language related affairs
  // NITO-language-single-source
  [Language.Python]: "Python",
  [Language["C++"]]: "C++",
  [Language.JavaScript]: "JavaScript",
  [Language.Java]: "Java",
  [Language["C#"]]: "C#",
  [Language.Go]: "Go",
  [Language.C]: "C",
  [Language.Typescript]: "TypeScript",
  [Language.Kotlin]: "Kotlin",
  [Language.Swift]: "Swift",
};

const ENABLED_LANGUAGES_FOR_CODE_EXECUTION = new Set([
  Language.Python,
  Language['C++'],
  Language.JavaScript,
  Language.Java,
  Language['C#'],
  Language.Go,
  Language.C,
  Language.Typescript,
  Language.Kotlin,
  Language.Swift,
  /* other allowed languages */
]);

export interface CodeEditorSettings {
  disabled: boolean;
  isSubmissionDisabled: boolean;
  isRunningDisabled: boolean;
  programmingLanguage: Language;
  setProgrammingLanguage: (language: Language) => void;
  isAssessment: boolean;
  timeLimitInMinutes: number;
  isTimerDisabled: boolean;
  isTimerRunning: boolean;
  setIsTimerRunning: (isTimerRunning: boolean) => void;
  programmingLanguageWhiteList: Set<Language> | null; // if this is set, then only languages in this set are allowed otherwise default enabled languages are allowed
  shouldPersistCode: boolean;
}

interface CodeEditorBoxProps {
  userId: number;
  codeEditorValue: string;
  setCodeEditorValue: (code: string) => void;
  codeBlockByLanguage?: CodeBlockByLanguage;
  timerComplete: () => void;
  timerKey?: number;
  unit_id: number;
  codeSubmissionHandler: (code: string, language: Language) => Promise<void>;
  codeRunHandler: (code: string, language: Language) => Promise<void>;
  rightAnswer?: boolean | null;
  handleUnitAdvance?: () => void;
  codeEditorSettings: CodeEditorSettings;
  maxRuns?: number;
  onLoad?: () => void;
}

const CodeEditorBox: React.FC<CodeEditorBoxProps> = ({
  userId,
  codeEditorValue,
  setCodeEditorValue,
  codeSubmissionHandler,
  codeRunHandler,
  rightAnswer,
  unit_id,
  codeBlockByLanguage,
  timerComplete,
  handleUnitAdvance,
  timerKey = 0,
  codeEditorSettings,
  maxRuns = MAX_RUN_COUNT,
  onLoad,
}) => {
  const time_limit_in_seconds = codeEditorSettings.timeLimitInMinutes * 60;
  const [previousProgrammingLanguage, setPreviousProgrammingLanguage] =
    useState<Language | null>(codeEditorSettings.programmingLanguage);
  const [savedCodeEditorValue, setSavedCodeEditorValue] =
    useState<CodeBlockByLanguage>(
      codeBlockByLanguage || ({} as CodeBlockByLanguage)
    );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const navigate = useNavigate();
  const [runCount, setRunCount] = useState(0);
  const [isMounted, setIsMounted] = useState(true);

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const selectedLanguage = event.target.value as Language;
    if (selectedLanguage !== codeEditorSettings.programmingLanguage) {
      codeEditorSettings.setProgrammingLanguage(selectedLanguage);
    }
  };

  const handleClickSubmit = async () => {
    if (!isUserSignedIn(userId)) {//TODO: move this logic out of here, submission needn't know about user sign in status
      logToBackendLogFile(
        "user denied 'code submission' access until sign-in",
        "info"
      );
      navigate(SIGN_UP_PAGE_PATH);
      return;
    }
    setIsSubmitting(true);

    try {
      await codeSubmissionHandler(
        codeEditorValue,
        codeEditorSettings.programmingLanguage
      );

      if (codeEditorSettings.isAssessment && handleUnitAdvance) {
        handleUnitAdvance();
      }
    } catch (error) {
      // Handle the error as needed, perhaps show an error message to the user
      console.error("Error during code submission:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update previous programming language
  useEffect(() => {
    setPreviousProgrammingLanguage(codeEditorSettings.programmingLanguage);
    // Get code for the selected language
    let codeForSelectedLanguage: string = "";
    if (savedCodeEditorValue) {
      const mappedSelectedLanguage = mapLanguagetoCodeBlockLanguageLabel(
        codeEditorSettings.programmingLanguage
      );
      codeForSelectedLanguage = savedCodeEditorValue[mappedSelectedLanguage];
    }

    // Save the code of the previous language
    let codeBlockToSave = savedCodeEditorValue;
    if (previousProgrammingLanguage) {
      const mappedPreviousProgrammingLanguage =
        mapLanguagetoCodeBlockLanguageLabel(previousProgrammingLanguage);
      codeBlockToSave[mappedPreviousProgrammingLanguage] = codeEditorValue;
    } else {
      const mappedProgrammingLanguage = mapLanguagetoCodeBlockLanguageLabel(
        codeEditorSettings.programmingLanguage
      );
      codeBlockToSave[mappedProgrammingLanguage] = codeEditorValue;
    }
    setSavedCodeEditorValue(codeBlockToSave);

    // Set the code to the selected language
    setCodeEditorValue(codeForSelectedLanguage);
  }, [codeEditorSettings.programmingLanguage]);

  // Reset the code editor when code block changes
  useEffect(() => {
    let newCodeBlock = "";
    if (codeBlockByLanguage) {
      if (previousProgrammingLanguage) {
        const mappedPreviousProgrammingLanguage =
          mapLanguagetoCodeBlockLanguageLabel(previousProgrammingLanguage);
        newCodeBlock = codeBlockByLanguage[mappedPreviousProgrammingLanguage];
      } else if (codeEditorSettings.programmingLanguage) {
        const mappedProgrammingLanguage = mapLanguagetoCodeBlockLanguageLabel(
          codeEditorSettings.programmingLanguage
        );
        newCodeBlock = codeBlockByLanguage[mappedProgrammingLanguage];
      } else newCodeBlock = codeBlockByLanguage.Python;
    }
    setCodeEditorValue(newCodeBlock);
    setSavedCodeEditorValue((prev) => ({
      ...prev,
      ...codeBlockByLanguage,
    }));
    setPreviousProgrammingLanguage(null);
  }, [codeBlockByLanguage]);
  // Reset the code editor when code block changes

  if (codeEditorSettings.disabled) {
    return null;
  }

  const isProgrammingLanguageEnabled = (language: Language): boolean => {
    return (
      codeEditorSettings.programmingLanguageWhiteList?.has(language) ||
      ENABLED_LANGUAGES_FOR_CODE_EXECUTION.has(language)
    );
  };

  useEffect(() => {
    setIsMounted(true);

    onLoad && onLoad();

    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleRunCode = async () => {
    if (!isUserSignedIn(userId)) {
      logToBackendLogFile(
        "user denied 'code execution' access until sign-in",
        "info"
      );
      navigate(SIGN_UP_PAGE_PATH);
      return;
    }

    setIsRunning(true);
    try {
      await codeRunHandler(codeEditorValue, codeEditorSettings.programmingLanguage);
      setRunCount(prevRunCount => prevRunCount + 1);
    } catch (error) {
      console.error("Error during code execution:", error);
    } finally {
      setIsRunning(false);
    }
  };

  const handleResetCode = () => {
    let defaultCodeBlock = "";
    if (codeBlockByLanguage) {
      const mappedProgrammingLanguage = mapLanguagetoCodeBlockLanguageLabel(
        codeEditorSettings.programmingLanguage
      );
      defaultCodeBlock = codeBlockByLanguage[mappedProgrammingLanguage];
    }
    setCodeEditorValue(defaultCodeBlock);
  };

  return (
    <div className="code-editor-box">
      <div className="code-editor-navbar">
        <select
          className="language-selector"
          value={codeEditorSettings.programmingLanguage}
          onChange={handleLanguageChange}
        >
          {Object.values(Language).map((language) => (
            <option
              value={language}
              key={language}
              disabled={!isProgrammingLanguageEnabled(language)}
            >
              {LANGUAGE_DISPLAY_NAMES[language] || "Unknown"}
            </option>
          ))}
        </select>
        <div className="timer-submit-container">
          <div
            className={`code-editor-timer ${codeEditorSettings.isAssessment ? "extra-margin-right" : ""
              }`}
          >
            {!rightAnswer && (
              <CountdownTimer
                isTimerRunning={codeEditorSettings.isTimerRunning}
                setIsTimerRunning={codeEditorSettings.setIsTimerRunning}
                disabled={codeEditorSettings.isTimerDisabled}
                unit_id={unit_id}
                onTimerComplete={timerComplete}
                duration={time_limit_in_seconds}
                disablePause={codeEditorSettings.isAssessment}
                key={timerKey}
              />
            )}
          </div>

          <CodeEditorActionsToolbar
            codeEditorSettings={codeEditorSettings}
            handleResetCode={handleResetCode}
            handleRunCode={handleRunCode}
            handleClickSubmit={handleClickSubmit}
            isRunning={isRunning}
            isSubmitting={isSubmitting}
            runCount={runCount}
            maxRuns={maxRuns}
          />
        </div>
      </div>

      <CodeEditor
        value={codeEditorValue}
        language={codeEditorSettings.programmingLanguage}
        onChange={(value: string) => setCodeEditorValue(value)}
        userId={""} //TODO MAJOR: set actual userId once it is no longer being randomly generated
        taskId={`${LEARNING_PATH_COURSE_ID}-${LEARNING_PATH_MODULE_ID}-${LEARNING_PATH_SUBMODULE_ID}-${unit_id}`}
        shouldPersistCode={codeEditorSettings.shouldPersistCode}
      //TODO MAJOR: replace with actual taskId in future
      />
    </div>
  );
};


// SubmitButton.tsx
interface SubmitButtonProps {
  isSubmitting: boolean;
  isSubmissionDisabledConfig: boolean;
  handleClickSubmit: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  isSubmissionDisabledConfig,
  handleClickSubmit,
}) => (
  <button
    color="primary"
    className="submit-button"
    onClick={handleClickSubmit}
    disabled={isSubmissionDisabledConfig || isSubmitting}
    style={{ opacity: isSubmissionDisabledConfig || isSubmitting ? 0.5 : 1 }} // Inline style for greying out
    title={isSubmissionDisabledConfig ? "submission is disabled" : "Submit code"} // Tooltip for button
  >
    {isSubmitting ? "Submitting..." : "Submit"}
    <FontAwesomeIcon
      icon={isSubmissionDisabledConfig ? faLock : faPlay} // Conditional icon
      style={{ fontSize: "1em", paddingLeft: "10px" }}
    />
  </button>
);


// RunButton.tsx
interface RunButtonProps {
  isRunning: boolean;
  isRunButtonDisabledConfig: boolean
  handleRunCode: () => void;
  runCount: number;
  maxRuns: number;
}

const RunButton: React.FC<RunButtonProps> = ({
  isRunning,
  isRunButtonDisabledConfig,
  handleRunCode,
  runCount,
  maxRuns,
}) => {

  const getReasonForDisablement = () => {
    if (isRunButtonDisabledConfig) {
      return "running code is disabled";
    }
    else if (runCount >= maxRuns) {
      return "Maximum number of runs reached";
    } else if (isRunning) {
      return "Execution disabled while running";
    }
    else {
      return "button disabled";
    }
  };

  return (
    <button
      className="run-button"
      onClick={handleRunCode}
      disabled={isRunButtonDisabledConfig || isRunning || runCount >= maxRuns}
      style={{ opacity: isRunButtonDisabledConfig || isRunning ? 0.5 : 1 }} // Inline style for greying out
      title={isRunButtonDisabledConfig ? getReasonForDisablement() : "Run code"} // Tooltip for button
    >
      <span>{isRunning ? "Running..." : "Run"}
        <FontAwesomeIcon
          icon={isRunButtonDisabledConfig ? faLock : faPlay} // Conditional icon
          style={{ fontSize: "1em", paddingLeft: "10px" }}
        />
      </span>
    </button>
  );
};

interface CodeEditorActionsToolbarProps {
  codeEditorSettings: CodeEditorSettings; // Specify a more detailed type based on the structure of codeEditorSettings
  handleResetCode: () => void;

  runCount: number;
  maxRuns: number;
  isRunning: boolean;
  handleRunCode: () => void;

  isSubmitting: boolean;
  handleClickSubmit: () => void;
}

const CodeEditorActionsToolbar: React.FC<CodeEditorActionsToolbarProps> = ({
  codeEditorSettings,
  handleResetCode,
  handleRunCode,
  handleClickSubmit,
  isSubmitting,
  isRunning,
  runCount,
  maxRuns,
}) => (
  <div className="code-nav-btns">
    <Tooltip title="Reset Code">
      <button
        className="reset-button"
        onClick={handleResetCode}
      >
        <FontAwesomeIcon
          icon={faUndo}
          style={{ fontSize: "1em" }}
        />
      </button>
    </Tooltip>

    <RunButton
      isRunning={isRunning}
      isRunButtonDisabledConfig={codeEditorSettings.isRunningDisabled}
      handleRunCode={handleRunCode}
      runCount={runCount}
      maxRuns={maxRuns}
    />

    <SubmitButton
      isSubmitting={isSubmitting}
      isSubmissionDisabledConfig={codeEditorSettings.isSubmissionDisabled}
      handleClickSubmit={handleClickSubmit}
    />
  </div>
);





export default CodeEditorBox;
