import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Menu, MenuItem } from "@mui/material";
import { Tooltip } from "react-tooltip";
import "../css/Navbar.css";
import {
  BLOG_PAGE_PATH,
  START_MENU_PAGE_PATH,
  LEARNING_PAGE_PATH,
  SIGN_IN_PAGE_PATH,
  SIGN_UP_PAGE_PATH,
  CODITIONING_LOGO_URL,
  DIAGNOSTICS_PAGE_PATH,
  MOCK_INTERVIEW_PAGE_PATH,
  SUBSCRIPTIONS_PAGE_PATH,
  CV_SERVICES_PAGE_PATH,
  JOBS_PAGE_PATH,
  CAREERS_PAGE_PATH,
  HOME_PAGE_PATH,
} from "../utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import Modal from "../utils/Modal";
import FeedbackPage from "./FeedbackPage";
import { useNavigate } from "react-router-dom";
import { DiscountBannerButton } from "@/utils/BannerButton";

// Define reusable types and constants
export interface DropdownOption {
  label: string;
  url: string;
}

interface NavItemProps {
  urlPath?: string;
  labelText: React.ReactNode;
  className: string;
  beta?: boolean;
  dropdownOptions?: DropdownOption[];
  onClick?: () => void;
}

export enum NavItemClass {
  ACTIVE = "active",
  BUTTON = "button",
  SITE_LOGO = "site-logo-container",
}

interface NavBarProps {
  signedIn: boolean;
  handleSignOut: () => void;
  handleSettingsClick: () => void;
  handleProfileClick: () => void;
}

const NavBarItem: React.FC<NavItemProps> = ({
  urlPath,
  labelText,
  className,
  beta = false,
  dropdownOptions,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDropdownOpen = Boolean(anchorEl);
  const location = useLocation();

  const handleDropdownToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  // Render dropdown item
  if (dropdownOptions) {
    return (
      <li className={`${className} dropdown-trigger`}>
        <Link
          to="#"
          className={`button ${isDropdownOpen ? NavItemClass.ACTIVE : ""}`}
          aria-haspopup="menu"
          aria-expanded={isDropdownOpen}
          aria-controls="dropdown-menu"
          onClick={(event) => {
            event.preventDefault();
            handleDropdownToggle(event);
          }}
        >
          {labelText}
        </Link>
        <Menu
          anchorEl={anchorEl}
          open={isDropdownOpen}
          onClose={handleDropdownClose}
          MenuListProps={{ "aria-labelledby": "dropdown-menu" }}
        >
          {dropdownOptions.map((option, index) => (
            <MenuItem key={index} onClick={handleDropdownClose}>
              <Link to={option.url} className="dropdown-item">
                {option.label}
              </Link>
            </MenuItem>
          ))}
        </Menu>
        {beta && <span className="beta-label">BETA</span>}
      </li>
    );
  }

  // Render standard item
  return (
    <li
      className={`${className} ${
        urlPath === location.pathname ? NavItemClass.ACTIVE : ""
      }`}
    >
      <Link to={urlPath || "#"} className="button" onClick={onClick}>
        {labelText}
      </Link>
      {beta && <span className="beta-label">BETA</span>}
    </li>
  );
};

const NavBar: React.FC<NavBarProps> = ({
  signedIn,
  handleSignOut,
  handleSettingsClick,
  handleProfileClick,
}) => {
  const [state, setState] = useState({
    isFeedbackModalVisible: false,
    isMenuOpen: false,
  });

  const [isSignedIn, setIsSignedIn] = useState(signedIn);

  useEffect(() => {
    setIsSignedIn(signedIn);
  }, [signedIn]);

  const toggleState = (key: keyof typeof state) => {
    setState((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const navigate = useNavigate();
  const handleStartJourney = () => {
    navigate(START_MENU_PAGE_PATH);
  };

  const handleSignOutClick = () => {
    setIsSignedIn(false);
    handleSignOut();
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case HOME_PAGE_PATH:
        return "Home";
      case MOCK_INTERVIEW_PAGE_PATH:
        return "🔍 Mock Interviews & Coaching";
      case DIAGNOSTICS_PAGE_PATH:
        return "🩺 Diagnostics";
      case LEARNING_PAGE_PATH:
        return "Tailored Learning";
      case CV_SERVICES_PAGE_PATH:
        return "CV Optimization";
      case BLOG_PAGE_PATH:
        return "Blog";
      case CAREERS_PAGE_PATH:
        return "Careers";
      case JOBS_PAGE_PATH:
        return "Job Boards";
      case SIGN_UP_PAGE_PATH:
        return "Sign Up";
      case SIGN_IN_PAGE_PATH:
        return "Log In";
      case SUBSCRIPTIONS_PAGE_PATH:
        return "Subscription Details";
      default:
        return "";
    }
  };

  return (
    <>
      <nav className={`navbar ${isHomePage ? "home" : ""}`}>
        <ul>
          <NavBarItem
            urlPath="/"
            labelText={<img src={CODITIONING_LOGO_URL} alt="Logo" />}
            className={NavItemClass.SITE_LOGO}
          />
          <NavBarItem
            urlPath={MOCK_INTERVIEW_PAGE_PATH}
            labelText="🔍Mock Interviews & Coaching"
            className={NavItemClass.BUTTON}
          />
          <NavBarItem
            urlPath={DIAGNOSTICS_PAGE_PATH}
            labelText="🩺 Diagnostics"
            className={NavItemClass.BUTTON}
          />
          <NavBarItem
            urlPath={LEARNING_PAGE_PATH}
            labelText="Learn"
            className={NavItemClass.BUTTON}
            beta
          />
          <NavBarItem
            urlPath={CV_SERVICES_PAGE_PATH}
            labelText="CV Optimization"
            className={NavItemClass.BUTTON}
          />
          <NavBarItem
            urlPath={BLOG_PAGE_PATH}
            labelText="Blog"
            className={NavItemClass.BUTTON}
          />
          <NavBarItem
            labelText="Jobs"
            className={NavItemClass.BUTTON}
            dropdownOptions={[
              { label: "Careers", url: CAREERS_PAGE_PATH },
              { label: "Job Boards", url: JOBS_PAGE_PATH },
            ]}
          />
          <li className={NavItemClass.BUTTON}>
            <a
              onClick={() => toggleState("isFeedbackModalVisible")}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faCommentDots} /> Feedback
            </a>
          </li>
          {!isSignedIn ? (
            <NavBarItem
              urlPath={SIGN_UP_PAGE_PATH}
              labelText={
                <>
                  <AutoAwesomeIcon style={{ marginRight: "5px" }} />
                  Sign Up
                </>
              }
              className={NavItemClass.BUTTON}
            />
          ) : (
            <NavBarItem
              urlPath={SUBSCRIPTIONS_PAGE_PATH}
              labelText={
                <>
                  <AccountCircleIcon style={{ marginRight: "5px" }} />
                  Subscription
                </>
              }
              className={NavItemClass.BUTTON}
            />
          )}
          {!isSignedIn ? (
            <NavBarItem
              urlPath={SIGN_IN_PAGE_PATH}
              labelText="Log In"
              className={`${NavItemClass.BUTTON} logIn`}
            />
          ) : (
            <NavBarItem
              labelText="Sign Out"
              className={`${NavItemClass.BUTTON} signOut`}
              onClick={handleSignOutClick}
            />
          )}
        </ul>
        {isHomePage && !state.isFeedbackModalVisible && (
          <div
            className="actionBtn animatedBorder"
            onClick={handleStartJourney}
          >
            Start Journey
          </div>
        )}
        <div className="icons">
          <SettingsIcon
            className="icon disabled-icon"
            onClick={handleSettingsClick}
            data-tooltip-id="settings-tooltip"
            data-tooltip-content="Settings"
          />
          <AccountCircleIcon
            className="icon disabled-icon"
            onClick={handleProfileClick}
            data-tooltip-id="profile-tooltip"
            data-tooltip-content="View your profile"
          />
        </div>
        <Tooltip id="profile-tooltip" />
        <Tooltip id="settings-tooltip" />
      </nav>
      <DiscountBannerButton />
      <nav className={`mobileNav ${isHomePage ? "home" : ""}`}>
        
        <ul>
          <NavBarItem
            urlPath="/"
            labelText={<img src={CODITIONING_LOGO_URL} alt="Logo" />}
            className={NavItemClass.SITE_LOGO}
          />
          <div className="mobile-title">{getPageTitle()}</div>
          <div className="hamburger-menu">
            <div
              className={`hamburger-icon ${state.isMenuOpen ? "open" : ""}`}
              onClick={() => toggleState("isMenuOpen")}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              className={`hamburger-links ${state.isMenuOpen ? "open" : ""}`}
              onClick={() => toggleState("isMenuOpen")}
            >
              <NavBarItem
                urlPath={MOCK_INTERVIEW_PAGE_PATH}
                labelText="🔍 Mock Interviews"
                className={NavItemClass.BUTTON}
              />
              <NavBarItem
                urlPath={DIAGNOSTICS_PAGE_PATH}
                labelText="🩺 Diagnostics"
                className={NavItemClass.BUTTON}
              />
              <NavBarItem
                urlPath={LEARNING_PAGE_PATH}
                labelText="Tailored Learning"
                className={NavItemClass.BUTTON}
              />
              <NavBarItem
                urlPath={CV_SERVICES_PAGE_PATH}
                labelText="CV Optimization"
                className={NavItemClass.BUTTON}
              />
              <NavBarItem
                urlPath={BLOG_PAGE_PATH}
                labelText="Blog"
                className={NavItemClass.BUTTON}
              />
              <NavBarItem
                urlPath={CAREERS_PAGE_PATH}
                labelText="Careers"
                className={NavItemClass.BUTTON}
              />
              <NavBarItem
                urlPath={JOBS_PAGE_PATH}
                labelText="Job Boards"
                className={NavItemClass.BUTTON}
              />
              <li className={NavItemClass.BUTTON}>
                <a
                  onClick={() => toggleState("isFeedbackModalVisible")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faCommentDots} /> Feedback
                </a>
              </li>
              {!isSignedIn ? (
                <NavBarItem
                  urlPath={SIGN_UP_PAGE_PATH}
                  labelText={
                    <>
                      <AutoAwesomeIcon style={{ marginRight: "5px" }} />
                      Sign Up
                    </>
                  }
                  className={NavItemClass.BUTTON}
                />
              ) : (
                <NavBarItem
                  urlPath={SUBSCRIPTIONS_PAGE_PATH}
                  labelText={
                    <>
                      <AccountCircleIcon style={{ marginRight: "5px" }} />
                      Subscription
                    </>
                  }
                  className={NavItemClass.BUTTON}
                />
              )}
              {!isSignedIn ? (
                <NavBarItem
                  urlPath={SIGN_IN_PAGE_PATH}
                  labelText="Log In"
                  className={`${NavItemClass.BUTTON} logIn`}
                />
              ) : (
                <NavBarItem
                  labelText="SIGN OUT"
                  className={`${NavItemClass.BUTTON} signOut`}
                  onClick={handleSignOutClick}
                />
              )}
            </div>
          </div>
        </ul>
      </nav>
      {state.isFeedbackModalVisible && (
        <Modal
          content={<FeedbackPage />}
          onClose={() => toggleState("isFeedbackModalVisible")}
          backgroundColor="#f5f5f5"
          padding="0px"
        ></Modal>
      )}
    </>
  );
};

export default NavBar;
