import React, { useContext, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fas,
  faPlay,
  faClock,
  faStop,
  faCommentDots
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import SettingsView, { InterviewSettings, ProgrammingLanguage, SettingsAccessibility } from "./pages/Settings";
import Modal from "./utils/Modal";
import FeedbackPage from "./pages/FeedbackPage";
import { InterviewStatus } from "./utils/Constants";
import "./css/TopMenuBar.css";
import StateAccessor from "./StateAccessor";
import { userHasPremiumSubscriptionPrivilege } from "./utils/PermissionUtils";

library.add(fas);

function formatTime(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const hh = hours.toString().padStart(2, "0");
  const mm = minutes.toString().padStart(2, "0");
  const ss = seconds.toString().padStart(2, "0");

  return `${hh}h:${mm}m:${ss}s`;
}

interface TopMenuBarProps {
  isSettingsVisible: boolean;
  setIsSettingsVisible: (isSettingsVisible: boolean) => void;
  startInterview: () => void;
  interviewStatus: InterviewStatus;
  isTimerRunning: boolean;
  timerVisible: boolean;
  setTimerVisible: (timerVisible: boolean) => void;
  endInterview: (endOfInterviewMsg: string) => void;
  interviewSettings: InterviewSettings;
  setInterviewSettings: (interviewSettings: InterviewSettings) => void;
  programmingLanguage: ProgrammingLanguage;
  setProgrammingLanguage: (programmingLanguage: ProgrammingLanguage) => void;
  displayTimerTemporarily: () => void;
  settingsAccessibility?: SettingsAccessibility;

}

const TopMenuBar: React.FC<TopMenuBarProps> = ({
  isSettingsVisible,
  setIsSettingsVisible,
  startInterview,
  interviewStatus,
  isTimerRunning,
  timerVisible,
  setTimerVisible,
  endInterview,
  interviewSettings,
  setInterviewSettings,
  programmingLanguage,
  setProgrammingLanguage,
  displayTimerTemporarily,
  settingsAccessibility,


}) => {
  const { userSubscriptionTier } = useContext(StateAccessor);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  const toggleFeedbackModal = () => {
    setIsFeedbackModalVisible(!isFeedbackModalVisible);
  };
  const INTERVIEW_TIMER_DURATION_IN_SECONDS = interviewSettings.timeLimitInMinutes * 60;

  const handleTimerComplete = () => {
    endInterview(
      "You're out of time. The interview has ended, thank you for your time"
    );
  };

  const handleSettingsClick = () => {
    setIsSettingsVisible(true);
  };

  const handleSettingsClose = () => {
    setIsSettingsVisible(false);
  };


  const handleStartInterview = () => {
    startInterview();
  };

  const handleStopInterview = () => {
    if (interviewStatus == InterviewStatus.IN_PROGRESS) {
      const endOfInterviewMsg =
        "The interview is now over. Thank you for your time.";
      endInterview(endOfInterviewMsg);
    } else {
      console.warn("Interview has not started, cannot stop");
    }
  };

  return (
    <div className="top-menu-bar">
      {/* Settings*/}
      <div className="top-menu-bar-item">
        <button className="settings-button top-menu-bar-button" onClick={handleSettingsClick}>
          <FontAwesomeIcon icon="cog" />

          <span className="top-menu-bar-label">Settings</span>
        </button>
      </div>

      {isSettingsVisible && (
        <Modal
          content={<SettingsView
            onClose={handleSettingsClose}
            interviewSettings={interviewSettings}
            setInterviewSettings={setInterviewSettings}
            programmingLanguage={programmingLanguage}
            setProgrammingLanguage={setProgrammingLanguage}
            settingsAccessibility={settingsAccessibility}
          />}
          onClose={handleSettingsClose}
        ></Modal>
      )}

      {/* start/stop interview */}
      <div className="top-menu-bar-item">
        <button
          className={
            interviewStatus === InterviewStatus.IN_PROGRESS
              ? "stop-button top-menu-bar-button"
              : "start-button top-menu-bar-button"
          }
          onClick={
            interviewStatus === InterviewStatus.IN_PROGRESS
              ? handleStopInterview
              : handleStartInterview
          }
        >
          <FontAwesomeIcon
            icon={interviewStatus === InterviewStatus.IN_PROGRESS ? faStop : faPlay}
          />
          <span className="top-menu-bar-label">
            {interviewStatus === InterviewStatus.IN_PROGRESS ? "Stop Interview" : "Start Interview"}
            {!userHasPremiumSubscriptionPrivilege(userSubscriptionTier) && (
              <span className="premium-label">Premium</span>
            )}
          </span>
        </button>
      </div>
      <div className="top-menu-bar-item">
        <button className="top-menu-bar-button" onClick={toggleFeedbackModal}>
          <FontAwesomeIcon icon={faCommentDots} />
          <span className="top-menu-bar-label">Give us feedback</span>
        </button>

        {isFeedbackModalVisible && (
          <Modal
            content={<FeedbackPage />}
            onClose={toggleFeedbackModal}
            padding="0px"
          ></Modal>
        )}


      </div>

      {/* countdown timer */}
      <div className="top-menu-bar-item">
        {interviewStatus === InterviewStatus.IN_PROGRESS && (
          <button
            className="countdown-timer-button top-menu-bar-button"
            onClick={displayTimerTemporarily}
          >
            <FontAwesomeIcon icon={faClock} />
            <span className="top-menu-bar-label">Timer</span>
          </button>
        )}
      </div>

      <div
        className="top-menu-bar-item"
        style={{ visibility: timerVisible ? "visible" : "hidden" }}
      >
        {interviewStatus === InterviewStatus.IN_PROGRESS && (
          <CountdownCircleTimer
            isPlaying={isTimerRunning}
            duration={INTERVIEW_TIMER_DURATION_IN_SECONDS}
            strokeWidth={0}
            size={20}
            trailColor={"#FFFFFF"}
            colors={
              [
                ["#004777", 0.33],
                ["#F7B801", 0.33],
                ["#A30000"],
              ] as any
            }
            onComplete={handleTimerComplete}
          >
            {({ remainingTime }) => (
              <div className="countdown-timer-text-interview">{formatTime(remainingTime)}</div>
            )}
          </CountdownCircleTimer>
        )}
      </div>
    </div>
  );
}

export default TopMenuBar;
