import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { openUrlInNewTab } from './HelperFunctions';
import StateAccessor from '../StateAccessor';

import '../css/BannerButton.css';
import { CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG, HUMAN_INTERVIEWER_COACH_PATH, WORKSHOPS_PAGE_PATH } from './Constants';
import { getCurrentDiscount } from '@/pages/HumanInterviewerCoach/Discounts/DiscountUtils';

export enum BannerPosition {
    Top = 'top',
    Bottom = 'bottom'
}

export interface BannerButtonProps {
    url: string;
    text: string;  // We'll keep this as string, but we'll render it as HTML.
    backgroundImage?: string;
    textColor?: string;
    position?: BannerPosition;
    appearAfterTimeInSec?: number | null;
}

/*
  Generic BannerButton that displays a text string (rendered as HTML) 
  and opens a URL in a new tab on click.
*/
const BannerButton: React.FC<BannerButtonProps> = ({
    url,
    text,
    backgroundImage = 'linear-gradient(189deg, #ffff99, #fdd835)',
    textColor = 'black',
    position = BannerPosition.Top,
    appearAfterTimeInSec = null
}) => {
    const { userId } = useContext(StateAccessor);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (appearAfterTimeInSec !== null) {
            timer = setTimeout(() => {
                setIsVisible(true);
            }, appearAfterTimeInSec * 1000);
        } else {
            setIsVisible(true);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [appearAfterTimeInSec]);

    if (!isVisible) {
        return null;
    }

    return (
        <Button
            className='join-workshop-button'
            variant="contained"
            color="primary"
            endIcon={<EventNoteIcon />}
            onClick={() => openUrlInNewTab(url, userId)}
            sx={{
                color: textColor,
                fontSize: '14px',
                textTransform: 'none',
                backgroundImage: backgroundImage,
                position: position === BannerPosition.Bottom ? ['-webkit-sticky', 'sticky'] : 'relative',
                bottom: position === BannerPosition.Bottom ? 0 : 'auto',
                paddingRight: '1px',
                zIndex: 99,
            }}
        >
            <IconButton
                aria-label="close"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent the button's onClick from being called
                    setIsVisible(false);
                }}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/*
              Render the text as HTML using dangerouslySetInnerHTML.
              This allows partial styling of certain parts (e.g., seconds in red).
            */}
            <span
                dangerouslySetInnerHTML={{ __html: text }}
                /* 
                  Avoid adding user-supplied data here 
                  unless you've sanitized it, as this can be a security risk.
                */
            />
        </Button>
    );
};

interface CustomBannerButtonProps {
    backgroundImage?: string;
    textColor?: string;
    position?: BannerPosition;
    appearAfterTimeInSec?: number | null;
}

/**
 * FreeWorkshopBannerButton: Unchanged
 */
export const FreeWorkshopBannerButton: React.FC<CustomBannerButtonProps> = ({
    backgroundImage = 'linear-gradient(189deg, #ffff99, #fdd835)',
    textColor = 'black',
    position = BannerPosition.Bottom,
    appearAfterTimeInSec = null
}) => {
    return (
        <BannerButton
            url={WORKSHOPS_PAGE_PATH}
            text={CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG}
            backgroundImage={backgroundImage}
            textColor={textColor}
            position={position}
            appearAfterTimeInSec={appearAfterTimeInSec}
        />
    );
};

/**
 * DiscountBannerButton:
 *  - If discount is 0 or expired, returns null (no banner).
 *  - Otherwise, shows a banner with a live countdown (if endDate is set).
 *  - Renders seconds in red.
 */
export const DiscountBannerButton: React.FC<CustomBannerButtonProps> = ({
    backgroundImage = 'linear-gradient(189deg, #ffff99, #fdd835)',
    textColor = 'black',
    position = BannerPosition.Top,
    appearAfterTimeInSec = null
}) => {
    const { discountPercentage, promoCode, endDate } = getCurrentDiscount();

    const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
    const [bannerHTML, setBannerHTML] = useState<string>(''); // We'll store HTML here

    useEffect(() => {
        // If discount is 0, hide banner
        if (discountPercentage === 0) {
            setIsBannerVisible(false);
            return;
        }

        // If no endDate => indefinite discount
        if (!endDate) {
            setIsBannerVisible(true);
            // Simple static HTML (no countdown):
            setBannerHTML(`${discountPercentage}% off Mock Interviews | Use code: ${promoCode}`);
            return;
        }

        // Otherwise, we have an endDate. Check if it's already expired:
        const now = new Date();
        const timeLeft = endDate.getTime() - now.getTime();
        if (timeLeft <= 0) {
            setIsBannerVisible(false);
            return;
        }

        // Discount is valid -> show banner and start countdown
        setIsBannerVisible(true);

        const updateCountdown = () => {
            const currentTimeLeft = endDate.getTime() - new Date().getTime();
            if (currentTimeLeft <= 0) {
                setIsBannerVisible(false);
                return;
            }

            // Calculate days, hours, minutes, seconds
            const days = Math.floor(currentTimeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (currentTimeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (currentTimeLeft % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((currentTimeLeft % (1000 * 60)) / 1000);

            // We create an HTML string
            // We'll color only the seconds portion in red
            const newHTML = `
              ${discountPercentage}% off Mock Interviews | Use code: ${promoCode} | 
              Expires in ${days}d ${hours}h ${minutes}m 
              <span style="color:#d32f2f;font-weight:bold;">${seconds}s</span>
            `;
            setBannerHTML(newHTML);
        };

        // Immediate update on mount
        updateCountdown();
        // Update every second
        const intervalId = setInterval(updateCountdown, 1000);

        return () => clearInterval(intervalId);
    }, [discountPercentage, promoCode, endDate]);

    if (!isBannerVisible) {
        return null;
    }

    return (
        <BannerButton
            url={HUMAN_INTERVIEWER_COACH_PATH}
            text={bannerHTML} // Pass the HTML string
            backgroundImage={backgroundImage}
            textColor={textColor}
            position={position}
            appearAfterTimeInSec={appearAfterTimeInSec}
        />
    );
};

export default BannerButton;
