import React, { useState, useContext } from "react";
import { signInWithEmailAndPassword as signIn } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { Typography, Container } from "@mui/material";
import {
  Form,
  StyledTextField,
  StyledButton,
  ErrorText,
  AuxiliaryButton,
} from "../utils/FormStyles";
import StateAccessor from "../StateAccessor";
import {
  cacheUserIdByEmailMapping,
  getUserIdByEmailFromBackend,
} from "../utils/HelperFunctions";
import { FcGoogle } from "react-icons/fc";
import { signInOrSignUpWithGmail, PostSignInAction } from "@/utils/AuthHelpers";
import { StyledDivider, TermsAndPrivacy } from "./SignUpPage";
import PremiumSubscriptionModal from "../utils/PremiumSubscriptionModal";
import {
  SIGN_UP_PAGE_PATH,
  LANDING_PAGE_UPON_SIGN_IN_PATH,
  PASSWORD_RESET_PAGE_PATH,
} from "@/utils/Constants";
import { useNavigate } from "react-router-dom";

interface SignInPageProps {
  fromModal?: boolean; // Determines if this page is used within a modal
  gmailSignInButtonLabel?: string;
  onEmailSignInSuccess?: () => void; // Callback for successful email-password sign-in
  onGmailSignInSuccess?: () => void; // Callback for successful Gmail sign-in
  finalizeSignInModal?: () => void; // Finalize logic for the sign-in modal
}

const SignInPage: React.FC<SignInPageProps> = ({
  fromModal = false,
  gmailSignInButtonLabel = "Sign in with Google",
  onEmailSignInSuccess,
  onGmailSignInSuccess,
  finalizeSignInModal,
}) => {
  const { setUserEmail, setUserId } = useContext(StateAccessor);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [showPremiumSubscriptionModal, setShowPremiumSubscriptionModal] =
    useState(false);

  const initialiseUsersAccess = async (email: string, userId: number) => {
    cacheUserIdByEmailMapping(email, userId);
    setUserEmail(email);
    setUserId(userId);

    if (fromModal) {
      setShowPremiumSubscriptionModal(true);
      finalizeSignInModal && finalizeSignInModal(); // Finalize modal cleanup
    } else {
      navigate(LANDING_PAGE_UPON_SIGN_IN_PATH);
    }
  };

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    try {
      const userId = await getUserIdByEmailFromBackend(email, 3); // Fetch userId from backend
      await signIn(auth, email, password);
      initialiseUsersAccess(email, userId);

      onEmailSignInSuccess && onEmailSignInSuccess(); // Notify email sign-in success
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Invalid email-password combination.");
    }
  };

  const handleGmailSignIn = () => {
    signInOrSignUpWithGmail({
      postSignInAction: fromModal
        ? PostSignInAction.OpenPremiumSubscriptionModal
        : PostSignInAction.NavigateToLandingPage,
      postSignInHandler: (action, path) => {
        if (action === PostSignInAction.OpenPremiumSubscriptionModal) {
          setShowPremiumSubscriptionModal(true);
          finalizeSignInModal && finalizeSignInModal(); // Finalize modal cleanup
          onGmailSignInSuccess && onGmailSignInSuccess(); // Notify Gmail sign-in success
        } else if (path) {
          navigate(path);
        }
      },
      customPagePath: LANDING_PAGE_UPON_SIGN_IN_PATH,
      setError,
      setIsSigningInorSigningUp: setIsSigningIn,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSigningIn(true);
    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setError("Invalid email.");
      setIsSigningIn(false);
      return;
    }
    signInWithEmailAndPassword(email, password);
    setIsSigningIn(false);
  };

  const navigateToSignUpPage = () => {
    navigate(SIGN_UP_PAGE_PATH);
  };

  const navigateToPasswordResetPage = () => {
    navigate(PASSWORD_RESET_PAGE_PATH);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center">
        Sign In
      </Typography>
      {error && <ErrorText>{error}</ErrorText>}
      <StyledButton
        onClick={handleGmailSignIn}
        style={{ textTransform: "none" }}
        variant="outlined"
        fullWidth
        startIcon={<FcGoogle />}
      >
        {gmailSignInButtonLabel}
      </StyledButton>
      <StyledDivider>
        <span>or continue with</span>
      </StyledDivider>
      <Form onSubmit={handleSubmit}>
        <StyledTextField
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="outlined"
          fullWidth
        />
        <StyledTextField
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          variant="outlined"
          fullWidth
        />
        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSigningIn}
        >
          {isSigningIn ? "Signing In..." : "Sign In"}
        </StyledButton>
        <StyledButton
          onClick={navigateToSignUpPage}
          variant="outlined"
          color="primary"
          fullWidth
        >
          Don't have an account yet? Sign Up Here
        </StyledButton>
        <AuxiliaryButton onClick={navigateToPasswordResetPage}>
          Forgot password?
        </AuxiliaryButton>
        <TermsAndPrivacy />
      </Form>

      {showPremiumSubscriptionModal && (
        <PremiumSubscriptionModal
          onSubscriptionComplete={() => setShowPremiumSubscriptionModal(false)}
        />
      )}
    </Container>
  );
};

export default SignInPage;
