import React, { useEffect, useRef, useState } from "react";
import * as monaco from "monaco-editor";
import MonacoEditor from "@monaco-editor/react";
import CodeStorage from "./CodeStorage";
import { MinusIcon, PlusIcon } from "lucide-react";
import "@/css/CodeEditor.css";
import debounce from "lodash.debounce"; // Ensure lodash.debounce is available
import { Snackbar } from "@mui/material";

/**
 * Ensures a value is within the specified minimum and maximum bounds.
 * @param value - The number to enforce bounds on.
 * @param min - The minimum allowable value.
 * @param max - The maximum allowable value.
 * @returns A value within the range [min, max].
 */
const enforceFontSizeBounds = (value: number, min: number, max: number): number => {
  return Math.min(max, Math.max(min, value));
};

// Enum for editor themes
enum Theme {
  DARK = "vs-dark",
  LIGHT = "vs-light",
  HIGH_CONTRAST = "hc-black",
}

// Constants for localStorage keys and font size boundaries
const CODE_EDITOR_THEME: Readonly<string> = "code-editor-theme";
const CODE_EDITOR_FONT_SIZE: Readonly<string> = "code-editor-font-size";
const MIN_FONT_SIZE: Readonly<number> = 12;
const MAX_FONT_SIZE: Readonly<number> = 32;
const DEFAULT_FONT_SIZE: Readonly<number> = 16;

const RESIZE_DEBOUNCE_DELAY_MS: Readonly<number> = 150; // this value influences the lag seen when resizing the window, if too large users will see a delay in the editor resizing
const CODE_EDITOR_CONTAINER_CLASS = "code-editor-container";

// Function to initialize theme from localStorage or default
const initializeTheme = (): Theme => {
  const storedTheme = localStorage.getItem(CODE_EDITOR_THEME);
  if (storedTheme && Object.values(Theme).includes(storedTheme as Theme)) {
    return storedTheme as Theme;
  }
  return Theme.DARK;
};

// Props interface for CodeEditor
interface Props {
  value: string;
  language: string;
  userId: string;
  taskId: string;
  onChange: (value: string) => void;
  shouldPersistCode?: boolean;
}

const CodeEditor: React.FC<Props> = ({
  value,
  language,
  userId,
  taskId,
  onChange,
  shouldPersistCode: useCodeStorage = false,
}) => {
  // State for editor theme and font size
  const [theme, setTheme] = useState<Theme>(Theme.DARK);
  const [fontSizeInPixels, setFontSizeInPixels] = useState<number>(DEFAULT_FONT_SIZE);

  // For showing any storage-failure notifications
  const [failedCodeStorageNotificationOpen, setFailedCodeStorageNotificationOpen] = useState(false);

  // Ref for the Monaco editor instance
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  // Initialize theme from localStorage
  useEffect(() => {
    const themeFromStorage = initializeTheme();
    setTheme(themeFromStorage);
  }, []);

  // Initialize font size from localStorage
  useEffect(() => {
    const storedFontSize = localStorage.getItem(CODE_EDITOR_FONT_SIZE);
    const parsedFontSize =
      storedFontSize && !isNaN(Number(storedFontSize))
        ? parseInt(storedFontSize, 10)
        : DEFAULT_FONT_SIZE;
    setFontSizeInPixels(enforceFontSizeBounds(parsedFontSize, MIN_FONT_SIZE, MAX_FONT_SIZE));
  }, []);

  // Persist theme changes to localStorage
  useEffect(() => {
    localStorage.setItem(CODE_EDITOR_THEME, theme);
  }, [theme]);

  // Persist font-size changes to localStorage
  useEffect(() => {
    localStorage.setItem(CODE_EDITOR_FONT_SIZE, fontSizeInPixels.toString());
  }, [fontSizeInPixels]);

  // Helper to generate CSS classes based on theme
  const getCSSClassNameByCodeEditorTheme = (currentTheme: Theme, baseClass: string): string => {
    switch (currentTheme) {
      case Theme.LIGHT:
        return `${baseClass} light`;
      case Theme.HIGH_CONTRAST:
        return `${baseClass} high-contrast`;
      default:
        return `${baseClass} dark`;
    }
  };

  // CSS class for font size controls
  const fontSizeControlButtonClass = getCSSClassNameByCodeEditorTheme(theme, "icon-button text-white");

  // Load stored code if persistence is enabled
  useEffect(() => {
    if (!useCodeStorage) return;

    let isMounted = true;

    const loadCode = async () => {
      try {
        const storedCode = await CodeStorage.fetchCode(userId, taskId, language);
        if (isMounted && storedCode && storedCode !== value) {
          onChange(storedCode);
        }
      } catch (error) {
        console.error("Error fetching code:", error);
      }
    };

    loadCode();

    return () => {
      isMounted = false;
    };
  }, [userId, taskId, language, useCodeStorage]);

  // Handler for when the editor is mounted
  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
    monacoInstance: typeof monaco
  ) => {
    editorRef.current = editor;
    editor.layout(); // Initial layout
    editor.focus();
  };

  const handleFailedNotificationClose = () => {
    setFailedCodeStorageNotificationOpen(false);
  };

  // Handle changes in the code editor
  const handleChange = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      onChange(newValue);

      if (useCodeStorage) {
        CodeStorage.debouncedSave(userId, taskId, language, newValue)?.catch((error) => {
          if (error instanceof DOMException && error.name === "QuotaExceededError") {
            console.warn("Storage quota exceeded. Old entries will be evicted.");
          } else {
            console.error("Error saving code:", error);
          }
          setFailedCodeStorageNotificationOpen(true);
        });
      }
    }
  };

  // Container ref for the Monaco Editor
  const containerRef = useRef<HTMLDivElement | null>(null);

  /**
   * 1) Warn if ResizeObserver is not supported and use a window resize fallback instead
   * 2) Otherwise, attach a debounced ResizeObserver
   * 3) Wrap the observer callback in try-catch to handle any unexpected errors
   * 4) Re-attach if containerRef changes (for dynamic DOM updates)
   */
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    // Fallback if ResizeObserver is unavailable
    if (typeof ResizeObserver === "undefined") {
      console.warn(
        "ResizeObserver is not supported. Using window resize fallback. For better performance, consider adding a ResizeObserver polyfill."
      );

      // Debounced fallback for older browsers
      const handleResize = debounce(() => {
        if (editorRef.current) {
          editorRef.current.layout();
        }
      }, RESIZE_DEBOUNCE_DELAY_MS);

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }

    // Debounced ResizeObserver for supported browsers
    const observer = new ResizeObserver(
      debounce(() => {
        try {
          if (editorRef.current) {
            editorRef.current.layout();
          }
        } catch (error) {
          console.error("ResizeObserver error:", error);
        }
      }, RESIZE_DEBOUNCE_DELAY_MS)
    );

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
    // Include containerRef.current in dependencies so observer re-attaches if the element changes
  }, [containerRef.current]);

  return (
    <div
      ref={containerRef}
      className={CODE_EDITOR_CONTAINER_CLASS}
      style={{ height: "100%", width: "100%" }}
    >
      {/* Editor Controls */}
      <div className={getCSSClassNameByCodeEditorTheme(theme, "editor-controls")}>
        {/* Theme Selector */}
        <select
          value={theme}
          aria-label="Select Theme"
          onChange={(e) => setTheme(e.target.value as Theme)}
          className={getCSSClassNameByCodeEditorTheme(theme, "theme-selector")}
        >
          <option value={Theme.DARK}>Dark</option>
          <option value={Theme.LIGHT}>Light</option>
          <option value={Theme.HIGH_CONTRAST}>High Contrast</option>
        </select>

        {/* Font Size Controls */}
        <div className="font-size-controls">
          <MinusIcon
            size={32}
            className={fontSizeControlButtonClass}
            aria-label="Decrease Font Size"
            onClick={() =>
              setFontSizeInPixels((prev) => enforceFontSizeBounds(prev - 1, MIN_FONT_SIZE, MAX_FONT_SIZE))
            }
          />
          <span className="font-size-display">{fontSizeInPixels}</span>
          <PlusIcon
            size={32}
            className={fontSizeControlButtonClass}
            aria-label="Increase Font Size"
            onClick={() =>
              setFontSizeInPixels((prev) => enforceFontSizeBounds(prev + 1, MIN_FONT_SIZE, MAX_FONT_SIZE))
            }
          />
        </div>
      </div>

      {/* Monaco Editor */}
      <MonacoEditor
        height="100vh"
        width="100%"
        language={language}
        theme={theme}
        value={value}
        onChange={handleChange}
        onMount={handleEditorDidMount}
        options={{
          fontSize: fontSizeInPixels,
          automaticLayout: false, // We handle layout manually via observer
        }}
      />

      {/* Snackbar for failed code storage */}
      <Snackbar
        open={failedCodeStorageNotificationOpen}
        onClose={handleFailedNotificationClose}
        message="[Warning] An error occured while saving your code. This won't impact your current work"
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgb(250, 199, 58)", // Custom background color
            color: "black", // Custom text color
          },
        }}
      />
    </div>
  );
};

export default CodeEditor;
