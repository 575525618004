import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "@/firebaseConfig";
import { createUserInBackend, logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import { addUserEmailToFirebaseUsersCollectionIfNew, doesUserEmailExistInFirebaseUsersCollection } from "@/externalLayerAccessor/firebaseAccessor";
import { LANDING_PAGE_UPON_SIGN_IN_PATH } from "./Constants";

const DEFAULT_MVP_GMAIL_PASSWORD: string = `P-22wd-gmail-346gF!_${Math.random().toString(36).substring(2, 10)}`;

export enum PostSignInAction {
  NavigateToLandingPage = "LANDING_PAGE_UPON_SIGN_IN_PATH",
  OpenPremiumSubscriptionModal = "OpenPremiumSubscriptionModal",
}

async function handleNewUser(userData: { email: string; userName: string; password: string; invitationCode: string }): Promise<void> {
  const userCreatedSuccessfullyInBackend = await createUserInBackend(userData);
  if (!userCreatedSuccessfullyInBackend) {
    logToBackendLogFile(`Error creating user in backend for email: ${userData.email}`, "error");
    throw new Error("Could not create user in backend.");
  }
  await addUserEmailToFirebaseUsersCollectionIfNew(userData.email);
}

export async function signInOrSignUpWithGmail({
  postSignInAction = PostSignInAction.NavigateToLandingPage,
  postSignInHandler,
  customPagePath, // New parameter for custom navigation paths
  setError,
  setIsSigningInorSigningUp,
}: {
  postSignInAction?: PostSignInAction;
  postSignInHandler: (action: PostSignInAction, path?: string) => void; // Accepts action and optional path
  customPagePath?: string; // Custom path for navigation
  setError: (error: string) => void;
  setIsSigningInorSigningUp: (isSigningInorSigningUp: boolean) => void;
}): Promise<void> {
  setIsSigningInorSigningUp(true);

  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    if (user && user.email) {
      const userData = {
        userName: user.email,
        email: user.email,
        password: DEFAULT_MVP_GMAIL_PASSWORD,
        invitationCode: "",
      };

      const userAlreadyExists = await doesUserEmailExistInFirebaseUsersCollection(user.email);

      // If user is new, create them in the backend
      if (!userAlreadyExists) {
        await handleNewUser(userData);
      }

      // Determine post-sign-in action
      if (postSignInAction === PostSignInAction.OpenPremiumSubscriptionModal) {
        postSignInHandler(PostSignInAction.OpenPremiumSubscriptionModal);
      } else if (postSignInAction === PostSignInAction.NavigateToLandingPage && customPagePath) {
        postSignInHandler(PostSignInAction.NavigateToLandingPage, customPagePath);
      } else {
        postSignInHandler(PostSignInAction.NavigateToLandingPage);
      }
    } else {
      throw new Error("Failed to retrieve user information. Please try again or contact support.");
    }
  } catch (error) {
    setError("An unexpected error occurred. Please try again.");
    logToBackendLogFile(`Error during Gmail sign-in or sign-up: ${error}`, "error");
  } finally {
    setIsSigningInorSigningUp(false);
  }
}
