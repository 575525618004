import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { ErrorText } from "../utils/FormStyles";
import { doc, setDoc, arrayUnion, getDoc } from "firebase/firestore";
import "../css/SignUpPage.css";
import log from "../utils/Logger";
import {
  DEFAULT_EMAIL_JS_PUBLIC_KEY,
  LANDING_PAGE_UPON_SIGN_IN_PATH,
  NavigationUrlParamTags,
} from "../utils/Constants";
import emailjs from "emailjs-com";
import {
  CODITIONING_OUTLOOK_SERVICE_ID,
  WELCOME_EMAIL_TEMPLATE_ID,
} from "../utils/Constants";
import { FcGoogle } from "react-icons/fc";
import { addUserEmailToFirebaseUsersCollectionIfNew } from "@/externalLayerAccessor/firebaseAccessor";
import { signInOrSignUpWithGmail, PostSignInAction } from "@/utils/AuthHelpers";
import { createUserInBackend, logToBackendLogFile } from "@/externalLayerAccessor/BackEndRequests";
import PremiumSubscriptionModal from "@/utils/PremiumSubscriptionModal";

const EMAIL_JS_PUBLIC_KEY = process.env.EMAIL_JS_PUBLIC_KEY || DEFAULT_EMAIL_JS_PUBLIC_KEY;

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .MuiTextField-root, & .MuiButton-root": {
    marginTop: theme.spacing(2),
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  width: "100%",
  textAlign: "center",
  "&::before, &::after": {
    borderColor: grey[400],
  },
  "& span": {
    padding: "0 8px",
    color: grey[400],
    fontSize: "16px",
  },
}));

const DEFAULT_MVP_PASSWORD: string = `P-22wd-346gF!_${Math.random().toString(36).substring(2, 10)}`;

const TermsAndPrivacy: React.FC = () => (
  <p className="terms-and-privacy-policy">
    By signing up or signing in, you agree to our{" "}
    <Link to="/terms" target="_blank" rel="noopener noreferrer">
      terms and conditions
    </Link>
    , as well as our{" "}
    <Link to="/privacy" target="_blank" rel="noopener noreferrer">
      privacy policy
    </Link>
    .
  </p>
);

interface SignUpPageProps {
  fromModal?: boolean; // Indicates if this is rendered inside a modal
  onSignUpComplete?: () => void; // Callback when signup completes from modal
}

const SignUpPage: React.FC<SignUpPageProps> = ({ fromModal, onSignUpComplete }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password] = useState(DEFAULT_MVP_PASSWORD);
  const [username, setUsername] = useState("");
  const [invitationCode] = useState("");
  const [error, setError] = useState("");
  const [userCount, setUserCount] = useState(Number.MAX_SAFE_INTEGER);
  const [isWaitlistMode, setIsWaitlistMode] = useState(true);
  const MAXIMUM_ALLOWED_USERS = 1152;
  const [isUserAddedToWaitlist, setIsUserAddedToWaitlist] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [showPremiumSubscriptionModal, setShowPremiumSubscriptionModal] = useState(false);

  useEffect(() => {
    setIsWaitlistMode(userCount >= MAXIMUM_ALLOWED_USERS);
  }, [userCount]);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const usersDocRef = doc(db, "alpha-phase", "users");
        const usersSnapshot = await getDoc(usersDocRef);
        if (usersSnapshot.exists()) {
          const emailsArray = usersSnapshot.data().emails || [];
          setUserCount(emailsArray.length);
        } else {
          setError("An internal server error occurred. Please try again later.");
        }
      } catch (error) {
        log.error("Error fetching user count:", error);
        setError("An internal server error occurred. Please try again later.");
      }
    };
    fetchUserCount();
  }, []);

  const handleWaitlistJoin = async (email: string) => {
    try {
      const waitlistDocRef = doc(db, "alpha-phase", "waitlist");
      const docSnapshot = await getDoc(waitlistDocRef);

      if (docSnapshot.exists()) {
        await setDoc(
          waitlistDocRef,
          { emails: arrayUnion(email) },
          { merge: true }
        );
      } else {
        await setDoc(waitlistDocRef, { emails: [email] });
      }
      setIsUserAddedToWaitlist(true);
      log.info("User added to the waitlist successfully");
    } catch (error) {
      setError("Error adding user to the waitlist. Please try again.");
      log.error("Error adding user to the waitlist:", error);
    }
  };

  const handleSignUp = async (email: string, password: string) => {
    try {
      setIsSigningUp(true);
      const userData = {
        userName: username,
        email,
        password,
        invitationCode,
      };

      const isBackendSuccess = await createUserInBackend(userData);

      if (isBackendSuccess) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const userEmail = userCredential?.user?.email;

        if (!userEmail) {
          throw new Error("User email is unexpectedly null or undefined after sign-up.");
        }

        await addUserEmailToFirebaseUsersCollectionIfNew(userEmail);

        if (fromModal && onSignUpComplete) {
          onSignUpComplete();
        } else {
          navigate(`${LANDING_PAGE_UPON_SIGN_IN_PATH}?source_component=${NavigationUrlParamTags.SIGN_UP_COMPONENT}`);
        }

        sendWelcomeEmail(email, password);
      } else {
        throw new Error("An error occurred while creating your account. Please try again or contact support.");
      }
    } catch (error) {
      setError("An error occurred during sign-up. Please try again.");
      logToBackendLogFile(`User sign-up error: ${error}`, "error");
    } finally {
      setIsSigningUp(false);
    }
  };

  const sendWelcomeEmail = async (email: string, password: string): Promise<void> => {
    const templateParams = { email, password };
    await emailjs.send(
      CODITIONING_OUTLOOK_SERVICE_ID,
      WELCOME_EMAIL_TEMPLATE_ID,
      templateParams,
      EMAIL_JS_PUBLIC_KEY
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isWaitlistMode) {
      handleWaitlistJoin(email);
      return;
    }

    handleSignUp(email, password);
  };

  const handleGmailSignUp = () => {
    signInOrSignUpWithGmail({
      postSignInAction: fromModal
        ? PostSignInAction.OpenPremiumSubscriptionModal
        : PostSignInAction.NavigateToLandingPage,
      postSignInHandler: (action, path) => {
        if (action === PostSignInAction.OpenPremiumSubscriptionModal && onSignUpComplete) {
          onSignUpComplete();
        } else if (path) {
          navigate(path);
        }
      },
      customPagePath: LANDING_PAGE_UPON_SIGN_IN_PATH,
      setError,
      setIsSigningInorSigningUp: setIsSigningUp,
    });
  };

  const navigateToSignInPage = () => navigate("/signin");

  return (
    <StyledContainer maxWidth="xs">
      {!isUserAddedToWaitlist ? (
        <>
          {!isWaitlistMode && email && (
            <p style={{ color: "#1976d2" }}>Your password will be sent to your email address.</p>
          )}
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          {error && <ErrorText>{error}</ErrorText>}
          <Button
            onClick={handleGmailSignUp}
            style={{ textTransform: "none" }}
            variant="outlined"
            fullWidth
            startIcon={<FcGoogle />}
          >
            Sign up with Google
          </Button>
          <StyledDivider>
            <span>or continue with</span>
          </StyledDivider>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setUsername(event.target.value);
              }}
            />
            {!isWaitlistMode && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSigningUp}
                style={{ textTransform: "none" }}
              >
                {isSigningUp ? "Signing Up..." : "Sign Up"}
              </Button>
            )}
            {isWaitlistMode && (
              <Button
                onClick={() => handleWaitlistJoin(email)}
                fullWidth
                variant="contained"
                color="primary"
              >
                Join Waitlist
              </Button>
            )}
            <Button
              onClick={navigateToSignInPage}
              fullWidth
              variant="outlined"
              color="primary"
            >
              Already Have an Account? Sign In Here
            </Button>
          </form>
          <TermsAndPrivacy />
        </>
      ) : (
        <p>
          <i>
            You have been added to the waitlist. You will receive an email when you can sign up. Please check your junk folder.
          </i>
        </p>
      )}
      {showPremiumSubscriptionModal && (
        <PremiumSubscriptionModal
          onSubscriptionComplete={() => setShowPremiumSubscriptionModal(false)}
        />
      )}
    </StyledContainer>
  );
};

export default SignUpPage;
export { TermsAndPrivacy };
