// src/components/Footer.tsx
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import {
  ABOUT_PAGE_PATH,
  CAREERS_PAGE_PATH,
  CONTACT_PAGE_PATH,
  DISCORD_URL,
  PRIVACY_PAGE_PATH,
  TERMS_PAGE_PATH,
  YOUTUBE_URL,
} from "../utils/Constants";
import "../css/Footer.css";
import Modal from "../utils/Modal";
import FeedbackPage from "./FeedbackPage";

const Footer: React.FC = () => {
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const toggleFeedbackModal = () => {
    setIsFeedbackModalVisible(!isFeedbackModalVisible);
  };

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <>
      {isFeedbackModalVisible && (
        <Modal content={<FeedbackPage />} onClose={toggleFeedbackModal} padding="0px"></Modal>
      )}

      <footer className={`footer ${isHomePage ? "home" : ""}`}>
        <div className={`footer-links ${isHomePage ? "home" : ""}`}>
          <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} /> Discord
          </a>
          <a href={YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} /> YouTube
          </a>
          <a onClick={toggleFeedbackModal} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faCommentDots} /> Feedback
          </a>
          <Link to={CAREERS_PAGE_PATH}>Careers</Link>
          <Link to={CONTACT_PAGE_PATH}>Contact Us</Link>
          <Link to={ABOUT_PAGE_PATH}>About Us</Link>
          <Link to={TERMS_PAGE_PATH}>Terms & Conditions</Link>
          <Link to={PRIVACY_PAGE_PATH}>Privacy Policy</Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
